import React, { useState, useEffect }  from "react";
import {styleText, smallPaperStyle, GRAY_LABEL_UX} from "../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useSelector, useDispatch} from "react-redux";
import {BLUE_THEME, WHITE_THEME_BLACK, BORDER_TABLE, BORDER_PAPER} from "../../shared/utils";
import ModalTitle from "../Titles/ModalTitle";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { Divider, FormControlLabel, Radio } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../Buttons/DefaultButton";
import api from "../../axios";
import { showSnackMessage } from "../../actions/SnackActions";


const EditLancamentoRecorrenteModal = ({openModal, setOpenModal, setAction, lancamento, tab}) => {
    const dispatch = useDispatch();

    const [selectedChoice, setSelectedChoice] = useState("");
    const [baixaId, setBaixaId] = useState(lancamento);

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);

    const nav = useNavigate();

    useEffect(() => {
        if(tab === "contas_pagar" || tab === "contas_receber") {
            api.GetBaixaId({parcela_id: lancamento, estabelecimento_id: estabelecimentoId}).then((response) => {
                setBaixaId(response.data.id);
            }).catch(() => {
                dispatch(showSnackMessage({"message": "Erro ao buscar baixa do lançamento", "severity": "error"}));
            });
        }

        if(tab === "visao_competencia"){
            api.GetBaixaId({movimentacao_id: lancamento, estabelecimento_id: estabelecimentoId}).then((response) => {
                setBaixaId(response.data.id);
            }).catch(() => {
                dispatch(showSnackMessage({"message": "Erro ao buscar baixa do lançamento", "severity": "error"}));
            });
        }


    }, []);

    return (
        <React.Fragment>
            <Modal
                open={openModal}
                onClose={() => {
                        setOpenModal(false);
                        setAction(null);
                        setSelectedChoice(null);
                    }
                }
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="info-modal"
            >
                <Paper sx={{...smallPaperStyle, minWidth: "45vw", backgroundColor: temaEscuro && BLUE_THEME, pt: 3}}>
                    <ModalTitle title={"Editar Lançamento Recorrente"} setOpenModal={setOpenModal} />
                    <Grid container sx={{marginTop: 2}}>
                        <Grid item xs={12} sx={{".MuiTypography-root": {
                            fontSize: "14px",
                        }}}>
                            <FormControl variant="standard">
                                <FormLabel sx={{...styleText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, mb: 2}}>As alterações neste lançamento devem ser refletidas:</FormLabel>
                                <RadioGroup
                                    name="recorrencia"
                                    value={selectedChoice}
                                    onChange={(e) => setSelectedChoice(e.target.value)}
                                >
                                <FormControlLabel value="unico" control={<Radio />} label="Somente o lançamento atual" />
                                <FormControlLabel value="nao-vencidos" control={<Radio />} label="Lançamento atual e todos os não vencidos que não possuam baixas" />
                                <FormControlLabel value="em-aberto" control={<Radio />} label="Lançamento atual e todos os em aberto (vencidos + a vencer) que não possuam baixas" />
                                <FormControlLabel value="todos" control={<Radio />} label="Todos os lançamentos" />
                                </RadioGroup>
                            </FormControl>
                            <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER, marginTop: 3, marginBottom: 2}}/>
                        </Grid>
                        <Grid item xs={12} sx={{display: "flex", justifyContent: "space-between"}}>
                            <DefaultButton
                                title={"Cancelar"}
                                onClick={() => {
                                    setOpenModal(false);
                                    setSelectedChoice(null);
                                }
                            }
                            />
                            <DefaultButton
                                disabled={!selectedChoice}
                                title={"Salvar"}
                                onClick={() => nav("/editar_recorrencia", {state: {selectedChoice, baixaId, tab}})}
                                variant="yellow"
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </React.Fragment>
    );
};

export default EditLancamentoRecorrenteModal;