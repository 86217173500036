import React, {useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {Link as LinkRouter} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import api from "../../axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Loading from "../../components/Loading/Loading";
import CopyRight from "../../components/CopyRight/CopyRight";
import Countdown from "react-countdown";
import { GRAY_LABEL_UX, GRAY_HEADER_UX, GREEN_SUCCESS_UX, styleButton, GRAY_BG_BODY, LINE_TABLE, GRAY_BORDER_TABLE, YELLOW_BG_UX, BLACK_BUTTON } from "../../shared/utils";
import "./Login.css";



const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: "Inter, cursive",
            color: GRAY_LABEL_UX,
            fontSize: "17px",
            fontWeight: "500",
        },
        button: {
            textTransform: "none",
        }
    },
});


export default function CheckCode() {
    const navigate = useNavigate();
    const location = useLocation();

    const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [resendCode, setResendCode] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);

    useEffect(() => {
		setEmail(location.state.email);
	}, []);

	const handleSubmit = () => {
		const data = {
			email,
			code
		};
		setError(false);
		setLoading(true);
		api.GetCheckCode(data).then(() => {
			setLoading(false);
            navigate("/reset_pwd", { state: { email, code }});
		}).catch(() => {
			setError(true);
			setLoading(false);
		});
	};

    const resendVerificationCode = () => {
		const data = {
			email
		};
		setLoading(true);
		api.SendResetPwdEmail(data).then(() => {
			setLoading(false);
            setResendCode(true);
		}).catch(() => {
			setLoading(false);
		});
	};

    const time = React.useMemo(() => {
        return Date.now() + 60000;
    }, [resendCode]);

    const formatTime = (time) => {
        if (time < 10) {
            return `0${time}`;
        }
        return time;
    };

	return (
		<Box 
            sx={{
                backgroundColor: GRAY_BG_BODY,
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Grid container sx={{backgroundColor: LINE_TABLE, width: "600px", borderRadius: 2, border: `1px solid ${GRAY_BORDER_TABLE}`, padding: 2}}>
                <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center", paddingX: 6 }}>
                    <p style={styles.header}>Por favor, digite o código que enviamos para o seu e-mail
                    </p>
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10 }}>
                    <h1 style={styles.text}>O código foi enviado para {email}</h1>
                </Grid>
                <Grid item xs={12} sx={{marginBottom: 4}}>
                    <ThemeProvider theme={theme}>
                        <Box component="form" sx={{ marginTop: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                            <TextField
                                data-testid="code-input"
                                margin="normal"
                                fullWidth
                                label="Código de confirmação"
                                autoComplete="code"
                                autoFocus
                                onChange={(e) => setCode(e.target.value)}
                                value={code}
                                error={error}
                                sx={{ 
                                    width: "80%",
                                }}
                            />
                            {
                                successMessage && (
                                    <p style={{color: GREEN_SUCCESS_UX, fontWeight: "400", fontSize: "16px"}}>Um novo código foi enviado para o e-mail informado.</p>
                                ) 
                            }
                            {
                                loading ? (
                                    <Loading />
                                ) : (
                                    <ThemeProvider theme={theme}>
                                        <Button
                                            data-testid="submit-button"
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            onClick={() => handleSubmit()}
                                            style={{
                                                backgroundColor: YELLOW_BG_UX,
                                                color: BLACK_BUTTON,
                                                border: `1px solid ${YELLOW_BG_UX}`,
                                                fontWeight: "600",
                                                boxShadow: "none",
                                                width: "80%",
                                                marginBottom: "10px",
                                                marginTop: "20px"
                                            }}
                                        >
                                            Recuperar senha 
                                        </Button>
                                    </ThemeProvider>
                                )
                            }
                            { 
                                (resendCode === true) && (
                                    <Countdown date={time} onComplete={() => {setResendCode(false); setSuccessMessage(false);}} renderer={({ minutes, seconds }) => {
                                        return <span style={{color: "#718096", fontWeight: "400", fontSize: "16px"}}>Aguarde {formatTime(minutes)}:{formatTime(seconds)} segundos para reenviar o código novamente</span>;
                                    }}/>
                                )
                            }
                            {
                                loading ? (
                                    <Loading />
                                ) : (
                                    <ThemeProvider theme={theme}>
                                        <Button
                                            data-testid="resend-button"
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            onClick={() => {resendVerificationCode(); setSuccessMessage(true);}}
                                            style={{...styleButton, width: "80%", marginBottom: "20px"}}
                                            disabled={resendCode}
                                        >
                                            Reenviar código
                                        </Button>
                                    </ThemeProvider>
                                )
                            }
                            <Grid container sx={{marginTop: 3}}>
                                <Grid item xs={12}>
                                    <LinkRouter data-testid="redirect-back-login" to='/' variant="body2" style={{textDecoration: "none", color: GRAY_LABEL_UX, fontWeight: "600", fontSize: "17px", display: "flex", justifyContent: "center"}}>
                                        Voltar ao login
                                    </LinkRouter>
                                </Grid>
                            </Grid>
                        </Box>
                    </ThemeProvider>
                </Grid>
                <div style={styles.copyright}>
                    <CopyRight color="black"/>
                </div>
            </Grid>
        </Box>
	);
}

const styles = {
    center: {
        width: "60%",
        marginLeft: "auto",
        marginRight: "auto",
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    logo: {
        userDrag: "none",
        userSelect: "none",
        pointerEvents: "none",
        width: "191px",
        height: "36px"
    },
    header: {
        width: "auto",
        height: "15px",
        fontFamily: "Kallisto",
        fontWeight: "bold",
        fontSize: "36px",
        lineHeight: "32px",
        color: GRAY_HEADER_UX,
    },
    text: {
        width: "411px",
        height: "50px",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "20px",
        color: GRAY_LABEL_UX,
        textAlign: "center",
    },
    copyright: {
        textAlign: "center",
        display: "flex",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "end",
        paddingBottom: "40px",
    },
    buttonSubmit: {
        marginTop: 3,
        marginBottom: 2,
        width: "80%",
    }
};