import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DefaultButton from "./DefaultButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
    BLACK_TABLE_THEME,
} from "../../shared/utils";
import { changeOpenLancamento } from "../../actions/StoreActions";


export default function OptionsNovaEXM(props) {
    const dispatch = useDispatch();

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const addCircleOutlineIcon = useMemo(() => (
		<AddCircleOutlineIcon/>
	), []);

    const redirectNovaReceita = () => {
        dispatch(changeOpenLancamento("receita"));
    };

    const handleClickDespesa = () => {
        dispatch(changeOpenLancamento("despesa"));
    };
    
    const handleClickTransferencia = () => {
        dispatch(changeOpenLancamento("transferencia"));    
    };
    
    return (
        <React.Fragment>

            <DefaultButton
                title="NOVA"
                startIcon={addCircleOutlineIcon}
                onClick={handleClick}
                testId="options-new-menu"
                className={props.className}
            />
            <Menu
                onClick={() => {}}
                sx={{
                    "& .css-kc02vp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper" : {
                        background: temaEscuro && BLACK_TABLE_THEME
                    }
                }}
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <MenuItem
                    onClick={() => redirectNovaReceita()}
                    data-testid="nova-receita-button"
                    className="button_percentage"
                >
                    Receita
                </MenuItem>
                <MenuItem
                    onClick={handleClickDespesa}
                    data-testid="nova-despesa-button"
                    className="button_percentage"
                >
                    Despesa
                </MenuItem>
                <MenuItem
                    onClick={handleClickTransferencia}
                    data-testid="nova-transferencia-button"
                    className="button_percentage"
                >
                    Transferência
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}