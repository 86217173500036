import React from "react";
import Paper from "@mui/material/Paper";
import { BLUE_THEME, BORDER_BLACK_THEME_CARD, BORDER_BUTTON, GRAY_PDF, INFO_THEME, LINE_TABLE, PAPER_PADDING_THEME, BORDER_PAPER, BORDER_TABLE } from "../../shared/utils";
import Grid from "@mui/material/Grid";
import { FileUploader } from "react-drag-drop-files";
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import ClearIcon from "@mui/icons-material/Clear";
import planilhaExample from "../../assets/images/planilha_example.png";
import importIlustration from "../../assets/images/import_planilha.png";
import DefaultButton from "../Buttons/DefaultButton";


const ImportFileFirstStep = ({sheet, setSheet, fecharImportar, processPlanilha}) => {
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const downloadTemplate = () => {
        const link = document.createElement("a");        
        link.href = "/docs/Planilha_Modelo_VXMF.xls";
        link.download = "Planilha_Modelo_VXMF.xls";
        link.click();
    };

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <span><p className="title-container-p">Como importar seus lançamentos</p></span>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} /> 
                </Grid>
                <Grid item xs={12}>
                    <p style={{fontSize: "15px", margin: "0px 0px 20px 0px"}}>
                        A importação de lançamentos em planilha passa por três etapas: primeiro, você envia uma planilha, depois, confirma os dados encontrados e, por fim, finaliza a importação. O processo completo pode demorar alguns minutos e vamos precisar da sua ajuda em todas as etapas.
                    </p>
                </Grid>
                <Grid item xs={12}>
                    <span><p className="title-container-p">Faça download da planilha modelo</p></span>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} /> 
                </Grid>
            </Grid>
            <Grid container sx={{display: "flex", mt: 2, mb: 3, alignItems: "center"}}>
                <Grid item xs={3} sx={{display: "flex", flexDirection: "column", alignItems: "center", gap: 3}}>
                    <img src={importIlustration} style={{width: "50%"}}/>
                    <DefaultButton
                        title="Fazer download"
                        onClick={() => downloadTemplate()}
                    />
                </Grid>
                <Grid item xs={9} sx={{display: "flex", flexDirection: "column"}}>
                    <p style={{marginBottom: ".4rem", fontSize: "14px"}}>Para importar seus lançamentos a partir de uma planilha, ela precisa estar <strong>no formato correto da planilha modelo</strong> que disponibilizamos. As planilhas serão importadas <strong>tanto para despesas como para receitas.</strong></p>
                    <span style={{marginBottom: "1rem"}}>Exemplo:</span>
                    <img src={planilhaExample}/>
                    <p style={{fontSize: "14px"}}>O preenchimento dos campos é <strong>obrigatório</strong>, com exceção de:</p>
                    <ul style={{margin: 0, paddingLeft: "2rem", display: "flex", flexDirection: "column", gap: "1rem"}}>
                        <li style={{fontSize: "14px"}}>
                            Centro de custo
                        </li>
                        <li style={{fontSize: "14px"}}>
                            Cliente/Fornecedor
                        </li>
                        <li style={{fontSize: "14px"}}>
                            CPF/CNPJ nos casos de <strong>apenas um</strong> tipo papel (cliente, fornecedor ou transportadora)
                        </li>
                        <li style={{fontSize: "14px"}}>
                            Observações.
                        </li>
                    </ul>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{marginTop: 2}}>
                <Grid item xs={12}>
                    <span><p className="title-container-p">Confira as regras abaixo e selecione a planilha</p></span>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} /> 
                </Grid>
            </Grid>
            <ul style={{paddingLeft: "1.2rem", display: "flex", flexDirection: "column", gap: ".5rem", marginBottom: "2rem"}}>
                <li style={{fontSize: "14px"}}>Formatos permitidos: <strong>XLS e XLSX</strong></li>
                <li style={{fontSize: "14px"}}>Tamanho máximo: <strong>10 Mb</strong></li>
                <li style={{fontSize: "14px"}}>
                    Se você não quiser dar baixa em algum lançamento, é só deixar o campo “Data de pagamento/recebimento” em branco.
                </li>
                <li style={{fontSize: "14px"}}>
                    O sinal de (-) indica valores a pagar, por isso é importante conferir se todas as despesas estão com símbolo de subtração.  
                </li>
                <li style={{fontSize: "14px"}}>
                    Para transferências, <strong>não é necessário</strong> o preenchimento dos seguintes campos: Data de vencimento, Data de Pagamento, Categoria.
                </li>
                <li style={{fontSize: "14px"}}>
                    Para lançar transferências, é necessário preencher a descrição em um lançamento único no seguinte formato: Origem: <strong> apelido da sua conta origem</strong> / Destino: <strong>apelido da sua conta destino</strong>.
                </li>
            </ul>
            <p style={{fontSize: "14px", margin: 0}}>Selecione a planilha para importar:</p>
            <Grid container sx={{mt: 1, mb: 6}}>
                <FileUploader
                    label="Busque ou arraste um arquivo até aqui"
                    hoverTitle="Arraste o arquivo até aqui"
                    handleChange={setSheet}
                    multiple={false}
                    maxSize={10}
                    types={["XLS", "XLSX"]}
                >
                    <Grid container sx={{display: "flex", gap: 2}}>
                        <Paper sx={{width: "30vw",p: ".5rem 3rem .5rem .5rem", display: "flex", boxShadow: "none", backgroundColor: temaEscuro ? BLUE_THEME : LINE_TABLE, backgroundImage: temaEscuro && "none", border: temaEscuro ? `2px dashed ${BORDER_BLACK_THEME_CARD}` : `1px dashed ${BORDER_BLACK_THEME_CARD}`}}>
                            {
                                sheet !== null ? (
                                    <div style={{display: "flex", alignItems: "start", gap: .5, backgroundColor: temaEscuro ? PAPER_PADDING_THEME : GRAY_PDF, padding: 3, borderRadius: "5px"}}>
                                        <p style={{margin: 0, fontSize: "14px", fontWeight: "400"}}>{sheet.name}</p>
                                        <Divider orientation="vertical" sx={{margin: "0 .1rem 0 .4rem", backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} />
                                        <ClearIcon
                                            sx={{cursor: "pointer", width: "18px", height: "18px"}}
                                            onClick={() => setSheet(null)}
                                        />
                                    </div>
                                ) : (
                                    <p style={{margin: 0, color: temaEscuro && BORDER_BUTTON}}>Escolha um arquivo ou arraste-o para este espaço</p>
                                )
                            }
                        </Paper>
                    </Grid>
                </FileUploader>
            </Grid>
            <Divider sx={{backgroundColor: INFO_THEME, opacity: "0.3"}}/>
            <Grid container sx={{display: "flex", justifyContent: "space-between", mt: 2}}>
                <DefaultButton
                    title="Fechar"
                    onClick={() => fecharImportar()}
                />
                <DefaultButton
                    title="Continuar"
                    disabled={sheet === null}
                    onClick={() => processPlanilha()}
                    variant="yellow"
                />
            </Grid>
        </React.Fragment>
    );
};

export default ImportFileFirstStep;