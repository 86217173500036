import React, {useState, useRef, useEffect} from "react";
import Header from "../../components/Titles/Header";
import Paper from "@mui/material/Paper";
import RepeatIcon from "@mui/icons-material/Repeat";
import {useSelector, useDispatch} from "react-redux";
import {
    PAPER_PADDING_THEME,
    paperStyle,
    LINE_TABLE,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    BLUE_THEME,
    WHITE_ESCRITA_THEME,
    GRAY_LABEL_UX,
    formatterCurrency,
    YELLOW_BG_UX,
    GRAY_BG_UX,
    BORDER_BUTTON,
    RED_INFO,
    WHITE_TABLE,
    BORDER_TABLE,
    GRAY_BORDER_TABLE,
    GRAY_LABEL_UX_THEME,
    BLACK_TABLE,
    BLACK_LABEL_UX,
    LIGHT_YELLOW_BG_UX,
    LIGHT_GREEN_BG_UX,
    LIGHT_GREEN_TEXT_UX,
    LIGHT_YELLOW_TEXT_UX,
    LIGHT_BLUE_BG_UX,
    LIGHT_BLUE_TEXT_UX,
    DARK_YELLOW_BG_UX,
    DARK_YELLOW_TEXT_UX,
    DARK_GREEN_BG_UX,
    DARK_GREEN_TEXT_UX,
    DARK_BLUE_BG_UX,
    DARK_BLUE_TEXT_UX,
} from "../../shared/utils";
import Skeleton from "@mui/material/Skeleton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import SelectEstabelecimento from "../../components/SelectEstabelecimento/SelectEstabelecimento";
import {showSnackMessage} from "../../actions/SnackActions";
import {Grid} from "@mui/material";
import FilterEXM from "../../components/Filter/FilterEXM";
import {DateRangePicker} from "@mui/x-date-pickers-pro/DateRangePicker";
import {SingleInputDateRangeField} from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import InputSearch from "../../components/Input/InputSearch.js";
import OptionsMenuEXM from "../../components/Buttons/OptionsMenuEXM.js";
import OptionsNovaEXM from "../../components/Buttons/OptionsNovaEXM.js";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EXMKPI from "../../components/Dash/EXMKPI";
import EXMFooter from "../../components/Dash/EXMFooter";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import api from "../../axios";
import FilterWithTag from "../../components/Filter/FilterWithTag";
import FilterSingleOption from "../../components/Filter/FilterSingleOption";
import FilterPriceRange from "../../components/Filter/FilterPriceRange";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";
import {useNavigate, useSearchParams} from "react-router-dom";
import DataGridWithServerPagination from "../../components/DataGrid/DataGridWithServerPagination";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {useLocation} from "react-router-dom";
import SelectActions from "../../components/SelectActions/SelectActions.js";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import DefaultButton from "../../components/Buttons/DefaultButton.js";

const EXM = () => {
    const dispatch = useDispatch();
    const nav = useNavigate();
    const location = useLocation();

    const hasPageBeenRendered = useRef(false);

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);

    const [searchParams] = useSearchParams();
    const tipoExtrato = searchParams.get("tipoExtrato");
    const kpi = searchParams.get("kpi");
    const [pageState, setPageState] = useState({
        data: [],
        total: 0,
        page: 1,
        pageSize: 100
    });
    const [loading, setLoading] = useState(false);
    const [filterPlaceholders, setFilterPlaceholders] = useState({});
    const [selectedTab, setSelectedTab] = useState(tipoExtrato || "movimentacoes");
    const [periodo, setPeriodo] = useState(kpi === "vencidos" ? "todo_periodo" : "este_mes");
    const [specificDate, setSpecificDate] = useState("");
    const [arrowCounter, setArrowCounter] = useState(0);
    const [lastSearchedValue, setLastSearchedValue] = useState("");
    const [searchValue, setSearchValue] = useState(lastSearchedValue || "");
    const [previousContas, setPreviousContas] = useState([]);
    const [contas, setContas] = useState([]);
    const [contasSelected, setContasSelected] = useState();
    const [selectedItem, setSelectedItem] = useState([]);
    const [moreFilters, setMoreFilters] = useState(false);
    const [inputCategoria, setInputCategoria] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [previousCategorias, setPreviousCategorias] = useState([]);
    const [inputFornecedor, setInputFornecedor] = useState([]);
    const [fornecedores, setFornecedores] = useState([]);
    const [previousFornecedores, setPreviousFornecedores] = useState([]);
    const [tiposMovimentacoes, setTiposMovimentacoes] = useState([]);
    const [inputTipoMovimentacao, setInputTipoMovimentacao] = useState([]);
    const [previousTipoMovimentacoes, setPreviousTiposMovimentacoes] = useState([]);
    const [formasPagamento, setFormasPagamento] = useState([]);
    const [inputFormaPagamento, setInputFormaPagamento] = useState([]);
    const [previousFormasPagamento, setPreviousFormasPagamento] = useState([]);
    const [inputRecorrencia, setInputRecorrencia] = useState(null);
    const [inputSituacaoAgendamento, setInputSituacaoAgendamento] = useState(null);
    const [situacoes, setSituacoes] = useState([]);
    const [inputSituacao, setInputSituacao] = useState([]);
    const [previousSituacoes, setPreviousSituacoes] = useState([]);
    const [inputSituacaoConciliacao, setInputSituacaoConciliacao] = useState(null);
    const [inputPrevisaoPagamento, setInputPrevisaoPagamento] = useState(null);
    const [inputVencimentoOriginal, setInputVencimentoOriginal] = useState(null);
    const [inputBaixa, setInputBaixa] = useState(null);
    const [inputLancamento, setInputLancamento] = useState(null);
    const [inputValorEmAbertoMin, setInputValorEmAbertoMin] = useState(null);
    const [inputValorEmAbertoMax, setInputValorEmAbertoMax] = useState(null);
    const [inputValorPagoMax, setInputValorPagoMax] = useState(null);
    const [inputValorPagoMin, setInputValorPagoMin] = useState(null);
    const [inputValorParcelaMin, setInputValorParcelaMin] = useState(null);
    const [inputValorParcelaMax, setInputValorParcelaMax] = useState(null);
    const [customPeriodo, setCustomPeriodo] = useState([null, null]);
    const [rowsSelected, setRowsSelected] = useState([]);
    const [totalPeriodoKPI, setTotalPeriodoKPI] = useState(0);
    const [kpis, setKpis] = useState({});

    const [pdfData, setPdfData] = useState([]);
    const [pdfDataKPI, setPdfDataKPI] = useState([]);

    const [selectedKPI, setSelectedKPI] = useState(kpi || "total");

    const [footerPeriodo, setFooterPeriodo] = useState("");

    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const isOperator = useSelector(state => state.AuthReducer.isOperator);
    const isAprover = useSelector(state => state.AuthReducer.isApprover);

    useEffect(() => {
        if (!hasPageBeenRendered.current) {
            return;
        }
        const isCustomPeriodoValid = customPeriodo[0] !== null && customPeriodo[1] !== null;
        const shouldFetchMovimentacoes =
            estabelecimentoId !== 0 &&
            (
                (periodo === "custom_periodo" && isCustomPeriodoValid) ||
                (periodo !== "custom_periodo")
            );

        if (shouldFetchMovimentacoes) {
            getExtratoMovimentacoes();
        }
    }, [estabelecimentoId, arrowCounter, periodo, customPeriodo, pageState.page, pageState.pageSize, selectedKPI]);

    useEffect(() => {
        api.RetrieveContas({estabelecimento_id: estabelecimentoId}).then(response => {
            const contas = [{label: "(Em branco)", value: "(Em branco)"}, ...response.data.map(conta => {
                    return {"label": conta.label, "value": conta.label};
            })];
            setContas(contas);
            setPreviousContas(contas);
        });
    }, [estabelecimentoId]);

    useEffect(() => {
        if (location.state) {
            getExtratoMovimentacoes();
        }
    }, [location]);

    useEffect(() => {
        if (hasPageBeenRendered.current) {
            clearAllFilter();
            setPageState(old => ({...old, page: 1}));
        }

        hasPageBeenRendered.current = true;
    }, [selectedTab]);

    useEffect(() => {
        getExtratoMovimentacoes();
    }, []);

    const getExtratoMovimentacoes = (clear = false, isExport=false) => {
        setLoading(true);
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            periodo: periodo,
            plus_minus: arrowCounter,
            search: clear ? null : searchValue,
            contas: clear ? [] : contasSelected,
            tipo_movimentacao: clear ? [] : inputTipoMovimentacao,
            forma_pagamento: clear ? [] : inputFormaPagamento,
            recorrencia: clear ? null : inputRecorrencia,
            situacao: clear ? [] : inputSituacao,
            situacao_agendamento: clear ? null : inputSituacaoAgendamento,
            situacao_conciliacao: clear ? null : inputSituacaoConciliacao,
            previsao_pagamento: clear ? null : parseDateRangeValue(inputPrevisaoPagamento),
            vencimento_original: clear ? null : parseDateRangeValue(inputVencimentoOriginal),
            baixa: clear ? null : parseDateRangeValue(inputBaixa),
            lancamento: clear ? null : parseDateRangeValue(inputLancamento),
            valor_em_aberto_minimo: clear ? null : inputValorEmAbertoMin,
            valor_em_aberto_maximo: clear ? null : inputValorEmAbertoMax,
            valor_pago_minimo: clear ? null : inputValorPagoMin,
            valor_pago_maximo: clear ? null : inputValorPagoMax,
            valor_parcela_minimo: clear ? null : inputValorParcelaMin,
            valor_parcela_maximo: clear ? null : inputValorParcelaMax,
            fornecedor: clear ? [] : inputFornecedor,
            categoria: clear ? [] : inputCategoria,
            page_size: clear ? 100 : pageState.pageSize,
            page: clear ? 1 : pageState.page,
            kpi: clear ? "total" : selectedKPI,
            tipo_extrato: selectedTab,
        };
        if (periodo === "custom_periodo") {
            let start_date = customPeriodo[0]._d;
            let end_date = customPeriodo[1]._d;
            dataRequest.start_date = `${start_date.getDate()}/${start_date.getMonth() + 1}/${start_date.getFullYear()}`;
            dataRequest.end_date = `${end_date.getDate()}/${end_date.getMonth() + 1}/${end_date.getFullYear()}`;
        }
        if (isExport) {
            dataRequest.is_export = true;
            dataRequest.page_size = null;
            dataRequest.page = null;
        }
        api.GetExtratoMovimentacoes(dataRequest).then(response => {
            setLoading(false);
            let data = response.data;

            if (isExport) {
                window.open(data, "_blank");
                return;
            }
            setFilterPlaceholders(data.filter_placeholders);
            setSpecificDate(data?.specific_date || "");
            setLastSearchedValue(data?.searched_value || "");
            setPageState(old => ({...old, total: data.total_movimentacoes, data: data.extrato}));
            setTotalPeriodoKPI(data.kpis.total);
            setKpis(data.kpis);

            setCategorias(data.categorias);
            setFornecedores(data.fornecedores);
            setTiposMovimentacoes(data.tipos_movimentacao);
            setFormasPagamento(data.formas_pagamento);
            setSituacoes(data.situacoes);

            setPdfData(data.extrato);
            setPdfDataKPI(data.kpis);

            setFooterPeriodo(data.periodo);
            if (data.extrato.length === 0) {
                dispatch(showSnackMessage({message: "Nenhum registro encontrado", severity: "info"}));
            }
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const parseDateRangeValue = (value) => {
        if (value) {
            let start_date = value[0]._d;
            let end_date = value[1]._d;
            start_date = `${start_date.getDate()}/${start_date.getMonth() + 1}/${start_date.getFullYear()}`;
            end_date = `${end_date.getDate()}/${end_date.getMonth() + 1}/${end_date.getFullYear()}`;
            return `${start_date}-${end_date}`;
        }
        return null;
    };

    const handleDateChange = (newValue) => {
        setCustomPeriodo(newValue);
    };

    const handleOpenModalEditMovimentacao = (row) => {
        if (row.situacao === "Transferido"){
            nav("/editar_transferencia", {state: {id_movimentacao: row.id, situacao: row.situacao}});
        } else {
            nav("/editar_movimentacao", {state: {id_movimentacao: row.id, situacao: row.situacao}});
        }        
    };
    
    const getColorAnexo = (row) => {
        const hasAnexo = row.anexo_path;
        const darkThemeColor = hasAnexo ? YELLOW_BG_UX : BORDER_TABLE;
        const lightThemeColor = hasAnexo ? YELLOW_BG_UX : GRAY_BORDER_TABLE;

        return temaEscuro ? darkThemeColor : lightThemeColor;
    };

    const formatTitle = (title) => {
        const capitalized = title.charAt(0).toUpperCase() + title.slice(1);
        return capitalized.replaceAll("_", " ") + " (R$)";
    };

    const handleOpenModalDeleteMovimentacao = (row) => {
        setLoading(true);
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            selected_movimentacoes: [row.id],
        };
        api.DeleteLoteAction(dataRequest).then(() => {
            getExtratoMovimentacoes();
            dispatch(showSnackMessage({message: "Movimentação deletada com sucesso", severity: "success"}));
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const columns =
        {
            "movimentacoes": [
                {
                    field: "formatted_data_movimentacao",
                    headerName: "Data",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                },
                {
                    field: "descricao",
                    headerName: "Descrição",
                    flex: 5,
                    align: "left",
                    headerAlign: "left",
                    renderCell: (params) => {
                        const categorias = params.row.categoria_nome;
                        const recorrencia = params.row.recorrencia;
                        const descricao = recorrencia
                          ? `${recorrencia} - ${params.row.descricao}`
                          : params.row.descricao;
                        const fornecedor = params.row.fornecedor__nome;
                        let title = descricao;
                        title += categorias ? `\nCategoria: ${categorias}` : "";
                        title += fornecedor ? `\nFornecedor: ${fornecedor}` : "";
                        title += recorrencia ? `\nRecorrência: ${recorrencia}` : "";

                        return (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "5px",
                                    justifyContent: "center",
                                    height: "100%",
                                }}
                                title={title}
                            >
                                <Stack alignItems="center" direction="row">
                                {recorrencia && (<RepeatIcon fontSize="small"/>)}
                                <Typography fontWeight="normal" fontSize="15px">
                                    {descricao}
                                </Typography>
                                </Stack>
                                <Typography fontSize="15px">
                                <span
                                    style={{
                                        backgroundColor: GRAY_LABEL_UX,
                                        padding: "3px 10px",
                                        borderRadius: "3px",
                                        color: temaEscuro ? LINE_TABLE : WHITE_TABLE,
                                        fontWeight: "500"
                                    }}
                                >
                                    {categorias.length > 1
                                    ? `${categorias.length} categorias`
                                    : categorias}
                                </span>
                                </Typography>
                                <Typography fontWeight="normal" fontSize="12px">
                                    <span style={{fontSize:"12px", color: BORDER_BUTTON}}>{fornecedor ? ` ${fornecedor}` :  ""}</span>
                                </Typography>
                                
                            </div>
                        );
                    },
                },
                {
                    field: "situacao",
                    headerName: "Situação",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params) => {
                        const previsao = params.row.previsao;
                        return (
                            <Box sx={{display: "flex", justifyContent: "center"}}>
                                <Typography
                                    style={{
                                        backgroundColor: previsao
                                            ? temaEscuro
                                                ? DARK_YELLOW_BG_UX
                                                : LIGHT_YELLOW_BG_UX
                                            : temaEscuro
                                            ? DARK_GREEN_BG_UX
                                            : LIGHT_GREEN_BG_UX,
                                        color: previsao
                                            ? temaEscuro
                                                ? DARK_YELLOW_TEXT_UX
                                                : LIGHT_YELLOW_TEXT_UX
                                            : temaEscuro
                                            ? DARK_GREEN_TEXT_UX
                                            : LIGHT_GREEN_TEXT_UX,
                                        borderRadius: "5px",
                                        padding: "3px 10px",
                                        marginTop: "28px",
                                        fontSize: "15px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "5px",
                                        width: "100%",
                                    }}
                                >
                                    {params.row.situacao}
                                    {params.row.conciliado && (
                                        <CheckCircleIcon fontSize="15px" />
                                    )}
                                    {params.row.agendado && (
                                        <AccessAlarmIcon fontSize="15px" />
                                    )}
                                </Typography>
                            </Box>
                        );
                    }
                },
                {
                    field: "valor_total",
                    headerName: "Valor",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params) => {
                        return (
                            <span style={{
                                color: params.row.valor_total < 0 && RED_INFO,
                                fontWeight: params.row.valor_total < 0 ? "bold" : "normal"
                            }}>
                            {formatterCurrency(params.row.valor_total, 2)}
                        </span>

                        );
                    }
                },
                {
                    field: "saldo",
                    headerName: "Saldo",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params) => {
                        return (
                            <span style={{
                                color: params.row.saldo < 0 && RED_INFO,
                                fontWeight: params.row.saldo < 0 ? "bold" : "normal"
                            }}>
                        {formatterCurrency(params.row.saldo, 2)}
                    </span>
                        );
                    }
                },
                {
                    field: "anexo",
                    headerName: "Anexo",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params) => {
                        return (
                            <AttachFileIcon
                                sx={{color: getColorAnexo(params.row), height: "100%", transform: "rotate(45deg)"}}/>
                        );
                    }
                },
                {
                    field: "acoes",
                    headerName: "Ações",
                    width: 150,
                    align: "center",
                    headerAlign: "center",
                    flex: 1,
                    renderCell: (params) => (
                        <SelectActions
                            params={params.row}
                            selectedTab={selectedTab}
                            deleteAction={handleOpenModalDeleteMovimentacao}
                        />
                    ),
                },
            ],
            "contas_pagar": [
                {
                    field: "formatted_vencimento",
                    headerName: "Vencimento",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                },
                {
                    field: "formatted_pagamento",
                    headerName: "Pagamento",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                },
                {
                    field: "descricao",
                    headerName: "Descrição",
                    flex: 5,
                    align: "left",
                    headerAlign: "left",
                    renderCell: (params) => {
                        const categorias = params.row.categoria_nome;
                        const recorrencia = params.row.recorrencia;
                        const descricao = recorrencia
                          ? `${recorrencia} - ${params.row.descricao}`
                          : params.row.descricao;
                        const fornecedor = params.row.fornecedor__nome;
                        let title = descricao;
                        title += categorias ? `\nCategoria: ${categorias}` : "";
                        title += fornecedor ? `\nFornecedor: ${fornecedor}` : "";
                        title += recorrencia ? `\nRecorrência: ${recorrencia}` : "";

                        return (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "5px",
                                    justifyContent: "center",
                                    height: "100%",
                                }}
                                title={title}
                            >
                                <Stack alignItems="center" direction="row">
                                {recorrencia && (<RepeatIcon fontSize="small"/>)}
                                <Typography fontWeight="normal" fontSize="15px">
                                    {descricao}
                                </Typography>
                                </Stack>
                                <Typography fontSize="15px">
                                <span
                                    style={{
                                        backgroundColor: GRAY_LABEL_UX,
                                        padding: "3px 10px",
                                        borderRadius: "3px",
                                        color: temaEscuro ? LINE_TABLE : WHITE_TABLE,
                                        fontWeight: "500"
                                    }}
                                >
                                    {categorias.length > 1
                                    ? `${categorias.length} categorias`
                                    : categorias}
                                </span>
                                </Typography>
                                <Typography fontWeight="normal" fontSize="12px">
                                    <span style={{fontSize:"12px", color: BORDER_BUTTON}}>{fornecedor ? ` ${fornecedor}` :  ""}</span>
                                </Typography>
                                
                            </div>
                        );
                    },
                },
                {
                    field: "valor_total",
                    headerName: "Total (R$)",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params) => {
                        return (
                            <span>
                                {formatterCurrency(params.row.valor_total, 2)}
                            </span>
                        );
                    }
                },
                {
                    field: "valor_em_aberto",
                    headerName: "A pagar (R$)",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params) => {
                        return (
                            <span>
                                {formatterCurrency(params.row.valor_em_aberto, 2)}
                            </span>
                        );
                    }
                },
                {
                    field: "situacao",
                    headerName: "Situação",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params) => {
                        const valorEmAberto = params.row.valor_em_aberto;
                        const valorPago = params.row.valor_pago;
                        return (
                            <Box>
                                <Typography
                                    style={{
                                        backgroundColor:
                                            valorPago === 0
                                                ? temaEscuro
                                                    ? DARK_YELLOW_BG_UX
                                                    : LIGHT_YELLOW_BG_UX
                                                : valorEmAberto === 0
                                                ? temaEscuro
                                                    ? DARK_GREEN_BG_UX
                                                    : LIGHT_GREEN_BG_UX
                                                : temaEscuro
                                                ? DARK_BLUE_BG_UX
                                                : LIGHT_BLUE_BG_UX,
                                        color:
                                            valorPago === 0
                                                ? temaEscuro
                                                    ? DARK_YELLOW_TEXT_UX
                                                    : LIGHT_YELLOW_TEXT_UX
                                                : valorEmAberto === 0
                                                ? temaEscuro
                                                    ? DARK_GREEN_TEXT_UX
                                                    : LIGHT_GREEN_TEXT_UX
                                                : temaEscuro
                                                ? DARK_BLUE_TEXT_UX
                                                : LIGHT_BLUE_TEXT_UX,
                                        borderRadius: "5px",
                                        padding: "3px 10px",
                                        marginTop: "28px",
                                        fontSize: "15px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "5px",
                                    }}
                                >
                                    {params.row.situacao}
                                    {params.row.conciliado && (
                                        <CheckCircleIcon fontSize="15px" />
                                    )}
                                    {params.row.agendado && (
                                        <AccessAlarmIcon fontSize="15px" />
                                    )}
                                </Typography>
                            </Box>
                        );
                    }
                },
                {
                    field: "acoes",
                    headerName: "Ações",
                    width: 100,
                    align: "center",
                    headerAlign: "center",
                    flex: 1,
                    renderCell: (params) => (
                        <SelectActions
                            params={params.row}
                            selectedTab={selectedTab}
                            deleteAction={handleOpenModalDeleteMovimentacao}
                        />
                    ),
                },
            ],
            "contas_receber": [
                {
                    field: "formatted_vencimento",
                    headerName: "Vencimento",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                },
                {
                    field: "formatted_pagamento",
                    headerName: "Recebimento",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                },
                {
                    field: "descricao",
                    headerName: "Descrição",
                    flex: 5,
                    align: "left",
                    headerAlign: "left",
                    renderCell: (params) => {
                        const categorias = params.row.categoria_nome;
                        const recorrencia = params.row.recorrencia;
                        const descricao = recorrencia
                          ? `${recorrencia} - ${params.row.descricao}`
                          : params.row.descricao;
                        const fornecedor = params.row.fornecedor__nome;
                        let title = descricao;
                        title += categorias ? `\nCategoria: ${categorias}` : "";
                        title += fornecedor ? `\nFornecedor: ${fornecedor}` : "";
                        title += recorrencia ? `\nRecorrência: ${recorrencia}` : "";

                        return (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "5px",
                                    justifyContent: "center",
                                    height: "100%",
                                }}
                                title={title}
                            >
                                <Stack alignItems="center" direction="row">
                                {recorrencia && (<RepeatIcon fontSize="small"/>)}
                                <Typography fontWeight="normal" fontSize="15px">
                                    {descricao}
                                </Typography>
                                </Stack>
                                <Typography fontSize="15px">
                                <span
                                    style={{
                                        backgroundColor: GRAY_LABEL_UX,
                                        padding: "3px 10px",
                                        borderRadius: "3px",
                                        color: temaEscuro ? LINE_TABLE : WHITE_TABLE,
                                        fontWeight: "500"
                                    }}
                                >
                                    {categorias.length > 1
                                    ? `${categorias.length} categorias`
                                    : categorias}
                                </span>
                                </Typography>
                                <Typography fontWeight="normal" fontSize="12px">
                                    <span style={{fontSize:"12px", color: BORDER_BUTTON}}>{fornecedor ? ` ${fornecedor}` :  ""}</span>
                                </Typography>
                                
                            </div>
                        );
                    },
                },
                {
                    field: "valor_total",
                    headerName: "Total (R$)",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params) => {
                        return (
                            <span>
                                {formatterCurrency(params.row.valor_total, 2)}
                            </span>
                        );
                    }
                },
                {
                    field: "valor_em_aberto",
                    headerName: "A receber (R$)",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params) => {
                        return (
                            <span>
                                {formatterCurrency(params.row.valor_em_aberto, 2)}
                            </span>
                        );
                    }
                },
                {
                    field: "situacao",
                    headerName: "Situação",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params) => {
                        const valorEmAberto = params.row.valor_em_aberto;
                        const valorPago = params.row.valor_pago;
                        return (
                            <Box>
                                <Typography
                                    style={{
                                        backgroundColor:
                                            valorPago === 0
                                                ? temaEscuro
                                                    ? DARK_YELLOW_BG_UX
                                                    : LIGHT_YELLOW_BG_UX
                                                : valorEmAberto === 0
                                                ? temaEscuro
                                                    ? DARK_GREEN_BG_UX
                                                    : LIGHT_GREEN_BG_UX
                                                : temaEscuro
                                                ? DARK_BLUE_BG_UX
                                                : LIGHT_BLUE_BG_UX,
                                        color:
                                            valorPago === 0
                                                ? temaEscuro
                                                    ? DARK_YELLOW_TEXT_UX
                                                    : LIGHT_YELLOW_TEXT_UX
                                                : valorEmAberto === 0
                                                ? temaEscuro
                                                    ? DARK_GREEN_TEXT_UX
                                                    : LIGHT_GREEN_TEXT_UX
                                                : temaEscuro
                                                ? DARK_BLUE_TEXT_UX
                                                : LIGHT_BLUE_TEXT_UX,
                                        borderRadius: "5px",
                                        padding: "3px 10px",
                                        marginTop: "28px",
                                        fontSize: "15px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "5px",
                                    }}
                                >
                                    {params.row.situacao}
                                    {params.row.conciliado && (
                                        <CheckCircleIcon fontSize="15px" />
                                    )}
                                    {params.row.agendado && (
                                        <AccessAlarmIcon fontSize="15px" />
                                    )}
                                </Typography>
                            </Box>
                        );
                    }
                },
                {
                    field: "acoes",
                    headerName: "Ações",
                    width: 100,
                    align: "center",
                    headerAlign: "center",
                    flex: 1,
                    renderCell: (params) => (
                        <SelectActions
                            params={params.row}
                            selectedTab={selectedTab}
                            deleteAction={handleOpenModalDeleteMovimentacao}
                        />
                    ),
                },
            ],
            "visao_competencia": [
                {
                    field: "formatted_competencia",
                    headerName: "Competência",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                },
                {
                    field: "descricao",
                    headerName: "Descrição",
                    flex: 2,
                    align: "left",
                    headerAlign: "left",
                    renderCell: (params) => {
                        const recorrencia = params.row.recorrencia;
                        const descricao = recorrencia ? `${recorrencia} - ${params.row.descricao}` : params.row.descricao;
                        return <span style={{display: "flex", alignItems: "center", gap: "5px"}}>
                            {recorrencia && (<RepeatIcon fontSize="small"/>)}{descricao}
                        </span>;
                    }
                },
                {
                    field: "fornecedor_nome",
                    headerName: "Fornecedor",
                    flex: 2,
                    align: "left",
                    headerAlign: "left",
                },
                {
                    field: "categoria_nome",
                    headerName: "Categoria",
                    flex: 2,
                    align: "left",
                    headerAlign: "left",
                    renderCell: (params) => {
                        const categorias = params.value;

                        return (
                            <span
                                style={{
                                    backgroundColor: GRAY_LABEL_UX,
                                    padding: 8,
                                    borderRadius: "3px",
                                    color: temaEscuro ? LINE_TABLE : WHITE_TABLE
                                }}
                                title={categorias.length > 1 ? categorias.join(", ") : categorias}
                            >
                                {categorias.length > 1 ? `${categorias.length} categorias` : categorias}
                            </span>
                        );
                    },
                },
                {
                    field: "condicao_pagamento",
                    headerName: "Cond. Pagto",
                    flex: 1,
                    align: "left",
                    headerAlign: "left",
                },
                {
                    field: "valor",
                    headerName: "Valor",
                    flex: 1,
                    align: "right",
                    headerAlign: "center",
                    renderCell: (params) => {
                        return (
                            <span style={{
                                color: params.row.valor < 0 && RED_INFO,
                                fontWeight: params.row.valor < 0 ? "bold" : "normal"
                            }}>
                            {formatterCurrency(params.row.valor, 2)}
                        </span>

                        );
                    }
                },
                {
                    field: "anexo_path",
                    headerName: "Anexo",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params) => {
                        return (
                            <AttachFileIcon
                                sx={{color: getColorAnexo(params.row), height: "100%", transform: "rotate(45deg)"}}/>
                        );
                    }
                },
                {
                    field: "acoes",
                    headerName: "Ações",
                    width: 100,
                    align: "center",
                    headerAlign: "center",
                    flex: 1,
                    renderCell: (params) => (
                        <SelectActions
                            params={params.row}
                            selectedTab={selectedTab}
                            deleteAction={handleOpenModalDeleteMovimentacao}
                        />
                    ),
                },
            ],
        };

    const clearAllFilter = () => {
        setPeriodo("este_mes");
        setSelectedKPI("total");
        setContasSelected([]);
        setSearchValue("");
        setLastSearchedValue("");
        setMoreFilters(false);
        setInputCategoria([]);
        setPreviousCategorias([]);
        setInputFornecedor([]);
        setPreviousFornecedores([]);
        setInputTipoMovimentacao([]);
        setPreviousTiposMovimentacoes([]);
        setInputFormaPagamento([]);
        setPreviousFormasPagamento([]);
        setInputPrevisaoPagamento(null);
        setInputSituacao([]);
        setPreviousSituacoes([]);
        setInputSituacaoAgendamento(null);
        setInputSituacaoConciliacao(null);
        setInputVencimentoOriginal(null);
        setInputBaixa(null);
        setInputLancamento(null);
        setInputValorEmAbertoMin(null);
        setInputValorEmAbertoMax(null);
        setInputValorPagoMin(null);
        setInputValorPagoMax(null);
        setInputValorParcelaMin(null);
        setInputValorParcelaMax(null);
        setInputRecorrencia(null);
        getExtratoMovimentacoes(true);
    };

    const openMoreFilters = () => {
        setMoreFilters(!moreFilters);
    };

    const getPDFData = () => {
        let dataRequest = {
            "estabelecimento_id": estabelecimentoId,
            "extrato_data": pdfData,
            "kpis": pdfDataKPI,
            "periodo": periodo,
            "plus_minus": arrowCounter,
        };

        api.GetPDFData(dataRequest).then((response) => {
            if (response.data) {
                const blob = new Blob([response.data], {type: "application/pdf"});
                const url = window.URL.createObjectURL(blob);
    
                window.open(url, "_blank");
                window.URL.revokeObjectURL(url);
            }
        });
    };

    const deleteMovimentacoesLote = () => {
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            selected_movimentacoes: selectedItem,
        };

        api.DeleteLoteAction(dataRequest).then((response) => {
            getExtratoMovimentacoes();
            dispatch(showSnackMessage({message: response.data, severity: "success"}));
        }).catch(() => {
            dispatch(showSnackMessage({
                message: "Não foi possível deletar as movimentações selecionadas.",
                severity: "error"
            }));
        });
    };

    const handleEditMovimentacao = (row) => {
        if ((isAdmin || isOperator || isAprover)) {
            handleOpenModalEditMovimentacao(row);
        }
    };

    const handleChangeTab = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <div className="main" data-testid="exm-container">
            <Header title="EXM - Extrato de Movimentações" dataTestId="header-exm"/>
            <Paper
                sx={{
                    ...paperStyle,
                    backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE,
                    backgroundImage: temaEscuro && "none",
                    border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`
                }}
            >
                {estabelecimentoId === 0 ? (
                    <SelectEstabelecimento/>
                ) : (
                    <Grid container data-testid="extratoMovimentacoes" sx={{display: "flex", alignItems: "center"}}>
                        <Grid item xs={12}>
                            <Grid container
                                    sx={{display: "flex", flexDirection: "row", gap: 2, flexWrap: "wrap"}}>
                                <Grid item>
                                    <FilterEXM
                                        placeholders={filterPlaceholders}
                                        periodo={periodo}
                                        setPeriodo={setPeriodo}
                                        specificDate={specificDate}
                                        setSpecificDate={setSpecificDate}
                                        arrowCounter={arrowCounter}
                                        setArrowCounter={setArrowCounter}
                                        getExtratoMovimentacoes={getExtratoMovimentacoes}
                                        local="extrato_movimentacoes"
                                    />
                                    {
                                        periodo === "custom_periodo" && (
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DateRangePicker
                                                    value={customPeriodo}
                                                    onChange={handleDateChange}
                                                    localeText={{start: "Início", end: "Fim"}}
                                                    format="DD/MM/YYYY"
                                                    sx={{
                                                        width: "330px",
                                                        marginTop: 2,
                                                        "& .MuiInputLabel-root": {color: temaEscuro && BORDER_BUTTON}
                                                    }}
                                                    slotProps={{
                                                        textField: {
                                                            size: "small",
                                                            sx: {backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}
                                                        }
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        )
                                    }
                                </Grid>
                                <Grid item>
                                    <InputSearch
                                        periodo={periodo}
                                        setSearchValue={setSearchValue}
                                        searchValue={searchValue}
                                        functionProps={getExtratoMovimentacoes}
                                        className="input-text"
                                        EXM
                                    />
                                </Grid>
                                <Grid item>
                                    <FilterWithTag
                                        placeholderProp="Conta"
                                        options={contas}
                                        setOptions={setContasSelected}
                                        previousValue={previousContas}
                                        limitSelection={2}
                                        limitTagsOnFocus={true}
                                        widthSetting={"330px"}
                                        optionFilter
                                    />
                                </Grid>
                                <Grid item>
                                    <DefaultButton
                                        testId="filtrar-button"
                                        title="Filtrar"
                                        onClick={() => {
                                            setPageState(old => ({...old, page: 1}));
                                            getExtratoMovimentacoes();
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            moreFilters && (
                                <Grid item xs={7} data-testid="more-filters"
                                        sx={{marginTop: 2, marginBottom: 2, display: "flex", gap: 2, flexWrap: "wrap"}}>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    {selectedTab == "movimentacoes" &&
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DateRangePicker
                                            label="Baixa"
                                            value={inputBaixa}
                                            onChange={(value) => setInputBaixa(value)}
                                            format="DD/MM/YYYY"
                                            sx={{
                                                "& .MuiInputLabel-root": {color: temaEscuro && BORDER_BUTTON}
                                            }}
                                            slots={{
                                                field: SingleInputDateRangeField
                                            }}
                                            slotProps={{
                                                textField: {
                                                    size: "small",
                                                    sx: {
                                                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                                                        color: temaEscuro && BORDER_BUTTON,
                                                        width: "235px",
                                                        "& .MuiInputLabel-root": {color: temaEscuro && BORDER_BUTTON},
                                                    }
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                    }
                                    {["movimentacoes", "contas_pagar", "contas_receber", "visao_competencia"].includes(selectedTab) &&
                                        <FilterWithTag
                                            placeholderProp="Categoria"
                                            options={categorias}
                                            setOptions={setInputCategoria}
                                            previousValue={previousCategorias}
                                            widthSetting={"250px"}
                                            limitSelection={2}
                                            limitTagsOnFocus={true}
                                            optionFilter
                                        />
                                    }
                                    {["movimentacoes", "contas_pagar", "contas_receber", "visao_competencia"].includes(selectedTab) &&
                                        <FilterWithTag
                                            placeholderProp="Fornecedor"
                                            options={fornecedores}
                                            setOptions={setInputFornecedor}
                                            previousValue={previousFornecedores}
                                            widthSetting={"250px"}
                                            limitSelection={2}
                                            limitTagsOnFocus={true}
                                            optionFilter
                                        />
                                    }
                                    {selectedTab == "movimentacoes" &&
                                        <FilterWithTag
                                            placeholderProp="Tipo da movimentação"
                                            options={tiposMovimentacoes}
                                            setOptions={setInputTipoMovimentacao}
                                            previousValue={previousTipoMovimentacoes}
                                            widthSetting={"250px"}
                                            limitSelection={2}
                                            limitTagsOnFocus={true}
                                            optionFilter
                                        />
                                    }
                                    {["movimentacoes", "contas_pagar", "contas_receber"].includes(selectedTab) &&
                                        <FilterWithTag
                                            placeholderProp="Forma de pagamento"
                                            options={formasPagamento}
                                            setOptions={setInputFormaPagamento}
                                            previousValue={previousFormasPagamento}
                                            widthSetting={"250px"}
                                            limitSelection={2}
                                            limitTagsOnFocus={true}
                                            optionFilter
                                        />
                                    }
                                    {["movimentacoes", "contas_pagar", "contas_receber", "visao_competencia"].includes(selectedTab) &&
                                        <FilterSingleOption
                                            placeholder="Recorrência"
                                            filterOptions={["Sim", "Não"]}
                                            setSelectedOption={setInputRecorrencia}
                                            selectedOption={inputRecorrencia}
                                            width={"235px"}
                                        />
                                    }
                                    {["movimentacoes", "contas_pagar", "contas_receber"].includes(selectedTab) &&
                                        <FilterWithTag
                                            placeholderProp="Situação"
                                            options={situacoes}
                                            setOptions={setInputSituacao}
                                            previousValue={previousSituacoes}
                                            widthSetting={"250px"}
                                            limitSelection={2}
                                            limitTagsOnFocus={true}
                                            optionFilter
                                        />
                                    }
                                    {["movimentacoes", "contas_pagar", "contas_receber"].includes(selectedTab) &&
                                        <FilterSingleOption
                                            placeholder="Agendamento"
                                            filterOptions={["Agendado", "Não agendado"]}
                                            setSelectedOption={setInputSituacaoAgendamento}
                                            selectedOption={inputSituacaoAgendamento}
                                            width={"235px"}
                                        />
                                    }
                                    {selectedTab == "movimentacoes" &&
                                        <FilterSingleOption
                                            placeholder="Conciliação"
                                            filterOptions={["Conciliado", "Não conciliado"]}
                                            setSelectedOption={setInputSituacaoConciliacao}
                                            selectedOption={inputSituacaoConciliacao}
                                            width={"235px"}
                                        />
                                    }
                                    {["movimentacoes", "contas_pagar", "contas_receber"].includes(selectedTab) &&
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DateRangePicker
                                            label="Previsão de pagamento"
                                            value={inputPrevisaoPagamento}
                                            onChange={(value) => setInputPrevisaoPagamento(value)}
                                            format="DD/MM/YYYY"
                                            sx={{
                                                "& .MuiInputLabel-root": {color: temaEscuro && BORDER_BUTTON}
                                            }}
                                            slots={{
                                                field: SingleInputDateRangeField
                                            }}
                                            slotProps={{
                                                textField: {
                                                    size: "small",
                                                    sx: {
                                                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                                                        color: temaEscuro && BORDER_BUTTON,
                                                        width: "235px",
                                                        "& .MuiInputLabel-root": {color: temaEscuro && BORDER_BUTTON},
                                                    }
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                    }
                                    {selectedTab == "movimentacoes" &&
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DateRangePicker
                                                label="Vencimento original"
                                                value={inputPrevisaoPagamento}
                                                onChange={(value) => setInputVencimentoOriginal(value)}
                                                format="DD/MM/YYYY"
                                                sx={{
                                                    "& .MuiInputLabel-root": {color: temaEscuro && BORDER_BUTTON}
                                                }}
                                                slots={{
                                                    field: SingleInputDateRangeField
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        sx: {
                                                            backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                                                            color: temaEscuro && BORDER_BUTTON,
                                                            width: "235px",
                                                            "& .MuiInputLabel-root": {color: temaEscuro && BORDER_BUTTON},
                                                        }
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                    }
                                    {selectedTab == "movimentacoes" &&
                                        <FilterPriceRange
                                            inputValorMin={inputValorEmAbertoMin}
                                            setInputValorMin={setInputValorEmAbertoMin}
                                            inputValorMax={inputValorEmAbertoMax}
                                            setInputValorMax={setInputValorEmAbertoMax}
                                            width="250px"
                                            label="Valor em aberto (R$)"
                                        />
                                    }
                                    {selectedTab == "movimentacoes" &&
                                        <FilterPriceRange
                                            inputValorMin={inputValorPagoMin}
                                            setInputValorMin={setInputValorPagoMin}
                                            inputValorMax={inputValorPagoMax}
                                            setInputValorMax={setInputValorPagoMax}
                                            width="250px"
                                            label="Valor Pago/Recebido (R$)"
                                        />
                                    }
                                    {["contas_pagar", "contas_receber", "visao_competencia"].includes(selectedTab) &&
                                        <FilterPriceRange
                                            inputValorMin={inputValorParcelaMin}
                                            setInputValorMin={setInputValorParcelaMin}
                                            inputValorMax={inputValorParcelaMax}
                                            setInputValorMax={setInputValorParcelaMax}
                                            width="250px"
                                            label={["contas_pagar", "contas_receber"].includes(selectedTab) ? "Valor da parcela (R$)" : "Valor (R$)"}
                                        />
                                    }
                                    {["contas_pagar", "contas_receber"].includes(selectedTab) &&
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DateRangePicker
                                                label="Data do lançamento"
                                                value={inputLancamento}
                                                onChange={(value) => setInputLancamento(value)}
                                                format="DD/MM/YYYY"
                                                sx={{
                                                    "& .MuiInputLabel-root": {color: temaEscuro && BORDER_BUTTON}
                                                }}
                                                slots={{
                                                    field: SingleInputDateRangeField
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        sx: {
                                                            backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                                                            color: temaEscuro && BORDER_BUTTON,
                                                            width: "235px",
                                                            "& .MuiInputLabel-root": {color: temaEscuro && BORDER_BUTTON},
                                                        }
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>

                                    }
                                </Grid>
                            )
                        }
                        <Grid item xs={12}>
                            <Grid container columnSpacing={2}>
                                <Grid item data-testid="filtros-btn" sx={{
                                    fontSize: "14px",
                                    color: temaEscuro ? WHITE_ESCRITA_THEME : BLUE_THEME,
                                    display: "flex",
                                    gap: 0.5,
                                    alignItems: "center",
                                    cursor: "pointer"
                                }} onClick={openMoreFilters}>
                                    {
                                        moreFilters ? (
                                            <RemoveCircleOutlineIcon sx={{fontSize: "18px", color: RED_INFO}}/>
                                        ) : (
                                            <AddCircleOutlineIcon sx={{
                                                fontSize: "18px",
                                                color: temaEscuro ? WHITE_ESCRITA_THEME : BLUE_THEME
                                            }}/>
                                        )
                                    }
                                    <span>
                                        <p>Filtros</p>
                                    </span>
                                </Grid>
                                <Grid item data-testid="limpar-filtros-btn" sx={{
                                    fontSize: "14px",
                                    color: temaEscuro ? WHITE_ESCRITA_THEME : BLUE_THEME,
                                    display: "flex",
                                    gap: 0.5,
                                    alignItems: "center",
                                    cursor: "pointer"
                                }} onClick={clearAllFilter}>
                                    <DeleteOutlineIcon sx={{
                                        fontSize: "18px",
                                        color: temaEscuro ? WHITE_ESCRITA_THEME : BLUE_THEME
                                    }}/>
                                    <span>
                                        <p>Limpar filtros</p>
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            (isAdmin || isOperator || isAprover) && (
                                <Grid item sx={{
                                    display: "flex",
                                    flex: 1,
                                    gap: 2,
                                    justifyContent: "end",
                                    alignItems: "start"
                                }}>
                                    <OptionsNovaEXM/>
                                    <OptionsMenuEXM
                                        className="button_options"
                                        getPDFData={getPDFData}
                                        getExtratoMovimentacoes={getExtratoMovimentacoes}
                                        selectedTab={selectedTab}
                                    />
                                </Grid>
                            )
                        }
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{backgroundColor: "transparent", display: "flex", flexWrap: "wrap"}}>
                                <Tabs
                                    value={selectedTab}
                                    onChange={handleChangeTab}
                                    TabIndicatorProps={{style: {backgroundColor: "transparent"}}}
                                    className="buttonTabs"
                                >
                                    <Tab
                                        value={"movimentacoes"}
                                        label="Movimentações"
                                        sx={{
                                            borderRadius: "20px",
                                            color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                            backgroundColor: selectedTab === "movimentacoes" ? YELLOW_BG_UX : "transparent",
                                            "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                color: BLACK_LABEL_UX,
                                            },
                                        }}
                                        data-testid="movimentacoes-tab"
                                    />
                                    <Tab
                                        value={"contas_pagar"}
                                        label="Contas a pagar"
                                        sx={{
                                            borderRadius: "20px",
                                            color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                            backgroundColor: selectedTab === "contas_pagar" ? YELLOW_BG_UX : "transparent",
                                            "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                color: BLACK_LABEL_UX,
                                            },
                                        }}
                                        data-testid="contas-a-pagar-tab"
                                    />
                                    <Tab
                                        value={"contas_receber"}
                                        label="Contas a receber"
                                        sx={{
                                            borderRadius: "20px",
                                            color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                            backgroundColor: selectedTab === "contas_receber" ? YELLOW_BG_UX : "transparent",
                                            "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                color: BLACK_LABEL_UX,
                                            },
                                        }}
                                        data-testid="contas-a-receber-tab"
                                    />
                                    <Tab
                                        value={"visao_competencia"}
                                        label="Visão de competência"
                                        sx={{
                                            borderRadius: "20px",
                                            color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                            backgroundColor: selectedTab === "visao_competencia" ? YELLOW_BG_UX : "transparent",
                                            "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                color: BLACK_LABEL_UX,
                                            },
                                        }}
                                        data-testid="visao-de-competencia-tab"
                                    />
                                </Tabs>
                            </Grid>
                            <Grid item xs={12} sx={{
                                border: 0,
                                borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`,
                                mb: 2.5,
                                ml: 2
                            }}/>
                        </Grid>
                        {loading ? (
                            [...Array(10).keys()].map((i, d) => {
                                return (
                                    <Grid item xs={12} key={d}>
                                        <Skeleton key={d} height={50}/>
                                    </Grid>
                                );
                            })
                        ) : (
                            <>
                                <Grid item xs={12} sx={{marginTop: 3}}>
                                    <Grid container spacing={2} rowSpacing={4}>
                                        {Object.keys(kpis).map((key, index) => {
                                            const gridSize = 12 / Object.keys(kpis).length;
                                            return (
                                                <Grid item xs={gridSize} key={index}>
                                                    <EXMKPI
                                                        className={`card_${index}`}
                                                        title={formatTitle(key)}
                                                        testId={key}
                                                        data={kpis[key]}
                                                        handleClick={() => setSelectedKPI(key)}
                                                        selectedKPI={selectedKPI === key}
                                                        KPI={key}
                                                        tab={selectedTab}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sx={{marginTop: 2, height: "750px"}}>
                                    <DataGridWithServerPagination
                                        pagination
                                        pageSizeOptions={[10, 50, 100]}
                                        data={pageState.data}
                                        rowCount={pageState.total}
                                        page={pageState.page - 1}
                                        pageSize={pageState.pageSize}
                                        onPageChange={(newPage) => {
                                            setPageState(old => ({...old, page: newPage + 1}));
                                        }}
                                        onPageSizeChange={(newPageSize) => setPageState(old => ({
                                            ...old,
                                            pageSize: newPageSize
                                        }))}
                                        checked={isAdmin || isOperator || isAprover}
                                        //rows={(row) => (`${row.id}-${row.data_pagamento}-${row.categoria}-${row.descricao}-${row.situacao}-${row.valor}`)}
                                        columns={columns[selectedTab]}
                                        customRowSelectionModelChange={(item) => {
                                            if (isAdmin || isOperator || isAprover) {
                                                setSelectedItem(item);
                                            }
                                        }
                                        }
                                        exmItems={selectedItem}
                                        getExtratoMovimentacoes={getExtratoMovimentacoes}
                                        setRowsSelected={setRowsSelected}
                                        isEXM
                                        deleteMovimentacoesLote={deleteMovimentacoesLote}
                                        onRowClick={(params) => {
                                            if (params.field !== "acoes" && selectedTab === "movimentacoes") {
                                                handleEditMovimentacao(params.row);
                                            }
                                        }}
                                        initialState={{
                                            columns: {
                                                columnVisibilityModel: {
                                                    fornecedor__nome: false,
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{marginTop: 2}}>
                                    <EXMFooter
                                        rowsSelected={rowsSelected}
                                        valorTotal={totalPeriodoKPI}
                                        periodo={footerPeriodo}
                                        testId="footer-kpi"
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                )}
            </Paper>
        </div>
    );
};

export default EXM;