import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import EditLancamentoRecorrenteModal from "../../components/Modal/EditLancamentoRecorrenteModal";

const SelectActions = ({ params, selectedTab, deleteAction }) => {
    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const isOperator = useSelector(state => state.AuthReducer.isOperator);
    const isAprover = useSelector(state => state.AuthReducer.isApprover);

    const [openEditLancamentoRecorrenteModal, setOpenEditLancamentoRecorrenteModal] = useState(false);

    const [action, setAction] = useState(null);

    const handleChange = (event) => {
        setAction(event.target.value);
    };

    const handleOpenModalEditLancamentoRecorrente = () => {
        if (params.recorrencia !== null) {
            setOpenEditLancamentoRecorrenteModal(true);
        }
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={1}
            sx={{height: "100%"}}
        >
            {openEditLancamentoRecorrenteModal && <EditLancamentoRecorrenteModal
                openModal={openEditLancamentoRecorrenteModal}
                setOpenModal={setOpenEditLancamentoRecorrenteModal}
                setAction={setAction}
                lancamento={params.id}
                tab={selectedTab}
            />}
            <FormControl sx={{width: "100%"}}>
                <InputLabel>Ações</InputLabel>
                <Select
                    disabled={!(isAdmin || isOperator || isAprover)}
                    value={action}
                    label="Ações"
                    onChange={handleChange}
                    sx={{width: "100%"}}
                >
                    {
                        params.recorrencia_total > 0 && (
                            <MenuItem value={"edit_lancamento"} onClick={() => {
                                if ((isAdmin || isOperator || isAprover) && params.recorrencia_total > 0) {
                                    handleOpenModalEditLancamentoRecorrente(params);
                                }
                            }}
                            disabled={!(isAdmin || isOperator || isAprover) && params.recorrencia_total == 0}
                            >
                                Editar lançamento
                            </MenuItem>
                        )
                    }
                    <MenuItem
                        value={"delete"}
                        onClick={() => {
                            if (isAdmin || isOperator || isAprover) {
                                deleteAction(params);
                            }
                        }}
                    >
                        Excluir
                    </MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

export default SelectActions;