import {useState} from "react";
import React from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "./GenericModal.css";
import {
    BLUE_THEME,
    smallPaperStyle,
    GREEN_SUCCESS_UX,
    RED_ERROR_UX,
    BORDER_BUTTON,
    PAPER_PADDING_THEME,
    YELLOW_BG_UX,
    WHITE_ESCRITA_THEME,
    formatterCurrency,
} from "../../shared/utils";
import ModalTitle from "../Titles/ModalTitle";
import TextField from "@mui/material/TextField";
import DefaultButton from "../Buttons/DefaultButton";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import api from "../../axios";
import {onMessageListener} from "../../firebase";

const ReprocessarNotaModal = ({ openReprocessarNota, setOpenReprocessarNota, getNotasRecebidos }) => {
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const [chaveAcesso, setChaveAcesso] = useState("");
    const [openDadosNFE, setOpenDadosNFE] = useState(false);
    const [numNota, setNumNota] = useState("");
    const [serie, setSerie] = useState("");
    const [modelo, setModelo] = useState("");
    const [valorTotal, setValorTotal] = useState(0);
    const [cnpj, setCnpj] = useState("");
    const [nomeRazaoSocial, setNomeRazaoSocial] = useState("");
    const [dataEmissao, setDataEmissao] = useState("");
    const [eventProgression, setEventProgression] = useState("idle");
    const [searching, setSearching] = useState(true);

    // foreground notifications
    onMessageListener()
    .then((payload) => {
        if (payload?.notification?.title === "Sucesso na importação da NF-e") {
            setEventProgression("success");

            setTimeout(() => {
                setOpenReprocessarNota(false);
                getNotasRecebidos();
                clearFields();
            }, 800);
        }
    })
    .catch(() => {
        setEventProgression("error");
    });

    const buscarNota = () => {
        setEventProgression("loading");

        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            nfe_key: chaveAcesso
        };

        api.GetNotaByKey(dataRequest).then((response) => {
            setNumNota(response.data.numero_nota);
            setSerie(response.data.serie);
            setModelo(response.data.modelo);
            setValorTotal(response.data.valor_total);
            setCnpj(response.data.cnpj);
            setNomeRazaoSocial(response.data.nome_razao_social);
            setDataEmissao(response.data.data_emissao);
            setEventProgression("success");
            setTimeout(() => {
                setEventProgression("idle");
                setOpenDadosNFE(true);
            }, 800);
        }).catch(() => {
            setEventProgression("error");
        });
    };
    
    const importarNota = () => {
        setSearching(false);
        setEventProgression("loading");
        
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            nfe_key: chaveAcesso
        };

        api.ImportNotaByKey(dataRequest).then(() => {
        }).catch(() => {
            setEventProgression("error");
        });
    };

    const closeModal = () => {
        if (eventProgression !== "loading") {
            setOpenReprocessarNota(false);
            clearFields();
        }
    };

    const clearFields = () => {
        setEventProgression("idle");
        setSearching(true);
        setChaveAcesso("");
        setNumNota("");
        setSerie("");
        setModelo("");
        setValorTotal(0);
        setCnpj("");
        setNomeRazaoSocial("");
        setDataEmissao("");
        setOpenDadosNFE(false);
    };

    const checkTryAgain = () => {
        if (searching) {
            buscarNota();
        }
        else {
            importarNota();
        }
    };

    return (
        openReprocessarNota && (
            <Modal
                open={openReprocessarNota}
                onClose={() => closeModal()}
            >
                <Paper data-testid="reprocessar-nota-modal" sx={{...smallPaperStyle, backgroundColor: temaEscuro && PAPER_PADDING_THEME, backgroundImage: temaEscuro && "none"}}>
                {eventProgression == "loading" ? (
                    <Grid container sx={{...styles.stateModal}}>
                        <CircularProgress
                            sx={{fontSize: "48px", color: YELLOW_BG_UX}}
                        />
                        <p>{searching ? "Buscando NF-e" : "Importando NF-e"}</p>
                    </Grid>
                ) : eventProgression == "success" ? (
                    <Grid container sx={{...styles.stateModal}}>
                        <CheckCircleIcon
                            sx={{color: GREEN_SUCCESS_UX, fontSize: "48px"}}
                        />
                        <p>{searching ? "NF-e encontrada com sucesso!" : "NF-e importada com sucesso!"}</p>
                    </Grid>
                    ) : eventProgression == "error" ? (
                        <Grid container sx={{...styles.stateModal}}>
                            <ErrorIcon
                                sx={{color: RED_ERROR_UX, fontSize: "48px"}}
                            />
                            <p>{searching ? "Erro na busca da NF-e!" : "Erro na importação da NF-e!"}</p>
                            <DefaultButton
                                title="Tentar novamente"
                                onClick={() => checkTryAgain()}
                            />
                        </Grid>
                    ) : (
                    <React.Fragment>
                        <ModalTitle setOpenModal={closeModal} title="Bsucar e importar NF-e"/>
                        <Grid container sx={{display: "flex", flexDirection: "column"}}>
                            <p style={{color: temaEscuro && WHITE_ESCRITA_THEME}}>Insira a chave de acesso da NF-e e clique em buscar</p>
                            <Paper sx={{display: "flex", backgroundColor: temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                                <InputBase
                                    autoFocus
                                    onChange={(e) => setChaveAcesso(e.target.value)}
                                    sx={{ ml: 1, flex: 1, width: "fit-content", color: temaEscuro && WHITE_ESCRITA_THEME }}
                                    placeholder="Digite aqui"
                                    value={chaveAcesso}
                                />
                                <IconButton
                                    type="button"
                                    sx={{ p: "10px", color: temaEscuro && BORDER_BUTTON }}
                                    aria-label="search"
                                    onClick={() => buscarNota()}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </Paper>
                        </Grid>
                        {openDadosNFE && (
                            <React.Fragment>
                                <Grid container sx={{mt: 4}}>
                                    <Grid item xs={12} sx={{mb: 3}}>
                                        <ModalTitle title="Dados da NF-e encontrada:"/>
                                    </Grid>
                                    <Grid item xs={12} sx={{display: "flex", gap: 2.5}}>
                                        <TextField
                                            label="Número da nota"
                                            value={numNota}
                                            inputProps={{
                                                ...styles.disabledStyle
                                            }}
                                            sx={{width: "280px"}}
                                        />
                                        <TextField
                                            label="Série"
                                            value={serie}
                                            sx={{width: "15%"}}
                                            inputProps={{
                                                ...styles.disabledStyle
                                            }}
                                        />
                                        <TextField
                                            label="Modelo"
                                            value={modelo}
                                            sx={{width: "15%"}}
                                            inputProps={{
                                                ...styles.disabledStyle
                                            }}
                                        />
                                        <TextField
                                            label="Valor total"
                                            value={formatterCurrency(valorTotal, 2)}
                                            sx={{width: "25%"}}
                                            inputProps={{
                                                ...styles.disabledStyle
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container sx={{mt: 4}}>
                                    <Grid item xs={12} sx={{display: "flex", gap: 2.5}}>
                                        <TextField
                                            label="CNPJ"
                                            value={cnpj}
                                            sx={{width: "280px"}}
                                            inputProps={{
                                                ...styles.disabledStyle
                                            }}
                                        />
                                        <TextField
                                            label="Nome / Razão Social"
                                            value={nomeRazaoSocial}
                                            sx={{width: "60%"}}
                                            inputProps={{
                                                ...styles.disabledStyle
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container sx={{mt: 4, mb: 5}}>
                                    <Grid item xs={12} sx={{display: "flex"}}>
                                        <TextField
                                            label="Data de emissão"
                                            value={dataEmissao}
                                            sx={{width: "280px"}}
                                            inputProps={{
                                                ...styles.disabledStyle
                                            }}
                                        />
                                    </Grid>
                                    <p style={{color: temaEscuro && WHITE_ESCRITA_THEME}}>Clique em importar para concluir a importação.</p>
                                </Grid>
                            </React.Fragment>

                        )}
                        <Grid container sx={{display: "flex", gap: 2, alignItems: "center", justifyContent: "end", mt: 8}}>
                            <DefaultButton
                                title="Cancelar"
                                onClick={() => closeModal()}
                            />
                            <DefaultButton
                                title="Importar NF-e"
                                disabled={openDadosNFE ? false : true}
                                onClick={() => importarNota()}
                                variant="yellow"
                            />
                        </Grid>
                    </React.Fragment>
                )}
                </Paper>
            </Modal>
        )
    );
};

const styles = {
    disabledStyle: {
        disabled: true,
    },
    stateModal: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        mt: 3,
        height: "188px"
    }
};

export default ReprocessarNotaModal;