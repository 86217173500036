import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {
    BLUE_THEME,
    BORDER_TABLE,
    smallPaperStyle,
    WHITE_ESCRITA_THEME,
    formatterThousand,
    formatterPercentage,
} from "../../shared/utils";
import FilterSingleOption from "../Filter/FilterSingleOption";
import InputNumber from "../Input/InputNumber";
import DefaultButton from "../Buttons/DefaultButton";
import InputCurrency from "../Input/InputCurrency";


const RateioCentroCustoModal = (props) => {
    const {openModal, setOpenModal, categoria, valorCategoria, indexRateioCentroCusto, rateios, setRateios, centroCustoOptions} = props;
    const [centroCustos, setCentroCustos] = useState([]);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const handleRemoveCentroCusto = (idx) => {
        centroCustos.splice(idx, 1);
        setCentroCustos([...centroCustos]);
    };

    const handleSaveRateioCentroCusto = () => {
        setRateios(rateios.map((rateio, idx) => {
            if (idx === indexRateioCentroCusto) {
                rateio.centro_custo = centroCustos;
                rateio.label_centro_custo = `${centroCustos.length} selecionados`;
            }
            return rateio;
        }));
        setOpenModal(false);
    };

    return (
        <React.Fragment>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="info-modal"
            >
                <Paper sx={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME, pt: 3, width: "50%", maxWidth: null}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <a>Informações da categoria</a>
                        </Grid>
                        <Grid item xs={12} sx={{border: 0, borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, mb: 1, ml: 2}}/>
                        <Grid item xs={12} sx={{mb: 10}}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={12}>
                                            <a style={{fontWeight: "bold"}}>Categoria</a>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <a>{categoria}</a>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={12} sx={{display: "flex", justifyContent: "end"}}>
                                            <a>Valor da categoria</a>
                                        </Grid>
                                        <Grid item xs={12} sx={{display: "flex", justifyContent: "end"}}>
                                            <a style={{fontSize: "18px", fontWeight: "bold"}}>R${formatterThousand(valorCategoria)}</a>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <a>Listagem de centro de custos</a>
                        </Grid>
                        <Grid item xs={12} sx={{border: 0, borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, mb: 1, ml: 2}}/>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {
                                    centroCustos.map((centro, idx) => {
                                        return(
                                            <React.Fragment key={idx}>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={3}>
                                                        <Grid item>
                                                            <FilterSingleOption
                                                                filterOptions={centroCustoOptions}
                                                                selectedOptions={centro.nome}
                                                                setSelectedOption={(value) => (setCentroCustos(centroCustos.map((centro_aux, idx_aux) => {
                                                                    if (idx_aux === idx) {
                                                                        centro_aux.nome = value.value;
                                                                    }
                                                                    return centro_aux;
                                                                })))}
                                                                placeholder={"Centro de Custo"}
                                                                width={centroCustos.length > 1 ? "375px" : "412px"}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <InputCurrency
                                                                valor={centro.valor}
                                                                setValor={(value) => (setCentroCustos(centroCustos.map((centro_aux, idx_aux) => {
                                                                    centro_aux.error = value >= valorCategoria;
                                                                    if (idx_aux === idx) {
                                                                        centro_aux.valor = value;
                                                                        centro_aux.percentual = formatterPercentage((value / valorCategoria) * 100, 2);
                                                                    }
                                                                    return centro_aux;
                                                                })))}
                                                                title={"Valor (R$)*"}
                                                                width={"250px"}
                                                                error={centro.error}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <InputNumber
                                                                label={"Percentual (%)*"}
                                                                value={centro.percentual}
                                                                setValue={(value) => (setCentroCustos(centroCustos.map((centro_aux, idx_aux) => {
                                                                    centro_aux.error = value >= 100;
                                                                    if (idx_aux === idx) {
                                                                        centro_aux.percentual = value;
                                                                        centro_aux.valor = formatterThousand((value / 100) * valorCategoria);
                                                                    }
                                                                    return centro_aux;
                                                                })))}
                                                                error={centro.error}
                                                            />
                                                        </Grid>
                                                        {
                                                            centroCustos.length > 1 && (
                                                                <Grid
                                                                    item
                                                                    sx={{padding: 0, cursor: "pointer", alignContent: "center"}}
                                                                    onClick={() => handleRemoveCentroCusto(idx)}
                                                                >
                                                                    <a>X</a>
                                                                </Grid>
                                                            )
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <DefaultButton
                                        title={"+ Adicionar linha"}
                                        size={"small"}
                                        onClick={() => {
                                            setCentroCustos([...centroCustos, {
                                                nome: null,
                                                valor: 0,
                                                percentual: 0,
                                            }]);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sx={{display: "flex", justifyContent: "end"}}>
                                    <DefaultButton
                                        title={"Salvar"}
                                        size={"small"}
                                        onClick={() => handleSaveRateioCentroCusto()}
                                        variant="yellow"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </React.Fragment>
    );
};

export default RateioCentroCustoModal;