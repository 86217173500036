import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
    BLUE_THEME,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    LINE_TABLE,
    PAPER_PADDING_THEME,
    paperStyle,
    YELLOW_BG_UX,
    BLUE_INFO_UX,
    YELLOW_INFO_UX,
    GRAY_LABEL_UX,
    manualCurrencyFormatter,
    GRAY_BG_BODY,
    BLACK_LABEL_UX,
    GREEN_SUCCESS_UX,
    RED_ERROR_UX,
    BLACK_TABLE_THEME,
    PERFIL_THEME,
    WHITE_TABLE,
    INFO_THEME,
    WHITE_ESCRITA_THEME,
    WHITE_THEME_BLACK,
    GRAY_STRONG_UX,
} from "../../../shared/utils";
import Bradesco from "../../Contas/LogoBancos/Bradesco.png";
import Santander from "../../Contas/LogoBancos/Santander.png";
import Sicoob from "../../Contas/LogoBancos/Sicoob.png";
import Itau from "../../Contas/LogoBancos/Itau.png";
import BancoBrasil from "../../Contas/LogoBancos/BB.png";
import Inter from "../../Contas/LogoBancos/Inter.png";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import ErrorIcon from "@mui/icons-material/Error";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ConfirmDialog from "../../../components/Modal/ConfirmDialog.js";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import DefaultButton from "../../../components/Buttons/DefaultButton.js";
import { showSnackMessage } from "../../../actions/SnackActions";
import api from "../../../axios";
import OptionsNovaEXM from "../../../components/Buttons/OptionsNovaEXM.js";
import { changeIdLancamentoCreated } from "../../../actions/StoreActions.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const RevisarValores = ({
    lancamento,
    openModalRevisarVal,
    setOpenModalRevisarVal,
    rowsSelected,
    setRowsSelected,
    handleConciliar,
}) => {
    const isLargeScreen = useMediaQuery("(min-width:1640px)");
    const dispatch = useDispatch();

    const temaEscuro = useSelector((state) => state.AuthReducer.temaEscuro);
    const idLancamentoCreated = useSelector(
        (state) => state.StoreReducer.idLancamentoCreated
    );

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [revisedData, setRevisedData] = useState([]);
    const [valorTotalMov, setValorTotalMov] = useState(0.0);
    const [valorFinalMov, setValorFinalMov] = useState(0.0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showWarning, setShowWarning] = useState(true);
    const [openModalUseLancamento, setOpenModalUseLancamento] = useState(false);

    const openMenu = Boolean(anchorEl);
    const tipoMovimentacao =
        lancamento.valor_lancamento > 0 ? "Receita" : "Despesa";

    const dataGridApiRef = useGridApiRef();

    useEffect(() => {
        getInfoMovimentacoes();
    }, []);

    useEffect(() => {
        if (idLancamentoCreated.length > 0) {
            getInfoMovimentacoes(idLancamentoCreated);
        }
    }, [idLancamentoCreated]);

    useEffect(() => {
        const valorTotal = revisedData.reduce(
            (acc, item) => acc + item.valor_total_bruto,
            0
        );
        const valorFinal = revisedData.reduce(
            (acc, item) => acc + item.valor_final,
            0
        );
        setValorTotalMov(parseInt(valorTotal * 100) / 100);
        setValorFinalMov(parseInt(valorFinal * 100) / 100);
    }, [revisedData]);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const reviseValues = (lancamentoId, movimentacoesIds) => {
        const fieldToUpdate = ["juros", "multa", "desconto", "tarifa_bancaria"];
        const updatedRows = revisedData.map((row) => {
            const movId = row.id;
            const originalMov = data.find((mov) => mov.id === movId);
            const hasChanged = fieldToUpdate.some(
                (field) => row[field] !== originalMov[field]
            );
            if (hasChanged) {
                return {
                    id: movId,
                    juros: row.juros,
                    multa: row.multa,
                    desconto: row.desconto,
                    tarifa_bancaria: row.tarifa_bancaria,
                    baixa_parcial: row.baixa_parcial,
                };
            }
        });
        if (
            (updatedRows.length === 0) |
            updatedRows.every((row) => row === undefined)
        ) {
            handleConciliar(lancamentoId, movimentacoesIds);
            return;
        }

        api.putRevisarValores({ movimentacoes: updatedRows })
            .then(() => {
                dispatch(
                    showSnackMessage({
                        message: "Valores revisados com sucesso",
                        severiy: "success",
                    })
                );
                handleConciliar(lancamentoId, movimentacoesIds);
            })
            .catch(() => {
                dispatch(
                    showSnackMessage({
                        message: "Erro ao revisar valores",
                        severiy: "error",
                    })
                );
            });
    };

    const handleEditCell = (params, event) => {
        const { id, field, row } = params;
        if (field === "baixa_parcial") {
            const value = parseFloat(event.target.value) || 0;
            const valorTotal = parseFloat(row.valor_total_bruto);
            const newData = revisedData.map((item) => {
                if (item.id === id) {
                    const newItem = {
                        ...item,
                        [field]: value,
                        ["valido"]: valorTotal > value,
                    };
                    return newItem;
                }
                return item;
            });
            setRevisedData(newData);
            return;
        }

        const value = parseFloat(event.target.value) || 0;
        const newData = revisedData.map((item) => {
            if (item.id === id) {
                const newItem = {
                    ...item,
                    [field]: value,
                };
                const valorTotal = parseInt(newItem.valor_total_bruto * 100);
                const juros = parseInt(newItem.juros * 100);
                const multa = parseInt(newItem.multa * 100);
                const desconto = parseInt(newItem.desconto * 100);
                const tarifa = parseInt(newItem.tarifa_bancaria * 100);

                const finalValue =
                    (valorTotal + juros + multa - desconto - tarifa) / 100;
                newItem.valor_final = finalValue;
                return newItem;
            }
            return item;
        });

        setRevisedData(newData);
    };

    const useLancamentoValue = () => {
        api.postRevisarValores({
            id: revisedData[0].id,
            valor_total: lancamento.valor_lancamento,
        })
            .then(() => {
                showSnackMessage("Valor editado com sucesso", "success");
                const newData = revisedData.map((item) => {
                    return {
                        ...item,
                        valor_total: lancamento.valor_lancamento,
                        valor_final: lancamento.valor_lancamento,
                    };
                });
                setRevisedData(newData);
                dataGridApiRef.current.setRows(newData);
                setOpenModalUseLancamento(false);
            })
            .catch(() => {
                showSnackMessage("Erro ao editar movimentação", "error");
            });
    };

    const fillWithJuros = () => {
        const difference = Math.abs(
            (parseInt(lancamento.valor_lancamento * 100) -
                parseInt(valorFinalMov * 100)) /
                100
        );
        if (difference === 0.0) {
            return;
        }
        const updatedRows = revisedData.map((row) => {
            return {
                id: row.id,
                descricao: row.descricao,
                juros: difference,
                multa: row.multa,
                desconto: row.desconto,
                tarifa_bancaria: row.tarifa_bancaria,
                baixa_parcial: row.baixa_parcial,
                valor_total: row.valor_total_bruto,
                valor_total_bruto: row.valor_total_bruto,
                valor_final:
                    row.valor_final > 0
                        ? row.valor_total_bruto + difference + row.multa - row.desconto - row.tarifa_bancaria
                        : -Math.abs(row.valor_total_bruto + difference + row.multa - row.desconto - row.tarifa_bancaria),
                valor_final_bruto: row.valor_total_bruto + difference + row.multa - row.desconto - row.tarifa_bancaria,
                valido: row.valido,
            };
        });
        setAnchorEl(null);
        setRevisedData(updatedRows);
        dataGridApiRef.current.setRows(updatedRows);
    };

    const fillWithMulta = () => {
        const difference = Math.abs(
            (parseInt(lancamento.valor_lancamento * 100) -
                parseInt(valorFinalMov * 100)) /
                100
        );
        const updatedRows = revisedData.map((row) => {
            return {
                id: row.id,
                descricao: row.descricao,
                juros: row.juros,
                multa: difference,
                desconto: row.desconto,
                tarifa_bancaria: row.tarifa_bancaria,
                baixa_parcial: row.baixa_parcial,
                valor_total: row.valor_total_bruto,
                valor_total_bruto: row.valor_total_bruto,
                valor_final:
                    row.valor_final > 0
                        ? row.valor_total_bruto + difference + row.juros - row.desconto - row.tarifa_bancaria
                        : -Math.abs(row.valor_total_bruto + difference + row.juros - row.desconto - row.tarifa_bancaria),
                valor_final_bruto: row.valor_total_bruto + difference + row.juros - row.desconto - row.tarifa_bancaria,
                valido: row.valido,
            };
        });
        setAnchorEl(null);
        setRevisedData(updatedRows);
        dataGridApiRef.current.setRows(updatedRows);
    };

    const fillWithDesconto = () => {
        const difference = Math.abs(
            (parseInt(lancamento.valor_lancamento * 100) -
                parseInt(valorFinalMov * 100)) /
                100
        );
        const updatedRows = revisedData.map((row) => {
            return {
                id: row.id,
                descricao: row.descricao,
                juros: row.juros,
                multa: row.multa,
                desconto: difference,
                tarifa_bancaria: row.tarifa_bancaria,
                baixa_parcial: row.baixa_parcial,
                valor_total: row.valor_total,
                valor_total_bruto: row.valor_total_bruto,
                valor_final:
                    row.valor_final > 0
                        ? row.valor_total_bruto + row.juros + row.multa - difference - row.tarifa_bancaria
                        : -Math.abs(row.valor_total_bruto + row.juros + row.multa - difference - row.tarifa_bancaria),
                valor_final_bruto: row.valor_total_bruto + row.juros + row.multa - difference - row.tarifa_bancaria,
                valido: row.valido,
            };
        });
        setAnchorEl(null);
        setRevisedData(updatedRows);
        dataGridApiRef.current.setRows(updatedRows);
    };

    const fillWithTarifa = () => {
        const difference = Math.abs(
            (parseInt(lancamento.valor_lancamento * 100) -
                parseInt(valorFinalMov * 100)) /
                100
        );
        const updatedRows = revisedData.map((row) => {
            return {
                id: row.id,
                descricao: row.descricao,
                juros: row.juros,
                multa: row.multa,
                desconto: row.desconto,
                tarifa_bancaria: difference,
                baixa_parcial: row.baixa_parcial,
                valor_total_bruto: row.valor_total_bruto,
                valor_final:
                    row.valor_final > 0
                        ? row.valor_total_bruto + row.juros + row.multa - row.desconto - difference
                        : -Math.abs(row.valor_total_bruto + row.juros + row.multa - row.desconto - difference),
                valor_final_bruto: row.valor_total_bruto + row.juros + row.multa - row.desconto - difference,
                valido: row.valido,
            };
        });
        setAnchorEl(null);
        setRevisedData(updatedRows);
        dataGridApiRef.current.setRows(updatedRows);
    };

    const getInfoMovimentacoes = (ids = []) => {
        setLoading(true);
        const existingMovIds = rowsSelected.map(
            (movimentacao) => movimentacao.id
        );
        const updatedIds = [...new Set([...existingMovIds, ...ids])];

        const updatedRowObjects = updatedIds.map(
            (id) => rowsSelected.find((mov) => mov.id === id) || { id }
        );

        setRowsSelected(updatedRowObjects);

        api.getRevisarValores({ ids: updatedIds })
            .then((response) => {
                setData(response.data);
                setRevisedData(response.data);
                setLoading(false);
                dispatch(changeIdLancamentoCreated([]));
            })
            .catch(() => {
                showSnackMessage("Erro ao buscar movimentações", "error");
                setLoading(false);
                dispatch(changeIdLancamentoCreated([]));
            });
    };

    const renderBanco = (bancoNome) => {
        const bancos = {
            "Banco do Brasil": BancoBrasil,
            Inter: Inter,
            Bradesco: Bradesco,
            Santander: Santander,
            Sicoob: Sicoob,
            Itaú: Itau,
        };

        if (bancos[bancoNome]) {
            return (
                <img
                    src={bancos[bancoNome]}
                    alt={bancoNome}
                    style={{ height: 70, width: 71, borderRadius: "5px" }}
                />
            );
        }

        return (
            <div
                style={{
                    height: 70,
                    width: 71,
                    backgroundColor: YELLOW_BG_UX,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "black",
                    borderRadius: "5px",
                }}
            >
                {bancoNome.toUpperCase()}
            </div>
        );
    };

    const columns = [
        {
            field: "descricao",
            headerName: "Data e descrição",
            flex: 2,
            align: "left",
            headerAlign: "left",
            renderCell: (params) => {
                const data = params.row.formatted_vencimento;
                const categorias = params.row.categoria_nome;
                const descricao = params.row.descricao;
                let title = `${data}\n${descricao}`;
                title += categorias ? `\nCategoria: ${categorias}` : "";

                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            justifyContent: "center",
                            height: "100%",
                        }}
                        title={title}
                    >
                        <Typography fontWeight="normal" fontSize="14px">
                            {data}
                        </Typography>
                        <Typography fontSize="14px">{descricao}</Typography>
                    </div>
                );
            },
        },
        {
            field: "valor_total",
            headerName: "Valor lançado (R$)",
            flex: 1,
            type: "number",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            color:
                                tipoMovimentacao === "Receita"
                                    ? GREEN_SUCCESS_UX
                                    : RED_ERROR_UX,
                        }}
                    >
                        {params.row.valor_total_bruto}
                    </div>
                );
            },
        },
        {
            field: "juros",
            editable: true,
            headerName: "Juros (R$)",
            flex: 1,
            type: "number",
            align: "center",
            headerAlign: "center",
        },
        {
            field: "multa",
            editable: true,
            headerName: "Multa (R$)",
            flex: 1,
            type: "number",
            align: "center",
            headerAlign: "center",
        },
        {
            field: "desconto",
            editable: true,
            headerName: "Desconto (R$)",
            flex: 1,
            type: "number",
            align: "center",
            headerAlign: "center",
        },
        {
            field: "tarifa_bancaria",
            editable: true,
            headerName: "Tarifa (R$)",
            flex: 1,
            type: "number",
            align: "center",
            headerAlign: "center",
        },
        {
            field: "baixa_parcial",
            editable: true,
            headerName: "Baixa parcial (R$)",
            flex: 1,
            type: "number",
            align: "center",
            headerAlign: "center",
        },
        {
            field: "valor_final",
            headerName: "Valor final (R$)",
            flex: 1,
            type: "number",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                const valorTotal = params.row.valor_total_bruto;
                const juros = params.row.juros;
                const multa = params.row.multa;
                const desconto = params.row.desconto;
                const tarifa = params.row.tarifa_bancaria;
                const baixaParcial = params.row.baixa_parcial;

                const finalValue =
                    parseInt(
                        (valorTotal + juros + multa - desconto - tarifa) * 100
                    ) / 100;

                if (baixaParcial) {
                    const emAberto =
                        parseInt((valorTotal - baixaParcial) * 100) / 100;

                    return (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                                justifyContent: "center",
                                height: "100%",
                            }}
                        >
                            <Typography fontWeight="normal" fontSize="15px">
                                Em aberto: {emAberto}
                            </Typography>
                            <Typography
                                fontSize="15px"
                                sx={{
                                    color:
                                        tipoMovimentacao === "Receita"
                                            ? GREEN_SUCCESS_UX
                                            : RED_ERROR_UX,
                                }}
                            >
                                {finalValue}
                            </Typography>
                        </div>
                    );
                }

                if (finalValue === valorTotal) {
                    return (
                        <div
                            style={{
                                color:
                                    tipoMovimentacao === "Despesa"
                                        ? RED_ERROR_UX
                                        : GREEN_SUCCESS_UX,
                            }}
                        >
                            {finalValue}
                        </div>
                    );
                }

                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            justifyContent: "center",
                            height: "100%",
                        }}
                    >
                        <Typography fontWeight="normal" fontSize="15px">
                            <span style={{ textDecoration: "line-through" }}>
                                {params.row.valor_total_bruto}
                            </span>
                        </Typography>
                        <Typography
                            fontSize="15px"
                            sx={{
                                color:
                                    tipoMovimentacao === "Receita"
                                        ? GREEN_SUCCESS_UX
                                        : RED_ERROR_UX,
                            }}
                        >
                            {finalValue}
                        </Typography>
                    </div>
                );
            },
        },
    ];

    return (
        <Dialog
            fullScreen
            open={openModalRevisarVal}
            onClose={() => setOpenModalRevisarVal(false)}
            TransitionComponent={Transition}
            PaperProps={{
                style: {
                    backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_BODY,
                },
            }}
            data-testid="revisar-valores-dialog"
        >
            <ConfirmDialog
                title="Utilizar o valor do extrato para lançamento com baixa"
                description={`Identificamos que o item selecionado corresponde a uma baixa já realizada, e o valor baixado é ${
                    valorTotalMov < lancamento
                        ? manualCurrencyFormatter(
                              lancamento.valor_lancamento - valorTotalMov,
                              2
                          ) + " inferior"
                        : manualCurrencyFormatter(
                              valorTotalMov - lancamento.valor_lancamento,
                              2
                          ) + " superior"
                } ao valor contido no extrato bancário.\n\nO valor do seu lançamento e sua baixa serão atualizados para ${manualCurrencyFormatter(
                    lancamento.valor_lancamento,
                    2
                )} , e o lançamento será quitado.\n\nDeseja confirmar essa ação?`}
                buttonTitle="Confirmar"
                callback={useLancamentoValue}
                open={openModalUseLancamento}
                setOpen={setOpenModalUseLancamento}
            />
            <AppBar
                sx={{
                    backgroundColor: temaEscuro
                        ? PAPER_PADDING_THEME
                        : LINE_TABLE,
                    backgroundImage: temaEscuro && "none",
                    borderBottom: temaEscuro
                        ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                        : `1px solid ${BORDER_PAPER}`,
                    padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                    position: "relative",
                }}
            >
                <Toolbar>
                    <Typography sx={{ flex: 1 }} variant="h6" component="div">
                        Revisar valores do VXMF
                    </Typography>
                    <IconButton
                        autoFocus
                        color="inherit"
                        onClick={() => setOpenModalRevisarVal(false)}
                        aria-label="close"
                        sx={{
                            color: !temaEscuro && BLACK_LABEL_UX,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div style={{ overflow: "auto", maxHeight: "100vh" }}>
                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro
                            ? PAPER_PADDING_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        border: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        margin: `20px ${
                            isLargeScreen ? "120px" : "20px"
                        } 20px ${isLargeScreen ? "120px" : "20px"}`,
                    }}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid
                                container
                                sx={{
                                    display: "flex",
                                    gap: 2,
                                    flexWrap: "nowrap",
                                }}
                            >
                                <Grid item>
                                    {renderBanco(lancamento?.banco_nome)}
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        sx={{
                                            display: "flex",
                                            gap: 1,
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Grid
                                            item
                                            sx={{
                                                display: "flex",
                                                gap: 1,
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography
                                                fontWeight="bold"
                                                fontSize="17px"
                                            >
                                                {lancamento?.valor_lancamento >
                                                0
                                                    ? "Recebimento"
                                                    : "Pagamento"}
                                                {` importado de ${manualCurrencyFormatter(
                                                    lancamento.valor_lancamento,
                                                    2
                                                )}`}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                display: "flex",
                                                gap: 0.1,
                                                flexDirection: "column",
                                            }}
                                        >
                                            <Typography
                                                fontWeight="normal"
                                                fontSize="14px"
                                            >
                                                Data do lançamento no extrato:{" "}
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {
                                                        lancamento?.data_lancamento
                                                    }
                                                </span>
                                            </Typography>
                                            <Typography
                                                fontWeight="normal"
                                                fontSize="14px"
                                            >
                                                Descrição:{" "}
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {lancamento?.descricao}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro
                            ? PAPER_PADDING_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        border: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        margin: `0 ${isLargeScreen ? "120px" : "20px"} 120px ${
                            isLargeScreen ? "120px" : "20px"
                        }`,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography fontWeight="bold" fontSize="17px">
                                Lançamentos do VXMF
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {lancamento.valor_lancamento - valorFinalMov !==
                                0 &&
                                showWarning && (
                                    <div
                                        style={{
                                            border: `solid 1px ${YELLOW_BG_UX}`,
                                            borderLeft: `7px solid ${YELLOW_BG_UX}`,
                                            borderRadius: "4px",
                                            padding: "10px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                            }}
                                        >
                                            <ErrorIcon
                                                sx={{
                                                    color: YELLOW_BG_UX,
                                                    marginRight: "5px",
                                                }}
                                            />
                                            <div>
                                                <Typography
                                                    fontWeight="bold"
                                                    fontSize="14px"
                                                >
                                                    Corrija a diferença de{" "}
                                                    {manualCurrencyFormatter(
                                                        lancamento.valor_lancamento -
                                                            valorFinalMov,
                                                        2
                                                    )}{" "}
                                                    do VXMF
                                                </Typography>
                                                <Typography fontSize="14px">
                                                    O valor total está diferente
                                                    do banco. Edite os
                                                    lançamentos abaixo ou crie
                                                    um novo.
                                                </Typography>
                                            </div>
                                        </div>
                                        <div>
                                            <CloseIcon
                                                onClick={() =>
                                                    setShowWarning(false)
                                                }
                                                fontSize="small"
                                            />
                                        </div>
                                    </div>
                                )}
                        </Grid>
                        <Grid item xs={12}>
                            {revisedData.some((item) => !item.valido) && (
                                <div
                                    style={{
                                        border: `solid 1px ${YELLOW_BG_UX}`,
                                        borderLeft: `7px solid ${YELLOW_BG_UX}`,
                                        borderRadius: "4px",
                                        padding: "10px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                        }}
                                    >
                                        <ErrorIcon
                                            sx={{
                                                color: YELLOW_BG_UX,
                                                marginRight: "5px",
                                            }}
                                        />
                                        <div>
                                            <Typography
                                                fontWeight="bold"
                                                fontSize="14px"
                                            >
                                                Valor da baixa parcial está
                                                maior do que o valor total da
                                                movimentação
                                            </Typography>
                                            <Typography fontSize="14px">
                                                Faremos a baixa do valor
                                                indicado no lançamento do VXMF.
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Grid>
                        <Grid item>
                            <DefaultButton
                                disabled={
                                    data.length !== 1 ||
                                    lancamento.valor_lancamento ===
                                        valorFinalMov
                                }
                                title="Ajustar valores"
                                endIcon={<ExpandMoreIcon />}
                                onClick={handleOpenMenu}
                                testId="ajustar-btn"
                            />
                            <Menu
                                sx={{
                                    "& .css-kc02vp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper":
                                        {
                                            background:
                                                temaEscuro && BLACK_TABLE_THEME,
                                        },
                                }}
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleCloseMenu}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <MenuItem
                                    onClick={() =>
                                        setOpenModalUseLancamento(true)
                                    }
                                >
                                    Usar valor do extrato
                                </MenuItem>
                                <MenuItem
                                    disabled={
                                        valorTotalMov >
                                        Math.abs(lancamento.valor_lancamento)
                                    }
                                    onClick={fillWithJuros}
                                >
                                    Informar diferença como juros
                                </MenuItem>

                                <MenuItem
                                    disabled={
                                        valorTotalMov >
                                        Math.abs(lancamento.valor_lancamento)
                                    }
                                    onClick={fillWithMulta}
                                >
                                    Informar diferença como multa
                                </MenuItem>
                                <MenuItem
                                    disabled={
                                        valorTotalMov <
                                        Math.abs(lancamento.valor_lancamento)
                                    }
                                    onClick={fillWithDesconto}
                                >
                                    Informar diferença como desconto
                                </MenuItem>
                                <MenuItem
                                    disabled={
                                        valorTotalMov <
                                        Math.abs(lancamento.valor_lancamento)
                                    }
                                    onClick={fillWithTarifa}
                                >
                                    Informar diferença como tarifa
                                </MenuItem>
                                <MenuItem disabled={true}>
                                    Informar{" "}
                                    {lancamento.valor_lancamento > 0
                                        ? "Recebimento"
                                        : "Pagamento"}{" "}
                                    parcial
                                </MenuItem>
                            </Menu>
                        </Grid>
                        <Grid item sx={{ marginLeft: "auto" }}>
                            <OptionsNovaEXM />
                        </Grid>
                        <Grid item xs={12}>
                            <DataGridPremium
                                data-testid="datagrid-component"
                                sx={{
                                    "& .MuiDataGrid-cell.MuiDataGrid-cell--editing":
                                        {
                                            backgroundColor: "transparent",
                                        },
                                    "& .MuiDataGrid-columnHeaderTitle": {
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        color: temaEscuro
                                            ? LINE_TABLE
                                            : GRAY_STRONG_UX,
                                    },
                                    "& ::-webkit-scrollbar-thumb": {
                                        backgroundColor: temaEscuro
                                            ? PERFIL_THEME
                                            : WHITE_ESCRITA_THEME,
                                    },
                                    "& .MuiDataGrid-row--editing .MuiDataGrid-cell":
                                        {
                                            backgroundColor: "transparent",
                                        },
                                    "& .super-app-theme--header": {
                                        backgroundColor: GRAY_LABEL_UX,
                                        color: WHITE_TABLE,
                                    },
                                    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus":
                                        {
                                            outline: "none",
                                        },
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    borderRadius: 2,
                                    borderColor: temaEscuro && INFO_THEME,
                                }}
                                apiRef={dataGridApiRef}
                                style={{
                                    "--DataGrid-containerBackground":
                                        temaEscuro && BLUE_THEME,
                                    "--DataGrid-pinnedBackground":
                                        temaEscuro && BLUE_THEME,
                                    backgroundColor: temaEscuro && BLUE_THEME,
                                    color: temaEscuro
                                        ? WHITE_THEME_BLACK
                                        : GRAY_STRONG_UX,
                                }}
                                loading={loading}
                                rows={data}
                                columns={columns}
                                onCellEditStop={handleEditCell}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </div>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: temaEscuro
                        ? PAPER_PADDING_THEME
                        : LINE_TABLE,
                    backgroundImage: temaEscuro && "none",
                    borderTop: temaEscuro
                        ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                        : `1px solid ${BORDER_PAPER}`,
                    top: "auto",
                    bottom: 0,
                    padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                }}
            >
                <Toolbar
                    sx={{ display: "flex", justifyContent: "space-between" }}
                >
                    <DefaultButton
                        title="cancelar"
                        onClick={() => setOpenModalRevisarVal(false)}
                        testId="cancelar-button"
                    />
                    <div style={{ display: "flex", gap: "1rem" }}>
                        <div>
                            <p
                                style={{
                                    fontSize: "14px",
                                    color: !temaEscuro && GRAY_LABEL_UX,
                                }}
                            >
                                Valor do lançamento bancário {"("}R${")"}
                            </p>
                            <p
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    direction: "rtl",
                                    textAlign: "justify",
                                    color: !temaEscuro && GRAY_LABEL_UX,
                                }}
                            >
                                {manualCurrencyFormatter(
                                    lancamento.valor_lancamento,
                                    2
                                )}
                            </p>
                        </div>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                        />
                        <div>
                            <p
                                style={{
                                    fontSize: "14px",
                                    color: !temaEscuro && GRAY_LABEL_UX,
                                }}
                            >
                                Total no VXMF {"("}R${")"}
                            </p>
                            <p
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    direction: "rtl",
                                    textAlign: "justify",
                                    color: BLUE_INFO_UX,
                                }}
                            >
                                {manualCurrencyFormatter(valorFinalMov, 2)}
                            </p>
                        </div>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                        />
                        <div>
                            <p
                                style={{
                                    fontSize: "14px",
                                    color: !temaEscuro && GRAY_LABEL_UX,
                                }}
                            >
                                Diferença {"("}R${")"}
                            </p>
                            <p
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    direction: "rtl",
                                    textAlign: "justify",
                                    color: YELLOW_INFO_UX,
                                }}
                            >
                                {manualCurrencyFormatter(
                                    (parseInt(
                                        lancamento.valor_lancamento * 100
                                    ) -
                                        parseInt(valorFinalMov * 100)) /
                                        100,
                                    2
                                )}
                            </p>
                        </div>
                    </div>
                    <div style={{ display: "flex", gap: "1rem" }}>
                        <DefaultButton
                            title={"Conciliar"}
                            disabled={
                                valorFinalMov !== lancamento.valor_lancamento
                            }
                            tooltip={
                                valorFinalMov !== lancamento.valor_lancamento
                                    ? "O valor selecionado deve ser igual ao valor do lançamento bancário"
                                    : ""
                            }
                            onClick={() =>
                                reviseValues(
                                    lancamento.id,
                                    data.map((movimentacao) => movimentacao.id)
                                )
                            }
                            variant="yellow"
                        />
                    </div>
                </Toolbar>
            </AppBar>
        </Dialog>
    );
};

export default RevisarValores;
