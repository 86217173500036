import React from "react";
import { Grid } from "@mui/material";
import DefaultButton from "../Buttons/DefaultButton";
import {customFooterStyle} from "../../shared/utils";


const NotasFooter = ({patchInformarReceita, notas, title}) => {
    return (
        notas?.length > 0 && (
            <Grid container>
                <Grid item sx={{...customFooterStyle}} xs={12} data-testid="datagrid-num-lines" >
                    <DefaultButton
                        title={title}
                        onClick={() => patchInformarReceita()}
                    />
                </Grid>
            </Grid>
        )
    );
};

export default React.memo(NotasFooter);