import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
	DASH_KPI_COLOR,
	DASH_KPI_DATA_COLOR,
	DASH_KPI_NEGATIVE_COLOR,
	DASH_KPI_POSITIVE_COLOR,
	dashLeftTitle,
	formatterCurrency,
	paperDash,
	BLUE_THEME,
	WHITE_ESCRITA_THEME,
    BLUE_INFO_UX,
	RED_INFO
} from "../../shared/utils";

const EXMKPI = (props) => {
	const {data, title, testId, handleClick, selectedKPI, KPI, tab} = props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const colorMapping = {
		"movimentacoes": {
			"receita_em_aberto": DASH_KPI_POSITIVE_COLOR,
			"receita_realizada": DASH_KPI_POSITIVE_COLOR,
			"despesa_em_aberto": DASH_KPI_NEGATIVE_COLOR,
			"despesa_realizada": DASH_KPI_NEGATIVE_COLOR,
			"total": BLUE_INFO_UX
		},
		"contas_pagar": {
			"vencidos": DASH_KPI_NEGATIVE_COLOR,
			"vencem_hoje": DASH_KPI_NEGATIVE_COLOR,
			"a_vencer": BLUE_INFO_UX,
			"pagos": DASH_KPI_POSITIVE_COLOR,
			"total": BLUE_INFO_UX
		},
		"contas_receber": {
			"vencidos": DASH_KPI_NEGATIVE_COLOR,
			"vencem_hoje": DASH_KPI_NEGATIVE_COLOR,
			"a_vencer": BLUE_INFO_UX,
			"recebidos": DASH_KPI_POSITIVE_COLOR,
			"total": BLUE_INFO_UX
		},
		"visao_competencia": {
			"receitas": DASH_KPI_POSITIVE_COLOR,
			"despesas": DASH_KPI_NEGATIVE_COLOR,
			"total": BLUE_INFO_UX
		}
	};

	return(
		<Paper
			className={props.className}
			onClick={handleClick}
			sx={{
				...paperDash,
				...dashLeftTitle,
				backgroundColor : temaEscuro && BLUE_THEME,
				backgroundImage: temaEscuro && "none",
				borderLeft: selectedKPI && `0.35rem solid ${colorMapping[tab][KPI] || DASH_KPI_DATA_COLOR}`,
				"&:hover": {cursor: "pointer", outline: "1px solid"}
		}}>
			<Grid container rowSpacing={2} data-testid={testId}>
				<Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
					<span style={{...styles.title, ...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>{title}</span>
				</Grid>
				<Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
					<span 
                        style={{
                            ...styles.subTitle,
                            color: colorMapping[tab][KPI] || DASH_KPI_DATA_COLOR
                        }}
                    >
                        {formatterCurrency(data, 2, false)}
					</span>
				</Grid>
			</Grid>
		</Paper>
	);
};

// Create styles
const styles = {
	title: {
		fontSize: "16px",
		fontWeight: 500,
		color: DASH_KPI_COLOR
	},
	subTitle: {
		fontSize: "20px",
		fontWeight: 600,
		color: DASH_KPI_DATA_COLOR
    },
	positive: {
		color: DASH_KPI_POSITIVE_COLOR
	},
	negative: {
		color: RED_INFO
	},
    total: {
        color: BLUE_INFO_UX
    },
};

export default React.memo(EXMKPI);