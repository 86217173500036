import React, { useState } from "react";
import Header from "../../components/Titles/Header";
import Paper from "@mui/material/Paper";
import { BLACK_PDF, GRAY_BORDER_TABLE, PAPER_PADDING_THEME, paperStyle, WHITE_ESCRITA_THEME, YELLOW_BG_UX } from "../../shared/utils";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import ImportFileFirstStep from "../../components/ImportFile/ImportFileFirstStep";
import ImportFileSecondStep from "../../components/ImportFile/ImportFileSecondStep";
import Skeleton from "@mui/material/Skeleton";
import ImportFileLastStep from "../../components/ImportFile/ImportFileLastStep";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import api from "../../axios";
import { showSnackMessage } from "../../actions/SnackActions";


const ImportFile = () => {
    const [sheet, setSheet] = useState(null);
    const [step, setStep] = useState(0);
    const [processedData, setProcessedData] = useState(null);
    const [contas, setContas] = useState([]);
    const [loading, setLoading] = useState(false);

    const estabelecimentoID = useSelector(state => state.StoreReducer.estabelecimentoId);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const dispatch = useDispatch();

    const processPlanilha = () => {
        setLoading(true);

        const formData = new FormData();
        formData.append("estabelecimento_id", estabelecimentoID);
        formData.append("sheet", sheet);
        
        api.ProcessPlanilha(formData).then(response => {            
            setProcessedData(response.data.lines);
            setContas(response.data.contas);
            setStep(1);
            setLoading(false);
        }).catch(() => {
            dispatch(showSnackMessage({message: "Algo deu errado! Não foi pssível processar a planilha.", severity: "error"}));
            setLoading(false);
        });
    };

    const savePlanilha = (contaSelected) => {
        let dataRequest = {
            estabelecimento_id: estabelecimentoID,
            planilha_data: processedData,
            conta: contaSelected
        };

        api.SavePlanilha(dataRequest).then(() => {
            setStep(2);
            setLoading(false);
        }).catch((e) => {
            if (e.response.data.error) {
                dispatch(showSnackMessage({message: e.response.data.error, severity: "error"}));
            }
            else {
                dispatch(showSnackMessage({message: "Algo deu errado! Não foi pssível salvar a planilha.", severity: "error"}));
            }
            setLoading(false);
            
        });
    };

    const fecharImportar = () => {
        window.history.back();
    };

    return (
        <div className="main" data-testid="import-planilha-container">
            <Header title="Importar planilha com lançamentos" />
            <Grid container sx={{display: "flex", justifyContent: "center", alignItems: "center", margin: "1rem 0"}}>
                <Stepper
                    alternativeLabel
                    activeStep={step}
                    sx={{width: "50vw"}}
                    connector={
                        <StepConnector
                            sx={{
                                [`&.${stepConnectorClasses.alternativeLabel}`]: {
                                  },
                                  [`&.${stepConnectorClasses.active}`]: {
                                    [`& .${stepConnectorClasses.line}`]: {
                                      backgroundColor: YELLOW_BG_UX
                                    },
                                  },
                                  [`&.${stepConnectorClasses.completed}`]: {
                                    [`& .${stepConnectorClasses.line}`]: {
                                      backgroundColor: YELLOW_BG_UX
                                    },
                                  },
                                  [`& .${stepConnectorClasses.line}`]: {
                                    height: 4,
                                    border: 0,
                                    backgroundColor: GRAY_BORDER_TABLE,
                                    borderRadius: 1,
                                  },
                            }}
                        />
                    }
                >
                    <Step
                        sx={{"& .MuiStepLabel-root .Mui-completed": {
                                color: YELLOW_BG_UX,
                            },
                            "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                                color: temaEscuro ? WHITE_ESCRITA_THEME : BLACK_PDF,
                            },
                            "& .MuiStepLabel-root .Mui-active": {
                                color: YELLOW_BG_UX,
                            },
                            "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":{
                                color: temaEscuro ? WHITE_ESCRITA_THEME : BLACK_PDF,
                            },
                            "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                                fill: BLACK_PDF,
                            },
                            "& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-text": {
                                fill: BLACK_PDF,
                            },
                            "& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-root": {
                                fill: GRAY_BORDER_TABLE,
                            },
                        }}
                    >
                        <StepLabel>Importar planilha</StepLabel>
                    </Step>
                    <Step
                        sx={{"& .MuiStepLabel-root .Mui-completed": {
                                color: YELLOW_BG_UX,
                            },
                            "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                                color: temaEscuro ? WHITE_ESCRITA_THEME : BLACK_PDF,
                            },
                            "& .MuiStepLabel-root .Mui-active": {
                                color: YELLOW_BG_UX,
                            },
                            "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":{
                                color: temaEscuro ? WHITE_ESCRITA_THEME : BLACK_PDF,
                            },
                            "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                                fill: BLACK_PDF,
                            },
                            "& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-text": {
                                fill: BLACK_PDF,
                            },
                            "& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-root": {
                                fill: GRAY_BORDER_TABLE,
                            }
                        }}
                    >
                        <StepLabel>Confirmar dados</StepLabel>
                    </Step>
                    <Step
                        completed={step === 2}
                        sx={{"& .MuiStepLabel-root .Mui-completed": {
                                color: YELLOW_BG_UX,
                            },
                            "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                                color: temaEscuro ? WHITE_ESCRITA_THEME : BLACK_PDF,
                            },
                            "& .MuiStepLabel-root .Mui-active": {
                                color: YELLOW_BG_UX,
                            },
                            "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":{
                                color: temaEscuro ? WHITE_ESCRITA_THEME : BLACK_PDF,
                            },
                            "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                                fill: BLACK_PDF,
                            },
                            "& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-text": {
                                fill: BLACK_PDF,
                            },
                            "& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-root": {
                                fill: GRAY_BORDER_TABLE,
                            }
                        }}
                    >
                        <StepLabel>Concluído</StepLabel>
                    </Step>
                </Stepper>
            </Grid>
            <Paper sx={{...paperStyle, backgroundColor: temaEscuro && PAPER_PADDING_THEME, backgroundImage: temaEscuro && "none"}}>
                {
                    loading ? (
                        <Skeleton variant="rectangular" width="100%" height={"80vh"} />
                    ) : (
                        step === 0 ? (
                            <ImportFileFirstStep
                                sheet={sheet}
                                setSheet={setSheet}
                                fecharImportar={fecharImportar}
                                processPlanilha={processPlanilha}
                            />
                        ) : step === 1 ? (
                            <ImportFileSecondStep
                                sheet={sheet}
                                savePlanilha={savePlanilha}
                                fecharImportar={fecharImportar}
                                processedData={processedData}
                                contas={contas.map(conta => {
                                    return {
                                        value: conta.id,
                                        label: conta.apelido,
                                        banco: conta.banco
                                    };
                                })}
                            />
                        ) : (
                            <ImportFileLastStep
                                fecharImportar={fecharImportar}
                            />
                        )
                    )
                }
            </Paper>
        </div>
    );
};

export default ImportFile;