import React, { useState, useEffect } from "react";
import {
    BORDER_BUTTON,
    BORDER_TABLE,
    currencyConfig, DASH_KPI_DATA_COLOR,
    GRAY_LABEL_UX, RED_ERROR_UX,
    styleTextStrong,
    WHITE_ESCRITA_THEME,
    LINE_TABLE,
    PAPER_PADDING_THEME,
    RED_INFO
} from "../../shared/utils";
import Grid from "@mui/material/Grid";
import IntlCurrencyInput from "react-intl-currency-input";
import { useSelector } from "react-redux";


const InputCurrency = ({
    valor = 0,
    setPrevValor = () => {},
    setValor = () => {},
    changesList = [],
    setChangesList = () => {},
    onBlur = () => {},
    error = false,
    errorMessage = "",
    width = "250px",
    ml = 0,
    title = "",
    dataTestId = "",
    disabled = false,
    max = undefined,
    required = true
}) => {
    const [inputValue, setInputValue] = useState(valor);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    useEffect(() => {
        setInputValue(valor);
    }, [valor]);

    const handleBlur = () => {
        if (!changesList.includes("valor")) {
            setChangesList([...changesList, "valor"]);
            setPrevValor(valor);
        }
        setValor(inputValue);
        if (onBlur) {
            onBlur();
        }
    };

    return (
        <Grid container sx={{width: width ?? "250px", position: "relative", ml: ml}}>
            <Grid item sx={{width: width ?? "250px", display: "flex", alignItems: "start", flexDirection: "column", gap: 0.5, paddingBottom: 0}}>
                <a style={{color: error ? RED_ERROR_UX : temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX, fontSize: "12px", position: "absolute", top: -8, left: 12, backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE}}>{title} {required && <span style={{color: RED_INFO}}>*</span>} </a>
                <div className="divCurrencyInputNew" style={{border: error ? `1px solid ${RED_ERROR_UX}` : (temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${BORDER_BUTTON}`)}}>
                    <IntlCurrencyInput
                        value={inputValue}
                        currency="BRL"
                        config={currencyConfig}
                        onChange={(event, value) => setInputValue(value)}
                        className={temaEscuro ? (disabled ? "currencyInputBlackDisabled" : "currencyInputBlack") : (disabled ? "currencyInputDisabled" : "currencyInput")}
                        style={{ ...styleTextStrong, color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, border: 0, height: "5.6px", fontSize: "16px", fontWeight: "600" }}
                        data-testid={dataTestId}
                        onBlur={() => handleBlur()}
                        disabled={disabled}
                        max={max}
                    />
                </div>
            </Grid>
            {
                (error && errorMessage) && (
                    <Grid container xs={12} sx={{mt: "1.2rem"}} >
                        <a style={{color: RED_ERROR_UX, fontSize: "12px", position: "absolute", bottom: -15, left: 12, backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE}}>{errorMessage}</a>
                    </Grid>
                )
            }
        </Grid>
    );
};

export default React.memo(InputCurrency);