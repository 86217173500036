import React from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
    BLUE_THEME, smallPaperStyle,
    BORDER_TABLE, WHITE_ESCRITA_THEME,
    styleText, GRAY_LABEL_UX, BORDER_PAPER,
} from "../../shared/utils";
import {useSelector} from "react-redux";
import Divider from "@mui/material/Divider";
import DefaultButton from "../Buttons/DefaultButton";


const ModalSelectedLoja = (props) => {

    const {
        openModal,
        setOpenModal,
        inputLoja,
        handleChangeLoja
    } = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    return (
        <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            data-testid="generic-modal"
        >
            <Paper style={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME, width: "50%"}}>
                <Grid item xs={10} className="start">
                    <h3 style={styles.text}>Existe uma sessão ativa do VXMF no navegador</h3>
                </Grid>
                <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}}/>
                <Grid container spacing={2} className="relative">
                    <Grid item xs={12} sx={{marginBottom: 4, marginTop: 2}}>
                        <span style={{...styleText, fontWeight: "700", color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}><p>Deseja encerrar a sessão e iniciar uma nova com o cliente {inputLoja}?</p></span>
                    </Grid>
                </Grid>
                <Grid container sx={{display: "flex", justifyContent: "end", gap: 2}}>
                    <Grid item>
                        <DefaultButton
                            onClick={() => setOpenModal(false)}
                            title="Cancelar"/>
                    </Grid>
                    <Grid item>
                        <DefaultButton
                            onClick={handleChangeLoja}
                            testId="generic-continue-btn"
                            title="Continuar"
                            variant="yellow"
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );

};

export default React.memo(ModalSelectedLoja);

const styles = {
	main: {
		marginBottom: "0px",
	},
    text: {
        marginTop: 0
    }
};