import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Skeleton } from "@mui/material";
import Header from "../../../components/Titles/Header";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
    BLUE_THEME,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER, GRAY_BG_UX,
    LINE_TABLE,
    PAPER_PADDING_THEME,
    paperStyle,
    YELLOW_BG_UX, BORDER_TABLE, BORDER_BUTTON, format
} from "../../../shared/utils";
import { useLocation } from "react-router-dom";
import Bradesco from "../../Contas/LogoBancos/Bradesco.png";
import Santander from "../../Contas/LogoBancos/Santander.png";
import Sicoob from "../../Contas/LogoBancos/Sicoob.png";
import Itau from "../../Contas/LogoBancos/Itau.png";
import BancoBrasil from "../../Contas/LogoBancos/BB.png";
import Inter from "../../Contas/LogoBancos/Inter.png";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {DateRangePicker} from "@mui/x-date-pickers-pro/DateRangePicker";
import InputSearch from "../../../components/Input/InputSearch";
import FilterEXM from "../../../components/Filter/FilterEXM";
import DefaultButton from "../../../components/Buttons/DefaultButton.js";
import DataGridWithPagination from "../../../components/DataGrid/DataGridWithPagination";
import Box from "@mui/material/Box";
import {showSnackMessage} from "../../../actions/SnackActions";
import api from "../../../axios";


const LancamentosIgnorados = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const [loading, setLoading] = useState(false);
    const [nomeBanco, setNomeBanco] = useState("");
    const [tipoConta, setTipoConta] = useState("");
    const [numberIgnorados, setNumberIgnorados] = useState(0);
    const [filterPlaceholders, setFilterPlaceholders] = useState({});
    const [periodo, setPeriodo] = useState("este_mes");
    const [specificDate, setSpecificDate] = useState("");
    const [arrowCounter, setArrowCounter] = useState(0);
    const [customPeriodo, setCustomPeriodo] = useState([null, null]);
    const [lastSearchedValue, setLastSearchedValue] = useState("");
    const [searchValue, setSearchValue] = useState(lastSearchedValue || "");
    const [selectedIgnorado, setSelectedIgnorado] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (location.state) {
            getDetailsIgnorados();
        }
    }, []);

    useEffect(() => {
        const isCustomPeriodoValid = customPeriodo[0] !== null && customPeriodo[1] !== null;
        const shouldFetchMovimentacoes =
            estabelecimentoId !== 0 &&
            (
                (periodo === "custom_periodo" && isCustomPeriodoValid) ||
                (periodo !== "custom_periodo")
            );

        if (shouldFetchMovimentacoes) {
            getDetailsIgnorados();
        }
    }, [estabelecimentoId, arrowCounter, periodo, customPeriodo]);

    const getDetailsIgnorados = () => {
        setLoading(true);
        const dataRequest = {
            estabelecimentoId: estabelecimentoId,
            conta_id: location.state.conta_id,
            periodo: periodo,
            plus_minus: arrowCounter,
            search: searchValue,
        };
        if (periodo === "custom_periodo") {
            let start_date = customPeriodo[0]._d;
            let end_date = customPeriodo[1]._d;
            dataRequest.start_date = `${start_date.getDate()}/${start_date.getMonth() + 1}/${start_date.getFullYear()}`;
            dataRequest.end_date = `${end_date.getDate()}/${end_date.getMonth() + 1}/${end_date.getFullYear()}`;
        }
        api.GetIgnorados(dataRequest).then((response) => {
            let data = response.data;
            setData(data.ignorados);
            setNumberIgnorados(data.total);
            setNomeBanco(data.banco);
            setTipoConta(data.tipo_conta);
            setFilterPlaceholders(data.filter_placeholders);
            setSpecificDate(data?.specific_date || "");
            setLastSearchedValue(data?.searched_value || "");
            setLoading(false);
            if (data.ignorados.length === 0) {
                dispatch(showSnackMessage({message: "Nenhum registro encontrado", severity: "info"}));
            }
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const renderBanco = (bancoNome) => {
        const bancos = {
            "Banco do Brasil": BancoBrasil,
            "Inter": Inter,
            "Bradesco": Bradesco,
            "Santander": Santander,
            "Sicoob": Sicoob,
            "Itaú": Itau,
        };
      
        if (bancos[bancoNome]) {
            return <img src={bancos[bancoNome]} alt={bancoNome} style={{height: 70, width: 71, borderRadius: "5px"}} />;
        }
      
        return (
            <div
                style={{
                    height: 70,
                    width: 71,
                    backgroundColor: YELLOW_BG_UX,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "black",
                    borderRadius: "5px",
                }}
            >
                {bancoNome.toUpperCase()}
            </div>
        );
    };

    const handleDateChange = (newValue) => {
        setCustomPeriodo(newValue);
    };

    const columns = [
        { field: "formatted_data_movimentacao", headerName: "Data", width: 120, align: "center", headerAlign: "center" },
        { field: "descricao_lancamento", headerName: "Descrição", flex: 1, align: "center", headerAlign: "center" },
        { 
            field: "valor",
            headerName: "Valor (R$)",
            width: 120,
            align: "center",
            headerAlign: "center",
            valueFormatter: (params) => format(params, 2)
        },
        { 
            field: "situacao",
            headerName: "Situação",
            width: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            fontSize: "14px",
                            borderRadius: "5px"
                        }}
                    >
                        {params.value}
                    </Box>
                );
            }
        },
    ];

    const restaurarIgnorados = () => {
        setLoading(true);
        const dataRequest = {
            ids: selectedIgnorado
        };
        api.PutRestaurarIgnorados(dataRequest).then(() => {
            setLoading(false);
            getDetailsIgnorados();
            setSelectedIgnorado([]);
            dispatch(showSnackMessage({message: "Lançamento(s) restaurado(s) com sucesso", severity: "success"}));
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    return (
        <div className="main" data-testid="lancamentos-ignorados">
            <Header title="Lançamentos Ignorados" subTitle="Contas" previousPage="/contas" />
            <Paper sx={{
                ...paperStyle,
                backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE,
                backgroundImage: temaEscuro && "none",
                border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`
            }}>
                {
                    loading ? (
                        [...Array(15).keys()].map((i,d) =>{
                            return(
                                <Skeleton key={d} height={50}/>
                            );
                        })
                    ) : (
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container sx={{display: "flex", gap: 2, flexWrap: "nowrap"}}>
                                    <Grid item>
                                        {renderBanco(nomeBanco)}
                                    </Grid>
                                    <Grid item>
                                        <Grid container sx={{display: "flex", gap: 1, flexDirection: "column"}}>
                                            <Grid item sx={{display: "flex", gap: 1, alignItems: "center"}}>
                                                <Typography fontWeight="bold" fontSize="17px">
                                                    {nomeBanco}
                                                </Typography>
                                                <Typography fontWeight="normal" fontSize="15px">
                                                    {tipoConta}
                                                </Typography>
                                            </Grid> 
                                            <Grid item sx={{display: "flex", gap: 0.1, flexDirection: "column"}}>
                                                <Typography fontWeight="normal" fontSize="14px">
                                                    Existem {numberIgnorados} lançamentos ignorados, que podem ter sido ignorados automaticamente pelo VXMF devido a risco de duplicidade ou manualmente ignorados por algum usuário. 
                                                </Typography>
                                                <Typography fontWeight="normal" fontSize="14px">
                                                    É importante ressaltar que ao restaurar um <strong>lançamento marcado como duplicado</strong>, isso pode <strong>impactar o saldo da sua conta</strong>. 
                                                </Typography>
                                                <Typography fontWeight="normal" fontSize="14px">
                                                    O lançamento restaurado estará disponível novamente em conciliações pendentes. 
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{marginTop: 4}}>
                                <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} /> 
                            </Grid>
                            <Grid item xs={12} sx={{marginY: 2}}>
                                <Grid container sx={{display: "flex", gap: 2, flexWrap: "wrap"}}>
                                    <Grid item>
                                        <FilterEXM
                                            placeholders={filterPlaceholders}
                                            periodo={periodo}
                                            setPeriodo={setPeriodo}
                                            specificDate={specificDate}
                                            setSpecificDate={setSpecificDate}
                                            arrowCounter={arrowCounter}
                                            setArrowCounter={setArrowCounter}
                                            isIgnorado
                                        />
                                        {
                                            periodo === "custom_periodo" && (
                                                <DateRangePicker
                                                    value={customPeriodo}
                                                    onChange={handleDateChange}
                                                    localeText={{start: "Início", end: "Fim"}}
                                                    format="DD/MM/YYYY"
                                                    sx={{
                                                        width: "330px",
                                                        marginTop: 2,
                                                        "& .MuiInputLabel-root": {color: temaEscuro && BORDER_BUTTON}
                                                    }}
                                                    slotProps={{
                                                        textField: {
                                                            size: "small",
                                                            sx: {backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}
                                                        }
                                                    }}
                                                />
                                            )
                                        }
                                    </Grid>
                                    <Grid item>
                                        <InputSearch
                                            periodo={periodo}
                                            setSearchValue={setSearchValue}
                                            searchValue={searchValue}
                                            functionProps={() => getDetailsIgnorados()}
                                            className="input-text"
                                            EXM
                                        />
                                    </Grid>
                                    <Grid item>
                                        <DefaultButton
                                            title="Filtrar"
                                            onClick={() => getDetailsIgnorados()}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <DataGridWithPagination
                                    pagination
                                    checked
                                    rows={(row) => row.id}
                                    columns={columns}
                                    data={data}
                                    customRowSelectionModelChange={(item) => setSelectedIgnorado(item)}
                                    lancamentosIgnorados={selectedIgnorado}
                                    restaurarIgnorados={restaurarIgnorados}
                                />
                            </Grid>
                        </Grid>
                    )
                }
            </Paper>
        </div>
    );
};

export default LancamentosIgnorados;