import React from "react";
import {BLUE_THEME, GRAY_BG_UX} from "../../shared/utils";
import {useSelector} from "react-redux";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import DefaultButton from "../Buttons/DefaultButton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";


const DefaultFooter = ({
    sum,
    operation,
    options,
    clearSum,
    setOperation,
    dataLength,
    NoFilters
}) => {

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    return (
        <Grid item xs={12}>
            <Grid container>
                {
                    NoFilters ? (
                        <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: 2}}>
                            <span><p>Número Total de Linhas: {dataLength}</p></span>
                        </Grid>
                    ) : (
                        <React.Fragment>
                            <Grid item xs={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <span><p>{operation} das Células Selecionadas: {sum}</p></span>
                            </Grid>
                            <Grid item xs={2} sx={{display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: 2}}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        size="small"
                                        disablePortal
                                        disableClearable
                                        PaperComponent={({ children }) => (
                                            <Paper
                                                style={{
                                                    backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
                                                    color: temaEscuro ? "#FFF" : "#4A5568",
                                                    fontWeight: "500"
                                                }}
                                            >
                                                {children}
                                            </Paper>
                                        )}
                                        onChange={(event, newValue) => {
                                            setOperation(newValue);
                                        }}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                        value={operation || null}
                                        options={options}
                                        renderInput={(params) => <TextField {...params} label="Operação" />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} sx={{display: "flex", alignItems: "center", paddingLeft: 2}}>
                                <DefaultButton
                                    title={"limpar"}
                                    onClick={clearSum}
                                />
                            </Grid>
                            <Grid item xs={6} sx={{display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: 2, gap: 2}}>
                                <span><p>Número Total de Linhas: {dataLength}</p></span>
                            </Grid>
                        </React.Fragment>                        
                    )
                }
            </Grid>
        </Grid>
    );
};

export default React.memo(DefaultFooter);