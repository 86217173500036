import React from "react";
import Transferecia from "../../containers/EXM/Transferencia";
import Movimentacao from "../../containers/EXM/Movimentacao";
import GerarCompra from "../../containers/NotasCompras/GerarCompra";

const OpenModaisEXM = (props) => {
  const {openEXM} = props;

  return (
    <React.Fragment>
      {openEXM === "transferencia" && <Transferecia />}
      {openEXM === "despesa" && <Movimentacao type="despesa" />}
      {openEXM === "receita" && <Movimentacao type="receita" />}
      {openEXM === "compra" && <GerarCompra />}
    </React.Fragment>
  );
};

export default OpenModaisEXM;