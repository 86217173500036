import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import api from "../../axios";
import {showSnackMessage} from "../../actions/SnackActions";
import { useDispatch, useSelector } from "react-redux";
import {useLocation} from "react-router-dom";
import {
    formatterCurrency,
    BLUE_THEME,
    GREEN_SUCCESS_UX,
    RED_INFO,
    paperStyle,
    PAPER_PADDING_THEME,
    LINE_TABLE,
} from "../../shared/utils";
import Header from "../../components/Titles/Header";
import Paper from "@mui/material/Paper";


const BoletosHistoric = () => {
    const location = useLocation();
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const dispatch = useDispatch();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const dataRequest = {
            estabelecimento_id: query.get("estabelecimento_id"),
            status: "Historico"
        };
        getBoletos(dataRequest);
    }, []);

    const getBoletos = (dataRequest) => {
        api.GetBoletos(dataRequest).then(response => {
            let dataResponse = response.data;
            setColumns(dataResponse.columns);
            setData(dataResponse.data);
        }).catch(() => {
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde.", severity: "error"}));
        });
    };

    return (
        <div className="main" data-testid="historico_despesas">
            <Header title="Histórico" subTitle="Boletos" previousPage="/boletos" />
            <Paper sx={{...paperStyle, backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE, border: !temaEscuro && "", backgroundImage: temaEscuro && "none"}}>
                <Grid>
                    <TableContainer sx={{backgroundColor: temaEscuro ? BLUE_THEME : "transparent", borderRadius: "10px", border: "1px solid", borderColor: "divider"}}>
                        <Table data-testid="table_historic">
                            <TableHead>
                                <TableRow>
                                    {columns.map((col) => {
                                        return (
                                            <TableCell key={col.field || col} sx={{borderRight: col == "CNPJ" && "1px solid", borderRightColor: col == "CNPJ" && "divider", fontWeight: "bold"}} align="center">{col}</TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {data.map((row) => (
                                <TableRow
                                key={row.id}
                                >
                                <TableCell align="center">{row.data_vencimento}</TableCell>
                                <TableCell align="center">{formatterCurrency(row.valor, 2)}</TableCell>
                                <TableCell align="center">{row.pagador}</TableCell>
                                <TableCell align="center">{row.beneficiario}</TableCell>
                                <TableCell align="center">{row.estabelecimento}</TableCell>
                                <TableCell sx={{borderRight: "1px solid", borderRightColor: "divider"}} align="center">{row.cnpj_beneficiario}</TableCell>
                                <TableCell sx={{color: row.novo_status == "Aceito" ? GREEN_SUCCESS_UX : RED_INFO}} align="center">{row.novo_status == "Cancelado" ? "Recusado" : row.novo_status}</TableCell>
                                <TableCell align="center">{row.usuario}</TableCell>
                                <TableCell align="center">{row.data_modificado}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Paper>
        </div>
    );
};

export default BoletosHistoric;