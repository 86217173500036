import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import FilterEXM from "../Filter/FilterEXM";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DataGrid from "../DataGrid/DataGrid";
import api from "../../axios";
import {showSnackMessage} from "../../actions/SnackActions";
import {BLUE_THEME, formatterCurrency, GRAY_BG_UX,} from "../../shared/utils";
import moment from "moment";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import InputSearch from "../Input/InputSearch";
import OptionsNotasSelected from "../Buttons/OptionsNotasSelected";
import { changeOpenLancamento } from "../../actions/StoreActions";
import DefaultButton from "../Buttons/DefaultButton";



const NotasComprasTab = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const nav = useNavigate();

    const [periodo, setPeriodo] = useState(location.state !== null ? "custom_periodo" : "este_mes");
    const [filterPlaceholders, setFilterPlaceholders] = useState({});
    const [specificDate, setSpecificDate] = useState("");
    const [arrowCounter, setArrowCounter] = useState(0);
    const [lastSearchedValue, setLastSearchedValue] = useState("");
    const [searchValue, setSearchValue] = useState(lastSearchedValue || "");
    const [selectedNotas, setSelectedNotas] = useState([]);
    const [notasComprasData, setNotasComprasData] = useState([]);

    const [customPeriodo, setCustomPeriodo] = useState(() => {
        if (location.state !== null && location.state.dataRecebimento) {
            const dates = location.state.dataRecebimento;
            if (dates.length !== 2) {
                const dateFormatted = moment(dates, "DD/MM/YYYY");
                return [dateFormatted, dateFormatted];
            } else {
                const [date1, date2] = dates;
                const startDate = moment(date1, "DD/MM/YYYY");
                const endDate = moment(date2, "DD/MM/YYYY");
                return [startDate, endDate];
            }
        } else {
            return [null, null];
        }
    });

    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const isOperator = useSelector(state => state.AuthReducer.isOperator);
    const isAprover = useSelector(state => state.AuthReducer.isApprover);

    useEffect(() => {
        if (location.state !== null) {
            setPeriodo("custom_periodo");
        }
    }, [location.state]);

    useEffect(() => {
        if (estabelecimentoId !== 0 && periodo !== "custom_periodo") {
            getNotasCompras();
        }
    }, [estabelecimentoId, arrowCounter, customPeriodo, periodo]);

    const getNotasCompras = () => {
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            periodo: periodo,
            search: searchValue,
        };
        if (arrowCounter !== 0) {
            dataRequest.plus_minus = arrowCounter;
        }
        if (periodo === "custom_periodo") {
            let start_date = customPeriodo[0]._d;
            let end_date = customPeriodo[1]._d;
            dataRequest.start_date = `${start_date.getDate()}/${start_date.getMonth() + 1}/${start_date.getFullYear()}`;
            dataRequest.end_date = `${end_date.getDate()}/${end_date.getMonth() + 1}/${end_date.getFullYear()}`;
        }
        api.GetNotasCompras(dataRequest).then(response => {
            let data = response.data;

            setFilterPlaceholders(data.filter_placeholders);
            setSpecificDate(data?.specific_date || "");
            setLastSearchedValue(data?.searched_value || "");
            let notasData = data.notas_data;
            if (notasData.length === 0) {
                dispatch(showSnackMessage({message: "Nenhum registro encontrado", severity: "info"}));
            }
            setNotasComprasData(notasData);
        }).catch(() => {
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const deleteNotas = () => {
        const dataRequest = {
            notas_id: selectedNotas,
            estabelecimento_id: estabelecimentoId
        };
        api.DeleteNotas(dataRequest).then(response => {
            getNotasCompras();
            dispatch(showSnackMessage({message: response.data, severity: "success"}));
        }).catch(() => {
            dispatch(showSnackMessage({message: "Não foi possível deletar as notas selecionadas.", severity: "error"}));
        });
    };

    const columns =[
        { field: "data_movimentacao_str", headerName: "Data da compra", flex: 1, align: "center", headerAlign: "center" },
        { field: "serie_nfe", headerName: "Compra", flex: 1, align: "center", headerAlign: "center" },
        { field: "nome_emitente", headerName: "Fornecedor", flex: 1, align: "center", headerAlign: "center" },
        { field: "vencimento_str", headerName: "Vencimento", flex: 1, align: "center", headerAlign: "center" },
        { field: "pagamento", headerName: "Pagamento", flex: 1, align: "center", headerAlign: "center" },
        {
            field: "valor_total",
            headerName: "Valor R$",
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2)
        },
    ];

    const handleDateChange = (newValue) => {
        setCustomPeriodo(newValue);
    };

    const openGerarCompra = () => {
        dispatch(changeOpenLancamento("compra"));
    };

    return (
        <React.Fragment>
            <Grid container data-testid="notas-compras-container">
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {
                            (isAdmin || isOperator || isAprover) && (
                                <Grid item>
                                    <DefaultButton
                                        testId="nova-compra"
                                        title="Nova compra de produto"
                                        onClick={() => openGerarCompra()}
                                        
                                        variant="yellow"
                                    />
                                </Grid>
                            )
                        }
                        <Grid item>
                            <FilterEXM
                                placeholders={filterPlaceholders}
                                periodo={periodo}
                                setPeriodo={setPeriodo}
                                specificDate={specificDate}
                                setSpecificDate={setSpecificDate}
                                arrowCounter={arrowCounter}
                                setArrowCounter={setArrowCounter}
                                callFunction={getNotasCompras}
                            />
                            {
                                periodo === "custom_periodo" && (
                                    <DateRangePicker
                                        value={customPeriodo}
                                        onChange={handleDateChange}
                                        localeText={{ start: "Início", end: "Fim" }}
                                        sx={{width: "330px", marginTop: 1}}
                                        format="DD/MM/YYYY"
                                        slotProps={{
                                            textField: {
                                                size: "small",
                                                sx: {backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}
                                            }
                                        }}
                                    />
                                )
                            }
                        </Grid>
                        <Grid item>
                            <InputSearch
                                periodo={periodo}
                                setSearchValue={setSearchValue}
                                searchValue={searchValue}
                                className="input-text"
                                functionProps={getNotasCompras}
                            />
                        </Grid>
                        <Grid item xs={12} height={"calc(100vh - 196px - 72px - 70px)"}>
                            <DataGrid
                                checked={(isAdmin || isOperator || isAprover)}
                                row={(row) => (row.id)}
                                rows={(row) => row.id}
                                columns={columns}
                                data={notasComprasData}
                                customHandleCellClick={(params) => {
                                    if (params.colDef.field != "__check__") {
                                        nav("/detalhe_nota", {state: params.row.serie_nfe});
                                    }
                                }}
                                customRowSelectionModelChange={(item) => {
                                    setSelectedNotas(item);
                                }}
                                customFooter={
                                    (isAdmin || isOperator || isAprover) &&
                                    <OptionsNotasSelected
                                        deleteNotas={deleteNotas}
                                        notas={selectedNotas}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default NotasComprasTab;