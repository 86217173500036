import React, { Fragment, useEffect }  from "react";
import {BORDER_BUTTON, LINE_TABLE, BORDER_BLACK_THEME_CARD} from "../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {BLUE_THEME} from "../../shared/utils";
import { FileUploader } from "react-drag-drop-files";


const AnexoUploaderModal = (props) => {
    const {openModal, setOpenModal, anexos, setAnexos, changesList, setChangesList, setPrevAnexos} = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    
    
    useEffect(() => {
        if (openModal) {
            window.addEventListener("paste", handleOnPaste);
        }
        
        return () => {
            window.removeEventListener("paste", handleOnPaste);
        };
    }, [openModal]);

    const handleOnPaste = (e) => {
        const items = e.clipboardData.items;
        for (let item of items) {
            if (item.type.startsWith("image/")) {
                const filePasted = item.getAsFile();

                setPrevAnexos(anexos);
                setChangesList([...changesList, "anexos"]);
                setAnexos((prevAnexos) => {
                    const newAnexos = [...prevAnexos];

                    newAnexos.find((anexo) => anexo.arquivo === null).arquivo = filePasted;
                
                    return newAnexos;
                });

                setOpenModal(false);
            }
        }
    };

    return(
        <Fragment>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="info-modal"
            >
                <Paper sx={{
                    width: "40%",
                    height: "40%",
                    overflowY: "hidden",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper", backgroundColor: temaEscuro && BLUE_THEME}}
                >
                    <FileUploader
                        hoverTitle="Arraste os anexos até aqui"
                        handleChange={(anexos_event) => {
                            let filesList = Array.from(anexos_event);

                            let newAnexos = anexos.filter((anexo) => anexo.arquivo !== null);

                            let filesObjects = filesList.map((file) => ({
                                forma_anexo: "Arquivo",
                                arquivo: file,
                                tipo_anexo: "Contrato",
                                descricao: "",
                            }));

                            newAnexos.push.apply(newAnexos, filesObjects);
                            
                            setPrevAnexos(anexos);
                            setChangesList([...changesList, "anexos"]);
                            setAnexos(newAnexos);
                            setOpenModal(false);
                        }}
                        multiple={true}
                        maxSize={10}
                        types={["XLS", "XLSX", "MP3", "PNG", "JPEG", "JPG", "DOC", "DOCX", "PDF", "MP4", "TXT", "CSV", "PPT", "PPTX"]}
                    >
                        <Grid
                            container
                            sx={{width: "100%", height: "100%"}}
                        >
                            <Paper sx={{cursor: "pointer", width: "100%", height: "99%", display: "flex", alignItems: "center", justifyContent: "center", boxShadow: "none", backgroundColor: temaEscuro ? BLUE_THEME : LINE_TABLE, backgroundImage: temaEscuro && "none", border: temaEscuro ? `2px dashed ${BORDER_BLACK_THEME_CARD}` : `1px dashed ${BORDER_BLACK_THEME_CARD}`}}>
                                <p style={{margin: 0, color: temaEscuro && BORDER_BUTTON}}>Escolha os anexos ou arraste-os para este espaço</p>
                            </Paper>
                        </Grid>
                    </FileUploader>
                </Paper>
            </Modal>
        </Fragment>
    );                  
};

export default AnexoUploaderModal;