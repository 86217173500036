import React, {useState, useMemo, useEffect} from "react";
import Paper from "@mui/material/Paper";
import {
    BLUE_THEME,
    BORDER_TABLE,
    smallPaperStyle,
    WHITE_ESCRITA_THEME,
    GRAY_LABEL_UX,
    styleTitleModal,
    styleText,
} from "../../shared/utils";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import FilterWithTag from "../../components/Filter/FilterWithTag";
import ArticleIcon from "@mui/icons-material/Article";
import api from "../../axios";
import {showSnackMessage} from "../../actions/SnackActions";
import Modal from "@mui/material/Modal";
import DefaultButton from "../Buttons/DefaultButton";


const MigracaoModal = (props) => {

    const {
        openModal,
        setOpenModal,
    } = props;

    const dispatch = useDispatch();
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const [lojas, setLojas] = useState([]);
    const [selectedLoja, setSelectedLoja] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);

    useEffect(() => {
        if (openModal) {
            getLojas();
            const lastClickedTime = localStorage.getItem("migracaoButtonClickedTime");
            if (lastClickedTime) {
                const timePassed = Date.now() - parseInt(lastClickedTime, 10);
                if (timePassed < 15 * 60 * 1000) {
                    setIsButtonDisabled(true);
                    const remaining = 15 * 60 * 1000 - timePassed;
                    setRemainingTime(Math.floor(remaining / 1000));
                    const interval = setInterval(() => {
                        setRemainingTime(prevTime => {
                            if (prevTime <= 1) {
                                clearInterval(interval);
                                setIsButtonDisabled(false);
                                localStorage.removeItem("migracaoButtonClickedTime");
                                return 0;
                            }
                            return prevTime - 1;
                        });
                    }, 1000);
                }
            }
        }
    }, [openModal]);

    const getLojas = async () => {
        api.GetLojasMigracao().then((response) => {
            setLojas(response.data);
        }).catch(() => {
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };

    const postMigracao = async () => {
        const dataRequest = {
            lojas_name: selectedLoja
        };
        api.PostMigracao(dataRequest).then((response) => {
            dispatch(showSnackMessage({ message: response.data.message, severity: "success" }));
            setOpenModal(false);
            setRemainingTime(15 * 60);
            localStorage.setItem("migracaoButtonClickedTime", Date.now().toString());
            // Desabilita o botão por 15 minutos
            const interval = setInterval(() => {
                setRemainingTime(prevTime => {
                    if (prevTime <= 1) {
                        clearInterval(interval);
                        setIsButtonDisabled(false);
                        localStorage.removeItem("migracaoButtonClickedTime");
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }).catch(() => {
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };

    const article = useMemo(() => (
		<ArticleIcon/>
	), []);

    const fileMigracao = () => {
        window.open("https://voxelgestaocom.sharepoint.com/:w:/r/sites/VoxelGesto/_layouts/15/Doc.aspx?sourcedoc=%7BE70F11EF-20C7-4739-971D-9E70282660C2%7D&file=MANUAL%20-%20MIGRA%C3%87%C3%83O.docx&action=default&mobileredirect=true&wdOrigin=OFFICE-OFFICE-METAOS.FILEBROWSER.FILES-SITES-FOLDER", "_blank");
    };

    return (
        <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            data-testid="generic-modal"
        >
            <Paper style={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME, width: "50%"}}>
                <Grid container>
                    <Grid item xs={12} sx={{borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, marginBottom: 2}}>
                        <h3 style={{marginTop: 0, ...styleTitleModal, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: "700"}}>Migração VXMF</h3>
                    </Grid>
                    <Grid item xs={12} sx={{display: "flex", flexDirection: "column", gap: 2}}>
                        <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Selecione uma loja</span>
                        {isButtonDisabled && (
                            <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontSize: "13px" }}>
                                Aguarde {Math.floor(remainingTime / 60)}:{("0" + (remainingTime % 60)).slice(-2)} para migrar novamente
                            </span>
                        )}
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 2}}>
                        <Grid container sx={{display: "flex", gap: 2, flexDirection: "row"}}>
                            <Grid item>
                                <FilterWithTag
                                    widthSetting="300px"
                                    options={lojas}
                                    setOptions={setSelectedLoja}
                                    placeholderProp="Lojas disponíveis"
                                    optionFilter
                                    isConciliacao
                                />
                            </Grid>
                            <Grid item>
                                <DefaultButton
                                    title="Manual de Migração"
                                    startIcon={article}
                                    onClick={fileMigracao}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{display: "flex", gap: 2, justifyContent: "end", marginTop: 8}}>
                        <DefaultButton
                            title="Cancelar"
                            onClick={() => setOpenModal(false)}
                        />
                        <DefaultButton
                            title="Migrar"
                            onClick={postMigracao}
                            disabled={isButtonDisabled}
                            variant="yellow"
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );

};

export default React.memo(MigracaoModal);