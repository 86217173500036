import React from "react";
import { Grid } from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import SavingsIcon from "@mui/icons-material/Savings";
import SwapVerticalCircleIcon from "@mui/icons-material/SwapVerticalCircle";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import HelpIcon from "@mui/icons-material/Help";
import { 
    YELLOW_BG_UX,
    RED_NEGATIVE_UX, 
    GRAY_BG_UX, 
    WHITE_THEME_BLACK, 
    COLOR_LABEL_INPUT, 
    GRAY_LABEL_UX,
    PAPER_PADDING_THEME,
    formatterCurrency,
    INFO_THEME,
    GRAY_BORDER_TABLE,
    WHITE_ESCRITA_THEME,
    RED_INFO
} from "../../shared/utils";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import DataGrid from "../DataGrid/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { changeOpenAccordionSelected } from "../../actions/StoreActions";
import { useNavigate } from "react-router-dom";
import ReportIcon from "@mui/icons-material/Report";


const MovimentacoesContaOther = (props) => {

    const {tipoConta, apelidoConta, detalhesData, saldoAtual} = props;

    const dispatch = useDispatch();
    const nav = useNavigate();

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const isOperator = useSelector(state => state.AuthReducer.isOperator);
    const isAprover = useSelector(state => state.AuthReducer.isApprover);
    const openAccordionSelected = useSelector(state => state.StoreReducer.openAccordionSelected);

    const getIconTipoConta = (tipoConta) => {
        switch (tipoConta) {
            case "Conta Corrente":
                return <AccountBalanceIcon />;
            case "Caixinha":
                return <Inventory2Icon />;
            case "Cartão de Crédito":
                return <CreditCardIcon />;
            case "Poupança":
                return <SavingsIcon />;
            case "Aplicação Automática":
                return <SwapVerticalCircleIcon />;
            case "Investimento":
                return <QueryStatsIcon />;
            case "Outro tipo de conta":
                return <HelpIcon />;
            default:
                return <AccountBalanceIcon />;
    }};

    const handleChange = (panel) => (event, isExpanded) => {
        dispatch(changeOpenAccordionSelected(isExpanded ? panel : ""));
    };

    const columns = [
        { 
            field: "descricao_vxmf",
            headerName: "Descrição da movimentação",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        { 
            field: "vxmf",
            headerName: "VXMF",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div style={{borderRight: temaEscuro ? `1px solid ${INFO_THEME}` : `1px solid ${GRAY_BORDER_TABLE}`, marginRight: -10}}>
                        <span style={{color: params.row.valor_vxmf < 0 && RED_INFO, fontWeight: "bold", width: "10000px"}}>
                            {formatterCurrency(params.row.valor_vxmf, 2)}
                        </span>
                    </div>
                );
            },
        },
    ];

    const handleOpenModalEditMovimentacao = (row) => {

        const routeMap = {
            "Transferência": "/editar_transferencia",
            "Despesa": "/editar_movimentacao",
            "Receita": "/editar_movimentacao",
        };

        const route = routeMap[row.tipo_movimentacao];

        if (route) {
            nav(route, { state: { id_movimentacao: row.id } });
        }

    };

    const handleEditMovimentacao = (row) => {
        if (isAdmin || isOperator || isAprover) {
            handleOpenModalEditMovimentacao(row);
        }
    };

    return (
        <Grid container spacing={2} data-testid="movimentacoes-conta-other">
            <Grid item xs={12} sx={{display: "flex", alignItems: "center", gap: 2}}>
                <div
                    style={{
                        height: "40px",
                        width: "40px",
                        padding: "1px",
                        backgroundColor: YELLOW_BG_UX,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "black",
                        borderRadius: "5px",
                    }}
                >
                    {getIconTipoConta(tipoConta)}
                </div>
                <Grid container sx={{display: "flex", flexDirection: "column"}}>
                    <Grid item>
                        <Typography variant="body2" sx={{color: temaEscuro && WHITE_ESCRITA_THEME}} fontWeight="bold" fontSize="17px">
                            {apelidoConta}
                        </Typography>
                    </Grid>
                    <Grid item sx={{display: "flex", gap: 1}}>
                        <Typography variant="body2" sx={{color: temaEscuro && WHITE_ESCRITA_THEME}}>
                            Saldo atual no VXMF:
                        </Typography>
                        <Typography variant="body2" sx={{color: saldoAtual < 0 ? RED_INFO : (temaEscuro && WHITE_ESCRITA_THEME)}} fontWeight="bold">
                            {formatterCurrency(saldoAtual)}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {detalhesData.map((item) => (
                    <Accordion
                        key={item.data}
                        expanded={openAccordionSelected === item.data}
                        onChange={handleChange(item.data)}
                        sx={{ marginBottom: 1, backgroundColor: "transparent" }}
                        data-testid="movimentacao-accordion"
                    >
                        <AccordionSummary
                            expandIcon={
                                <React.Fragment>
                                    {
                                        item.ultimo_saldo !== item.saldo_banco && (
                                            <ReportIcon
                                                sx={{color: RED_NEGATIVE_UX}}
                                            />
                                        )
                                    }
                                    <ExpandMoreIcon style={{color: !temaEscuro && GRAY_BG_UX }} />
                                </React.Fragment>
                            }
                            sx={{ backgroundColor: GRAY_LABEL_UX }}
                        >
                            <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Grid item xs={4} sx={{display: "flex", justifyContent: "start", alignItems: "center", gap: 1}}>
                                    <Typography sx={{color: temaEscuro ? WHITE_THEME_BLACK : GRAY_BG_UX}}>{item.data}</Typography>
                                    <Typography sx={{color: temaEscuro ? COLOR_LABEL_INPUT : GRAY_BG_UX, width: "200px"}}>{item.dia_semana}</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{display: "flex", justifyContent: "end", alignItems: "center", marginRight: 2, gap: 1}}>
                                    <Typography sx={{color: temaEscuro ? COLOR_LABEL_INPUT : GRAY_BG_UX}}>
                                        Saldo no final do dia: 
                                    </Typography>
                                    <Typography sx={{color: item.total_vxmf < 0 ? RED_INFO : (temaEscuro ? WHITE_THEME_BLACK : GRAY_BG_UX), fontWeight: "bold"}}>
                                        {formatterCurrency(item.total_vxmf, 2)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                padding: "16px 0px 0px 0px",
                                backgroundColor: temaEscuro && PAPER_PADDING_THEME,
                            }}
                        > 
                            <div style={{maxHeight: "77vh", width: "100%"}}>
                                {openAccordionSelected === item.data && 
                                    <DataGrid
                                        columns={columns}
                                        data={item.detalhes}
                                        row={(row) => row.id}
                                        customRowClick={(params) => {
                                            handleEditMovimentacao(params.row);
                                        }}
                                        columnGroupHeaderHeight={36}
                                    />
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Grid>
        </Grid>
    );
};

export default MovimentacoesContaOther;