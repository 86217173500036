import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DefaultButton from "./DefaultButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
    BLACK_TABLE_THEME,
} from "../../shared/utils";


export default function OptionsCadastrosSelectedItems({cadastroItems, deleteCadastros}) {

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const moreVertIcon = useMemo(() => (
		<MoreVertIcon/>
	), []);

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    
    return (
        <div>
            <DefaultButton
                title={cadastroItems.length > 1 ? "Ações em lote" : "Ações"}
                startIcon={moreVertIcon}
                onClick={handleClick}
            />
                <Menu
                    sx={{
                        "& .css-kc02vp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper" : {
                            background: temaEscuro && BLACK_TABLE_THEME
                        }
                    }}
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <MenuItem
                        onClick={() => deleteCadastros()}
                    >
                        Excluir
                    </MenuItem>
                </Menu>
        </div>
    );
}