import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    BLACK_TABLE,
    BLUE_THEME,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    BORDER_TABLE,
    formatterPercentage,
    formatterThousand,
    getCurrentDate,
    GRAY_BG_UX,
    GRAY_LABEL_UX,
    GRAY_LABEL_UX_THEME,
    LINE_TABLE,
    PAPER_PADDING_THEME,
    paperStyle,
    styleText,
    WHITE_ESCRITA_THEME,
    WHITE_THEME_BLACK,
    YELLOW_BG_UX,
    BLACK_LABEL_UX,
    GRAY_BG_BODY,
    GRAY_STRONG_UX,
    TITLE_SIZE,
    formaPagamentoOptions,
    handleDayMonthYear
} from "../../../shared/utils";
import FilterSingleOption from "../../../components/Filter/FilterSingleOption";
import api from "../../../axios";
import FilterDayMonthYear from "../../../components/Filter/FilterDayMonthYear";
import moment from "moment";
import InputSearch from "../../../components/Input/InputSearch";
import Anexos from "../../../components/Tabs/Anexos";
import RateiosReceita from "../../../components/Tabs/RateiosReceita";
import CreateOptionModal from "../../../components/Modal/CreateOptionModal";
import RateioCentroCustoModal from "../../../components/Modal/RateioCentroCustoModal";
import { cobrarSempreOptionsMes } from "../../EXM/Enums";
import ParcelasModal from "../../../components/Modal/ParcelasModal";
import DefaultButton from "../../../components/Buttons/DefaultButton";
import { showSnackMessage } from "../../../actions/SnackActions";
import Divider from "@mui/material/Divider";
import InputCurrency from "../../../components/Input/InputCurrency";
import SaveOptions from "../../../components/Buttons/SaveOptions";
import { useHotkeys } from "react-hotkeys-hook";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CompletarMovimentacao = ({
    open,
    setOpen,
    lancamento,
    conta,
    categoria,
    indexConciliacao,
    handleAddMovimentacao,
}) => {
    const dateParts = lancamento.data_lancamento.split("/");
    const dataLancamento = new Date(
        `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`
    );
    const componente = lancamento.valor_lancamento > 0 ? "Receita" : "Despesa";

    const isLargeScreen = useMediaQuery("(min-width:1640px)");

    const dispatch = useDispatch();

    const currentMonthDate = moment(getCurrentDate()).toDate();
    const [selectedTab, setSelectedTab] = useState("observacoes");
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [optionCreateModal, setOptionCreateModal] = useState("");
    const [openRateioCentroCustoModal, setOpenRateioCentroCustoModal] =
        useState(false);
    const [categoriaRateioCentroCusto, setCategoriaRateioCentroCusto] =
        useState("");
    const [
        valorCategoriaRateioCentroCusto,
        setValorCategoriaRateioCentroCusto,
    ] = useState(0);
    const [indexRateioCentroCusto, setIndexRateioCentroCusto] = useState(0);

    const [clienteOptions, setClientesOptions] = useState([]);
    const [categoriaOptions, setCategoriaOptions] = useState([]);
    const [centroCustoOptions, setCentroCustoOptions] = useState([]);
    const [contasOptions, setContasOptions] = useState([]);

    const [selectedCliente, setSelectedCliente] = useState(null);

    const [selectedDataCompra, setSelectedDataCompra] =
        useState(dataLancamento);
    const [descricaoCompra, setDescricaoCompra] = useState(
        lancamento.descricao
    );
    const [valorCompra, setValorCompra] = useState(lancamento.valor_lancamento);

    const [hasRateio, setHasRateio] = useState(false);
    const [rateios, setRateios] = useState([
        {
            categoria: null,
            valor_total: 0,
            porcentagem: 0,
            centro_custo: null,
        },
    ]);

    const [selectedCategoria, setSelectedCategoria] = useState(
        categoria ? categoria : null
    );
    const [selectedCentroCusto, setSelectedCentroCusto] = useState(null);
    const [codigoReferencia, setCodigoReferencia] = useState("");

    const [repetirCompra, setRepetirCompra] = useState(false);
    const [configRepeticao, setConfigRepeticao] = useState(null);
    const [selectedCobrarSempre, setSelectedCobrarSempre] = useState(null);
    const [selectedPrimeiroVencimento, setSelectedPrimeiroVencimento] =
        useState(currentMonthDate);

    const [selectedParcelamento, setSelectedParcelamento] = useState("À vista");
    const [selectedFormaPagamento, setSelectedFormaPagamento] = useState(null);
    const [openParcelasModal, setOpenParcelasModal] = useState(false);
    const [parcelas, setParcelas] = useState([]);

    const [selectedDataVencimento, setSelectedDataVencimento] =
        useState(dataLancamento);
    const [selectedConta, setSelectedConta] = useState(conta);

    const [hasRecebido, setHasRecebido] = useState(false);

    const [observacoes, setObservacoes] = useState("");
    const [anexos, setAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);

    const [prevSelectedCliente, setPrevSelectedCliente] = useState(null);
    const [prevValorCompra, setPrevValorCompra] = useState(0);
    const [prevHasRateio, setPrevHasRateio] = useState(false);
    const [prevSelectedCategoria, setPrevSelectedCategoria] = useState(null);
    const [prevSelectedCentroCusto, setPrevSelectedCentroCusto] =
        useState(null);
    const [prevRepetirCompra, setPrevRepetirCompra] = useState(false);
    const [prevAnexos, setPrevAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);
    const [prevParcelamento, setPrevParcelamento] = useState(null);
    const [prevFormaPagamento, setPrevFormaPagamento] = useState(null);
    const [prevConta, setPrevConta] = useState(null);
    const [prevRecebido, setPrevRecebido] = useState(false);
    const [prevConfigRepeticao, setPrevConfigRepeticao] = useState(null);
    const [prevCobrarSempre, setPrevCobrarSempre] = useState(null);

    const [changesList, setChangesList] = useState([]);

    const temaEscuro = useSelector((state) => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(
        (state) => state.StoreReducer.estabelecimentoId
    );

    useHotkeys("ctrl+z", () => {
        const change = changesList.pop();

        switch (change) {
            case "clienteFornecedor":
                setSelectedCliente(prevSelectedCliente);
                break;
            case "valor":
                setValorCompra(prevValorCompra);
                break;
            case "categoria":
                setSelectedCategoria(prevSelectedCategoria);
                break;
            case "centroCusto":
                setSelectedCentroCusto(prevSelectedCentroCusto);
                break;
            case "hasRateio":
                setHasRateio(prevHasRateio);
                break;
            case "repetirCompra":
                setRepetirCompra(prevRepetirCompra);
                break;
            case "parcelamento":
                setSelectedParcelamento(prevParcelamento);
                break;
            case "anexos":
                setAnexos(prevAnexos);
                break;
            case "formaPagamento":
                setSelectedFormaPagamento(prevFormaPagamento);
                break;
            case "conta":
                setSelectedConta(prevConta);
                break;
            case "recebido":
                setHasRecebido(prevRecebido);
                break;
            case "configRepeticao":
                setConfigRepeticao(prevConfigRepeticao);
                break;
            case "cobrarSempre":
                setSelectedCobrarSempre(prevCobrarSempre);
                break;
            default:
                break;
        }
    });

    useEffect(() => {
        getInputsOptions();
    }, [estabelecimentoId]);

    useEffect(() => {
        if (selectedParcelamento) {
            handleParcelas();
        }
    }, [selectedParcelamento, valorCompra]);

    const postCreateReceita = (redirect = true) => {
        let formData = new FormData();

        formData.append("estabelecimento_id", estabelecimentoId);
        if (selectedCliente != null) {
            formData.append("cliente_id", selectedCliente.value);
        }
        formData.append(
            "data_compra",
            moment(selectedDataCompra).format("DD-MM-YYYY")
        );
        formData.append("descricao", descricaoCompra);
        formData.append("valor_total", valorCompra);
        formData.append("has_rateio", hasRateio);
        if (hasRateio) {
            formData.append("rateios", JSON.stringify(rateios));
        } else {
            formData.append("centro_custo_id", selectedCentroCusto?.value || 0);
        }
        if (selectedCategoria && rateios.length <= 1) {
            formData.append("categoria_id", selectedCategoria.value);
        }
        formData.append("codigo_referencia", codigoReferencia);
        formData.append("repetir_compra", repetirCompra);
        if (repetirCompra) {
            formData.append("repetir_compra", repetirCompra);
            formData.append("config_repeticao", configRepeticao.value);
            formData.append("cobrar_sempre", selectedCobrarSempre.value);
            formData.append(
                "primeiro_vencimento",
                moment(selectedPrimeiroVencimento).format("DD-MM-YYYY")
            );
        } else {
            formData.append("parcelamento", selectedParcelamento);
            formData.append("parcelas", JSON.stringify(parcelas));
            formData.append(
                "data_vencimento",
                moment(selectedDataVencimento).format("DD-MM-YYYY")
            );
        }
        formData.append("forma_pagamento", selectedFormaPagamento);
        if (selectedConta != null) {
            formData.append("conta_recebimento", selectedConta.value);
        }
        formData.append("recebido", hasRecebido);
        formData.append("observacoes", observacoes);
        if (anexos[0].arquivo != null) {
            formData.append("quantidade_anexo", anexos.length);
            anexos.forEach((anexo, index) => {
                formData.append(
                    `anexos[${index}][forma_anexo]`,
                    anexo.forma_anexo
                );
                formData.append(`anexos[${index}][arquivo]`, anexo.arquivo);
                formData.append(
                    `anexos[${index}][tipo_anexo]`,
                    anexo.tipo_anexo
                );
                formData.append(`anexos[${index}][descricao]`, anexo.descricao);
            });
        }
        formData.append("tipo_movimentacao", componente);
        api.PostGenerateMovimentacao(formData)
            .then((response) => {
                dispatch(
                    showSnackMessage({
                        message: `${componente} gerado(a) com sucesso!`,
                        severity: "success",
                    })
                );
                handleAddMovimentacao(indexConciliacao, lancamento.id, {
                    id: response.data.ids[0],
                    apelido_conta: selectedConta.label,
                    codigo_referencia: codigoReferencia
                        ? codigoReferencia
                        : "Sem NFE",
                    data_pagamento:
                        moment(selectedDataCompra).format("DD/MM/YYYY"),
                    desconto: 0,
                    descricao: descricaoCompra,
                    nome_categoria: selectedCategoria ? [selectedCategoria.label] : [],
                    nome_fornecedor: selectedCliente?.label,
                    tipo_movimentacao: componente,
                    valor_vxmf: valorCompra,
                    juros_multa: 0,
                });
                if (redirect) {
                    setOpen(false);
                }
            })
            .catch(() => {
                dispatch(
                    showSnackMessage({
                        message: "Algo deu errado! Tente novamente mais tarde",
                        severity: "error",
                    })
                );
            });
    };

    const getInputsOptions = () => {
        if (estabelecimentoId !== 0) {
            let dataRequest = {
                estabelecimento_id: estabelecimentoId,
                papel_pessoa:
                    componente === "Receita" ? "Cliente" : "Fornecedor",
            };
            api.GetFornecedores(dataRequest).then((response) => {
                setClientesOptions(response.data);
            });
            api.GetCategorias({ ...dataRequest, tipo: componente }).then(
                (response) => {
                    setCategoriaOptions(response.data);
                }
            );
            api.GetCentroCustos(dataRequest).then((response) => {
                setCentroCustoOptions(response.data);
            });
            api.RetrieveContas(dataRequest).then((response) => {
                setContasOptions(response.data);
            });
        }
    };

    const handleParcelas = (
        skipIndex = false,
        skipRow = {},
        alteredField = ""
    ) => {
        let _parcelas = [];
        let valorTotal = 0;
        let percentTotal = 0;
        let _totalValue = valorCompra;
        let parcelasQuantidade =
            selectedParcelamento === "À vista" ? 1 : selectedParcelamento;
        let parcelamento =
            selectedParcelamento === "À vista" ? 1 : selectedParcelamento;
        for (let i = 0; i < parcelamento; i++) {
            if (
                (skipIndex || typeof skipIndex === "number") &&
                i === skipIndex
            ) {
                if (alteredField === "valor") {
                    if (skipRow.valor >= valorCompra || skipRow.valor === 0) {
                        skipRow.error = true;
                        return;
                    }
                    _totalValue = valorCompra - skipRow.valor;
                    skipRow.percentual = parseFloat(
                        formatterPercentage(
                            (skipRow.valor * 100) / valorCompra,
                            2
                        )
                    );
                    percentTotal += skipRow.percentual;
                    skipRow.error = false;
                } else {
                    if (skipRow.percentual >= 100 || skipRow.percentual === 0) {
                        skipRow.error = true;
                        return;
                    }
                    percentTotal += parseFloat(skipRow.percentual);
                    skipRow.valor = parseFloat(
                        formatterThousand(
                            valorCompra * (skipRow.percentual / 100),
                            true
                        )
                    );
                    _totalValue -= skipRow.valor;
                    skipRow.error = false;
                }
                parcelasQuantidade = parcelamento - 1;
                _parcelas.push(skipRow);
                continue;
            }
            let valorParcela = 0;
            let percentParcela = 0;
            if (i === parcelamento - 1) {
                valorParcela = formatterThousand(
                    _totalValue - valorTotal,
                    true
                );
                percentParcela = formatterPercentage(100 - percentTotal, 2);
            } else {
                valorParcela = formatterThousand(
                    _totalValue / parseFloat(parcelasQuantidade),
                    true
                );
                percentParcela = formatterPercentage(
                    (valorParcela * 100) / valorCompra,
                    2
                );
            }
            let parcelaData = moment(selectedDataVencimento || moment(getCurrentDate()).toDate()).add(i, "months");
            _parcelas.push({
                valor: parseFloat(valorParcela),
                percentual: parseFloat(percentParcela),
                data_vencimento: moment(parcelaData.toDate()).format("DD-MM-YYYY"),
                descricao: "",
            });
            valorTotal += parseFloat(valorParcela);
            percentTotal += parseFloat(percentParcela);
        }
        setParcelas(_parcelas);
    };

    const cleanReceitaInputs = () => {
        setSelectedCliente(null);
        setSelectedDataCompra(currentMonthDate);
        setDescricaoCompra("");
        setValorCompra(0);
        setHasRateio(false);
        setRateios([
            {
                categoria: null,
                valor_total: 0,
                porcentagem: 0,
                centro_custo: null,
            },
        ]);
        setSelectedCategoria(null);
        setSelectedCentroCusto(null);
        setCodigoReferencia("");
        setRepetirCompra(false);
        setConfigRepeticao(null);
        setSelectedCobrarSempre(null);
        setSelectedPrimeiroVencimento(currentMonthDate);
        setSelectedParcelamento(null);
        setSelectedFormaPagamento(null);
        setOpenParcelasModal(false);
        setParcelas([]);
        setSelectedDataVencimento(currentMonthDate);
        setSelectedConta(null);
        setHasRecebido(false);
        setObservacoes("");
        setAnexos([
            {
                forma_anexo: "Arquivo",
                arquivo: null,
                tipo_anexo: "Contrato",
                descricao: "",
            },
        ]);
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={() => setOpen(false)}
            TransitionComponent={Transition}
            PaperProps={{
                style: {
                    backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_BODY,
                },
            }}
        >
            <AppBar
                sx={{
                    backgroundColor: temaEscuro
                        ? PAPER_PADDING_THEME
                        : LINE_TABLE,
                    backgroundImage: temaEscuro && "none",
                    borderBottom: temaEscuro
                        ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                        : `1px solid ${BORDER_PAPER}`,
                    padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                    position: "relative",
                }}
            >
                <Toolbar>
                    <Typography sx={{ color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX, fontSize: TITLE_SIZE, fontWeight: "bold", lineHeight: 1, flex: 1 }}>
                        {componente === "Receita"
                            ? "Nova receita"
                            : "Nova despesa"}
                    </Typography>
                    <IconButton
                        autoFocus
                        color="inherit"
                        onClick={() => setOpen(false)}
                        aria-label="close"
                        sx={{
                            color: !temaEscuro && BLACK_LABEL_UX
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div style={{ overflow: "auto", maxHeight: "100vh" }}>
                <CreateOptionModal
                    openModal={openCreateModal}
                    setOpenModal={setOpenCreateModal}
                    option={optionCreateModal}
                    getInputsOptions={getInputsOptions}
                />
                <RateioCentroCustoModal
                    openModal={openRateioCentroCustoModal}
                    setOpenModal={setOpenRateioCentroCustoModal}
                    categoria={categoriaRateioCentroCusto}
                    valorCategoria={valorCategoriaRateioCentroCusto}
                    indexRateioCentroCusto={indexRateioCentroCusto}
                    rateios={rateios}
                    setRateios={setRateios}
                    centroCustoOptions={centroCustoOptions}
                />
                <ParcelasModal
                    openModal={openParcelasModal}
                    setOpenModal={setOpenParcelasModal}
                    formaPagamento={selectedFormaPagamento}
                    valorPagamento={valorCompra}
                    condicaoPagamento={selectedParcelamento}
                    parcelas={parcelas}
                    setParcelas={setParcelas}
                    handleParcelas={handleParcelas}
                />
                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro
                            ? PAPER_PADDING_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        border: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        margin: `20px ${
                            isLargeScreen ? "120px" : "20px"
                        } 20px ${isLargeScreen ? "120px" : "20px"}`,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <span>
                                <p className="title-container-p">
                                    Informações da compra
                                </p>
                            </span>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider
                                sx={{
                                    backgroundColor: temaEscuro
                                        ? BORDER_TABLE
                                        : BORDER_PAPER,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item sx={{ mr: "2rem" }}>
                                    <FilterSingleOption
                                        filterOptions={clienteOptions}
                                        selectedOption={selectedCliente}
                                        setSelectedOption={(e) => {
                                            setChangesList([
                                                ...changesList,
                                                "clienteFornecedor",
                                            ]);
                                            setPrevSelectedCliente(
                                                selectedCliente
                                            );
                                            setSelectedCliente(e);
                                        }}
                                        placeholder={
                                            componente === "Receita"
                                                ? "Cliente"
                                                : "Fornecedor"
                                        }
                                        width={"350px"}
                                        canCreate
                                        setOpenCreateModal={setOpenCreateModal}
                                        setOptionCreateModal={
                                            setOptionCreateModal
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <FilterDayMonthYear
                                        disableMinDate
                                        style={{
                                            backgroundColor:
                                                temaEscuro && BLUE_THEME,
                                        }}
                                        handleSubmit={handleDayMonthYear}
                                        value={selectedDataCompra}
                                        setState={setSelectedDataCompra}
                                        size={"small"}
                                        width={"250px"}
                                        iconPosition={"end"}
                                        label={"Data da compra"}
                                    />
                                </Grid>
                                <Grid item>
                                    <InputSearch
                                        functionProps={() => {}}
                                        setSearchValue={setDescricaoCompra}
                                        searchValue={descricaoCompra}
                                        label={"Descrição *"}
                                        width={"575px"}
                                    />
                                </Grid>
                                <Grid item>
                                    <InputCurrency
                                        title={"Valor (R$)"}
                                        valor={valorCompra}
                                        setValor={setValorCompra}
                                        changesList={changesList}
                                        setChangesList={setChangesList}
                                        setPrevValor={setPrevValorCompra}
                                    />
                                </Grid>
                                <Grid item>
                                    <Checkbox
                                        checked={hasRateio}
                                        icon={<CircleOutlinedIcon />}
                                        checkedIcon={<CircleIcon />}
                                        onChange={(event) => {
                                            setChangesList([
                                                ...changesList,
                                                "hasRateio",
                                            ]);
                                            setPrevHasRateio(hasRateio);
                                            setHasRateio(event.target.checked);
                                        }}
                                    />
                                    <a
                                        style={{
                                            ...styleText,
                                            color: temaEscuro
                                                ? WHITE_THEME_BLACK
                                                : GRAY_LABEL_UX,
                                        }}
                                    >
                                        Habilitar rateio
                                    </a>
                                </Grid>
                                <Grid item sx={{ mr: "2rem" }}>
                                    <FilterSingleOption
                                        sx={{ borderRadius: 0 }}
                                        filterOptions={categoriaOptions}
                                        selectedOption={selectedCategoria}
                                        setSelectedOption={(e) => {
                                            setChangesList([
                                                ...changesList,
                                                "categoria",
                                            ]);
                                            setPrevSelectedCategoria(
                                                selectedCategoria
                                            );
                                            setSelectedCategoria(e);
                                        }}
                                        placeholder={"Categoria *"}
                                        width={"350px"}
                                        disabled={hasRateio}
                                        canCreate
                                        setOpenCreateModal={setOpenCreateModal}
                                        setOptionCreateModal={
                                            setOptionCreateModal
                                        }
                                    />
                                </Grid>
                                <Grid item sx={{ mr: "2rem" }}>
                                    <FilterSingleOption
                                        filterOptions={centroCustoOptions}
                                        selectedOption={selectedCentroCusto}
                                        setSelectedOption={(e) => {
                                            setChangesList([
                                                ...changesList,
                                                "centroCusto",
                                            ]);
                                            setPrevSelectedCentroCusto(
                                                selectedCentroCusto
                                            );
                                            setSelectedCentroCusto(e);
                                        }}
                                        placeholder={"Centro de custo"}
                                        width={"350px"}
                                        canCreate
                                        setOpenCreateModal={setOpenCreateModal}
                                        setOptionCreateModal={
                                            setOptionCreateModal
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <InputSearch
                                        functionProps={() => {}}
                                        setSearchValue={setCodigoReferencia}
                                        searchValue={codigoReferencia}
                                        className="input-text"
                                        label={"Código de referência"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {hasRateio && (
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <a>Informar categoria e centro de custo</a>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        border: 0,
                                        borderBottom: temaEscuro
                                            ? `1px solid ${BORDER_TABLE}`
                                            : `1px solid ${WHITE_ESCRITA_THEME}`,
                                        mb: 2.5,
                                        ml: 2,
                                    }}
                                />
                                <RateiosReceita
                                    rateios={rateios}
                                    setRateios={setRateios}
                                    categoriaOptions={categoriaOptions}
                                    centroCustoOptions={centroCustoOptions}
                                    valorCompra={valorCompra}
                                    setOpenCreateModal={setOpenCreateModal}
                                    setOptionCreateModal={setOptionCreateModal}
                                    setOpenRateioCentroCustoModal={
                                        setOpenRateioCentroCustoModal
                                    }
                                    setCategoriaRateioCentroCusto={
                                        setCategoriaRateioCentroCusto
                                    }
                                    setValorCategoriaRateioCentroCusto={
                                        setValorCategoriaRateioCentroCusto
                                    }
                                    setIndexRateioCentroCusto={
                                        setIndexRateioCentroCusto
                                    }
                                />
                            </React.Fragment>
                        )}
                        <Grid item xs={repetirCompra ? 2 : 12}>
                            <a
                                style={{
                                    ...styleText,
                                    color: temaEscuro
                                        ? WHITE_THEME_BLACK
                                        : GRAY_LABEL_UX,
                                }}
                            >
                                Repetir lançamento?
                            </a>
                            <Checkbox
                                checked={repetirCompra}
                                icon={<CircleOutlinedIcon />}
                                checkedIcon={<CircleIcon />}
                                onChange={(event) => {
                                    setChangesList([
                                        ...changesList,
                                        "repetirCompra",
                                    ]);
                                    setPrevRepetirCompra(repetirCompra);
                                    setRepetirCompra(event.target.checked);
                                }}
                            />
                        </Grid>
                        {repetirCompra && (
                            <Grid item xs={10}>
                                <FilterSingleOption
                                    filterOptions={[
                                        "Diariamente",
                                        "Semanalmente",
                                        "Mensalmente",
                                        "Anualmente",
                                    ]}
                                    selectedOption={configRepeticao}
                                    setSelectedOption={(e) => {
                                        setChangesList([
                                            ...changesList,
                                            "configRepeticao",
                                        ]);
                                        setPrevConfigRepeticao(configRepeticao);
                                        setConfigRepeticao(e);
                                    }}
                                    placeholder={"Configuração de repetição"}
                                    width={"300px"}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} sx={{ marginTop: 2 }}>
                            <span>
                                <p className="title-container-p">
                                    Condição de pagamento
                                </p>
                            </span>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider
                                sx={{
                                    backgroundColor: temaEscuro
                                        ? BORDER_TABLE
                                        : BORDER_PAPER,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                {repetirCompra ? (
                                    <React.Fragment>
                                        <Grid item>
                                            <FilterSingleOption
                                                filterOptions={
                                                    cobrarSempreOptionsMes
                                                }
                                                selectedOption={
                                                    selectedCobrarSempre
                                                }
                                                setSelectedOption={(e) => {
                                                    setChangesList([
                                                        ...changesList,
                                                        "cobrarSempre",
                                                    ]);
                                                    setPrevCobrarSempre(
                                                        selectedCobrarSempre
                                                    );
                                                    setSelectedCobrarSempre(e);
                                                }}
                                                placeholder={"Cobrar sempre no"}
                                                width={"200px"}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FilterDayMonthYear
                                                disableMinDate
                                                style={{
                                                    backgroundColor:
                                                        temaEscuro &&
                                                        BLUE_THEME,
                                                }}
                                                handleSubmit={
                                                    handleDayMonthYear
                                                }
                                                value={
                                                    selectedPrimeiroVencimento
                                                }
                                                setState={
                                                    setSelectedPrimeiroVencimento
                                                }
                                                size={"small"}
                                                width={"250px"}
                                                iconPosition={"end"}
                                                label={"Primeiro vencimento"}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Grid item>
                                            <FilterSingleOption
                                                filterOptions={[
                                                    "À vista",
                                                    "1",
                                                    "2",
                                                    "3",
                                                    "4",
                                                    "5",
                                                    "6",
                                                    "7",
                                                    "8",
                                                    "9",
                                                    "10",
                                                ]}
                                                selectedOption={
                                                    selectedParcelamento
                                                }
                                                setSelectedOption={(e) => {
                                                    setChangesList([
                                                        ...changesList,
                                                        "parcelamento",
                                                    ]);
                                                    setPrevParcelamento(
                                                        selectedParcelamento
                                                    );
                                                    setSelectedParcelamento(e);
                                                }}
                                                placeholder={"Parcelamento *"}
                                                width={"200px"}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FilterDayMonthYear
                                                disableMinDate
                                                style={{
                                                    backgroundColor:
                                                        temaEscuro &&
                                                        BLUE_THEME,
                                                }}
                                                handleSubmit={
                                                    handleDayMonthYear
                                                }
                                                value={selectedDataVencimento}
                                                setState={
                                                    setSelectedDataVencimento
                                                }
                                                size={"small"}
                                                width={"250px"}
                                                iconPosition={"end"}
                                                label={"Vencimento"}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                )}
                                <Grid item>
                                    <FilterSingleOption
                                        filterOptions={formaPagamentoOptions}
                                        selectedOption={selectedFormaPagamento}
                                        setSelectedOption={(e) => {
                                            setChangesList([
                                                ...changesList,
                                                "formaPagamento",
                                            ]);
                                            setPrevFormaPagamento(
                                                selectedFormaPagamento
                                            );
                                            setSelectedFormaPagamento(e);
                                        }}
                                        placeholder={"Forma de pagamento"}
                                    />
                                </Grid>
                                <Grid item>
                                    <FilterSingleOption
                                        filterOptions={contasOptions}
                                        selectedOption={selectedConta}
                                        setSelectedOption={(e) => {
                                            setChangesList([
                                                ...changesList,
                                                "conta",
                                            ]);
                                            setPrevConta(selectedConta);
                                            setSelectedConta(e);
                                        }}
                                        placeholder={
                                            hasRecebido
                                                ? componente === "Receita"
                                                    ? "Conta de recebimento *"
                                                    : "Conta de pagamento *"
                                                : componente === "Receita"
                                                ? "Conta de recebimento"
                                                : "Conta de pagamento"
                                        }
                                    />
                                </Grid>
                                {!repetirCompra && (
                                    <Grid item>
                                        <Checkbox
                                            checked={hasRecebido}
                                            onChange={(event) => {
                                                setChangesList([
                                                    ...changesList,
                                                    "recebido",
                                                ]);
                                                setPrevRecebido(hasRecebido);
                                                setHasRecebido(
                                                    event.target.checked
                                                );
                                            }}
                                        />
                                        <a
                                            style={{
                                                ...styleText,
                                                color: temaEscuro
                                                    ? WHITE_THEME_BLACK
                                                    : GRAY_LABEL_UX,
                                                fontSize: "12px",
                                            }}
                                        >
                                            {componente === "Receita"
                                                ? "Recebido"
                                                : "Pago"}
                                        </a>
                                    </Grid>
                                )}
                                {selectedParcelamento !== "À vista" && (
                                    <Grid item xs={12}>
                                        <DefaultButton
                                            title={"Editar parcelas"}
                                            onClick={() =>
                                                setOpenParcelasModal(true)
                                            }
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sx={{
                                backgroundColor: "transparent",
                                marginBottom: "15px",
                                marginTop: 3,
                            }}
                        >
                            <Tabs
                                value={selectedTab}
                                onChange={(e, value) => setSelectedTab(value)}
                                TabIndicatorProps={{
                                    style: { backgroundColor: "transparent" },
                                }}
                                className="buttonTabs"
                            >
                                <Tab
                                    value={"observacoes"}
                                    label="Observações"
                                    sx={{
                                        borderRadius: "20px",
                                        color: temaEscuro
                                            ? GRAY_LABEL_UX_THEME
                                            : BLACK_TABLE,
                                        backgroundColor:
                                            selectedTab === "observacoes"
                                                ? YELLOW_BG_UX
                                                : "transparent",
                                        "&.Mui-selected": {
                                            // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                            color: BLACK_LABEL_UX,
                                        },
                                    }}
                                />
                                <Tab
                                    value={"anexo"}
                                    label="Anexo"
                                    sx={{
                                        borderRadius: "20px",
                                        color: temaEscuro
                                            ? GRAY_LABEL_UX_THEME
                                            : BLACK_TABLE,
                                        backgroundColor:
                                            selectedTab === "anexo"
                                                ? YELLOW_BG_UX
                                                : "transparent",
                                        "&.Mui-selected": {
                                            // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                            color: BLACK_LABEL_UX,
                                        },
                                    }}
                                />
                            </Tabs>
                        </Grid>
                        {selectedTab === "observacoes" ? (
                            <Grid item xs={12}>
                                <TextField
                                    label={"Observações"}
                                    id="input-observacoes-textfield"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    maxRows={6}
                                    value={observacoes}
                                    onChange={(event) => {
                                        setObservacoes(event.target.value);
                                    }}
                                    sx={{
                                        backgroundColor: temaEscuro
                                            ? BLUE_THEME
                                            : GRAY_BG_UX,
                                    }}
                                />
                            </Grid>
                        ) : (
                            <Anexos
                                anexos={anexos}
                                setAnexos={setAnexos}
                                setPrevAnexos={setPrevAnexos}
                                setChangesList={setChangesList}
                                changesList={changesList}
                            />
                        )}
                    </Grid>
                </Paper>
            </div>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: temaEscuro
                        ? PAPER_PADDING_THEME
                        : LINE_TABLE,
                    backgroundImage: temaEscuro && "none",
                    borderTop: temaEscuro
                        ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                        : `1px solid ${BORDER_PAPER}`,
                    top: "auto",
                    bottom: 0,
                    padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                }}
            >
                <Toolbar
                    sx={{ display: "flex", justifyContent: "space-between" }}
                >
                    <DefaultButton
                        title={"Voltar"}
                        onClick={() => setOpen(false)}
                    />

                    <Grid sx={{ display: "flex" }}>
                        <DefaultButton
                            title={"Salvar"}
                            onClick={() => postCreateReceita()}
                            borderRadius={{
                                borderRadius: "5px 0 0 5px",
                            }}
                            
                            variant="yellow"
                        />
                        <SaveOptions
                            action={postCreateReceita}
                            cleanStates={cleanReceitaInputs}
                        />
                    </Grid>
                </Toolbar>
            </AppBar>
        </Dialog>
    );
};

export default CompletarMovimentacao;
