import React, { useState } from "react";
import { useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
    BLACK_TABLE_THEME,
    YELLOW_BG_UX,
    DISABLED_BUTTON
} from "../../shared/utils";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";


export default function SaveOptions({ action, cleanStates, disabled }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        if (!disabled) {
            setAnchorEl(event.currentTarget);
        }
    };
    
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const saveAndClean = () => {
        cleanStates();
        action(false);
        handleCloseMenu();
    };
    
    const saveAndClone = () => {
        action(false);
        handleCloseMenu();
    };

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    
    return (
        <div>
            <IconButton
                data-testid="save-options-menu"
                disableRipple
                disableFocusRipple
                disableElevation
                variant="contained"
                sx={{
                    backgroundColor: disabled ? (temaEscuro ? "#FFFFFF1F" : DISABLED_BUTTON) : YELLOW_BG_UX,
                    display: "flex",
                    borderRadius: "0 5px 5px 0",
                    ml: .1,
                }}
                onClick={handleClick}
            >
                <KeyboardArrowDownIcon sx={{color: BLACK_TABLE_THEME}} />
            </IconButton>
                <Menu
                    sx={{
                        "& .css-kc02vp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper" : {
                            background: temaEscuro && BLACK_TABLE_THEME
                        }
                    }}
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <MenuItem
                        data-testid="save-and-clone-option"
                        onClick={() => saveAndClone()}
                    >
                        Salvar e clonar
                    </MenuItem>
                    <MenuItem
                        data-testid="save-and-clean-option"
                        onClick={() => saveAndClean()}
                    >
                        Salvar e nova em branco
                    </MenuItem>
                </Menu>
        </div>
    );
}