import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {
    styleText,
    WHITE_ESCRITA_THEME,
    GRAY_LABEL_UX,
    BORDER_TABLE,
    LINE_TABLE
} from "../../../../shared/utils";
import { useSelector, useDispatch } from "react-redux";
import LoadingWithPercentage from "../../../../components/Loading/LoadingWithPercentage";
import {showSnackMessage} from "../../../../actions/SnackActions";

const StepThreeContaCorrente = (props) => {

    const {inputBanco, setProgressComplete, progressComplete, createConta, setRenderSuccess, renderSuccess} = props;

    const dispatch = useDispatch();

    const [messageError, setMessageError] = useState("");

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    useEffect(() => {
        createConta("Conta Corrente")
            .then(response => {
                if (response.status === 201) {
                    setRenderSuccess(true);
                }
            })
            .catch(error => {
                if (error.response.status === 403) {
                    dispatch(showSnackMessage({ message: error.response.data, severity: "error" }));
                    setMessageError(error.response.data);
                } else {
                    dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
                }
            });
    }, []);

    return (
        <React.Fragment>
            {
                !progressComplete ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, marginBottom: 2}}>
                            <h3 style={{marginTop: 0, color: temaEscuro ? LINE_TABLE : GRAY_LABEL_UX, fontWeight: 700}}>Configurando saldo inicial da conta</h3>
                        </Grid>
                        <Grid item xs={12} sx={{marginTop: 1}}>
                            <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 600}}>O VXMF está registrando suas informações de forma segura no sistema</span>
                        </Grid>
                        <Grid item xs={12} sx={{marginTop: 8, display: "flex", alignItems: "center", justifyContent: "center"}}>  
                            <LoadingWithPercentage percentage={25} size="120px" thickness={8} setProgressComplete={setProgressComplete} />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        {
                            renderSuccess ? (
                                <React.Fragment>
                                    <Grid item xs={12} sx={{borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, marginBottom: 2}}>
                                        <h3 style={{marginTop: 0, color: temaEscuro ? LINE_TABLE : GRAY_LABEL_UX, fontWeight: 700, textAlign: "center"}}>Integração com {inputBanco.nome} concluída</h3>
                                    </Grid>
                                    <Grid item xs={12} sx={{marginTop: 1}}>
                                        <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 600}}>O cadastro da sua conta foi concluído e habilitamos a integração. Clique em Entrar na conta para verificar seus lançamentos. </span>
                                    </Grid>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Grid item xs={12} sx={{borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, marginBottom: 2}}>
                                        {
                                            messageError !== "" ? (
                                                <h3 style={{marginTop: 0, color: temaEscuro ? LINE_TABLE : GRAY_LABEL_UX, fontWeight: 700, textAlign: "center"}}>{messageError}</h3>
                                            ) : (
                                                <h3 style={{marginTop: 0, color: temaEscuro ? LINE_TABLE : GRAY_LABEL_UX, fontWeight: 700, textAlign: "center"}}>Algo deu errado, por favor tente novamente</h3>
                                            )
                                        }
                                    </Grid>
                                    <Grid item xs={12} sx={{marginTop: 1}}>
                                        <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 600}}>O cadastro da sua conta foi malsucedido!</span>
                                    </Grid>
                                </React.Fragment>
                            )
                        }
                    </Grid>
                )
            }
        </React.Fragment>
    );
};

export default React.memo(StepThreeContaCorrente);