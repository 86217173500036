import React  from "react";
import { useRouteError } from "react-router-dom";
import Grid from "@mui/material/Grid";
import {GRAY_LABEL_UX, PAPER_THEME, RED_TABLE_THEME} from "../../shared/utils";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DefaultButton from "../../components/Buttons/DefaultButton";
import {useSelector} from "react-redux";
import * as Sentry from "@sentry/react";

const Error = () => {
	const error = useRouteError();
	// eslint-disable-next-line no-console
	console.error(error);
	// eslint-disable-next-line no-undef
	if(process.env.NODE_ENV === "production"){
		Sentry.captureException(error);
	}
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

	return(
		<div style={{...styles.center, "background-color": temaEscuro? PAPER_THEME: "#ffffff"}}>
			<React.Fragment>
				<Grid container rowSpacing={5} sx={{alignItems: "center", marginBottom: "60px"}}>
					<Grid item xs={12} sx={{paddingTop: "0 !important"}}>
						<ErrorOutlineIcon sx={{...styles.icon, color: temaEscuro ? "#ffffff" : "#bdbdbd"}}/>
					</Grid>
					<Grid item xs={12}>
						<span style={{...styles.text, color: temaEscuro ? RED_TABLE_THEME : GRAY_LABEL_UX}}>Algo deu errado! Já estamos trabalhando para resolver o problema</span>
					</Grid>
					<Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
						<DefaultButton isRedirect path="/" title="Voltar"/>
					</Grid>
				</Grid>
			</React.Fragment>
		</div>
	);
};

// Create styles
const styles = {
	center: {
		textAlign: "center",
		margin: "auto",
		// margin is 30px
		height: "100vh",
		display: "flex",
		alignItems: "center"
	},
	text: {
		color: GRAY_LABEL_UX,
		fontWeight: 600,
		fontSize: "24px",
		lineHeight: "32px"
	},
	icon: {
		fontSize: "250px"
	}
};

export default Error;