import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import DefaultButton from "../Buttons/DefaultButton";
import {
    BLUE_THEME,
    BORDER_TABLE,
    formatterCurrency,
    WHITE_ESCRITA_THEME,
    GRAY_LABEL_UX_THEME,
    YELLOW_BG_UX,
    GRAY_BG_UX,
    COLOR_LABEL_INPUT,
    BORDER_BUTTON,
    textCompleteStyleBlack,
    textCompleteStyle,
    GREEN_SUCCESS_UX,
    RED_ERROR_UX,
} from "../../shared/utils";
import VXMF from "../../containers/Contas/LogoBancos/VXMF.png";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";


const BlocoLancamentoVXMF = ({
    conciliacao,
    idx,
    categorias,
    contas,
    setSelectedLancamento,
    setOpenModalBuscarLanc,
    selectedCategoria,
    setSelectedCategoria,
    selectedConta,
    setSelectedConta,
    selectedMovType,
    setSelectedMovType,
    handleCompletarInfo,
    handleUnlinkMovimentacao,
}) => {
    const nav = useNavigate();

    const [descricao, setDescricao] = useState(conciliacao.extrato.descricao);

    const temaEscuro = useSelector((state) => state.AuthReducer.temaEscuro);

    const handleEditMovimentacao = (movimentacao) => {
        const routeMap = {
            Transferência: "/editar_transferencia",
            Despesa: "/editar_movimentacao",
            Receita: "/editar_movimentacao",
        };

        const route = routeMap[movimentacao.tipo_movimentacao];

        if (route) {
            nav(route, { state: { id_movimentacao: movimentacao.id } });
        }
    };

    const handleSearchLancamentos = () => {
      setSelectedLancamento(conciliacao.extrato);
      setOpenModalBuscarLanc(true);
    };

    return (
        <Grid container spacing={2} data-testid="bloco-vxmf">
            {idx === 0 && (
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={2}
                        sx={{ display: "flex", justifyContent: "left" }}
                    >
                        <Grid item>
                            <img src={VXMF} />
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item xs={12}>
                                    <a
                                        style={{
                                            fontSize: "18px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Lançamentos do VXMF
                                    </a>
                                </Grid>
                                <Grid item xs={12}>
                                    <a style={{ fontSize: "12px" }}>
                                        Voxel - Menu Financeiro
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
            >
                <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                >
                    {conciliacao.movimentacao === null ? (
                        <Grid
                            container
                            sx={{
                                backgroundColor: temaEscuro
                                    ? BLUE_THEME
                                    : GRAY_LABEL_UX_THEME,
                                backgroundImage: temaEscuro && "none",
                                p: 3,
                                width: "100%",
                                height: 270.5,
                            }}
                        >
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={8}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "begin",
                                            alignItems: "center",
                                            marginBottom: 2,
                                        }}
                                    >
                                        {selectedMovType === "movimentacao" ? (
                                            <DefaultButton
                                                title={"Novo lançamento"}
                                                size={"small"}
                                                borderRadius={{
                                                    borderRadius: "5px 0 0 5px",
                                                }}
                                                testId="btn-novo-lancamento-yellow"
                                                
                                                variant="yellow"
                                            />
                                        ) : (
                                            <DefaultButton
                                                title={"Novo lançamento"}
                                                size={"small"}
                                                borderRadius={{
                                                    borderRadius: "5px 0 0 5px",
                                                }}
                                                onClick={() =>
                                                    setSelectedMovType(
                                                        "movimentacao"
                                                    )
                                                }
                                                testId="btn-novo-lancamento-gray"
                                            />
                                        )}
                                        {selectedMovType === "transferencia" ? (
                                            <DefaultButton
                                                title={"Nova transferência"}
                                                size={"small"}
                                                borderRadius={{
                                                    borderRadius: "0 5px 5px 0",
                                                }}
                                                testId="btn-nova-transferencia-yellow"
                                                
                                                variant="yellow"
                                            />
                                        ) : (
                                            <DefaultButton
                                                title={"Nova transferência"}
                                                size={"small"}
                                                borderRadius={{
                                                    borderRadius: "0 5px 5px 0",
                                                }}
                                                onClick={() =>
                                                    setSelectedMovType(
                                                        "transferencia"
                                                    )
                                                }
                                                testId="btn-nova-transferencia-gray"
                                            />
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "end",
                                            alignItems: "center",
                                            marginBottom: 2,
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            variant="text"
                                            startIcon={<SearchIcon />}
                                            style={{
                                                backgroundColor: "transparent",
                                                color: YELLOW_BG_UX,
                                                fontWeight: 600,
                                                boxShadow: "none",
                                            }}
                                            onClick={handleSearchLancamentos}
                                            data-testid="btn-buscar-lancamentos"
                                        >
                                            {"Buscar lançamento"}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label={"Descrição"}
                                            id="input-observacoes-textfield"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                            maxRows={6}
                                            value={descricao}
                                            onChange={(event) => {
                                                setDescricao(
                                                    event.target.value
                                                );
                                            }}
                                            sx={{
                                                backgroundColor: temaEscuro
                                                    ? BLUE_THEME
                                                    : GRAY_BG_UX,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {selectedMovType === "movimentacao" ? (
                                            <Autocomplete
                                                size={"small"}
                                                data-testid={"categoria-movimentacao"}
                                                options={categorias}
                                                slotProps={{
                                                    paper: {
                                                        style: {
                                                            backgroundColor:
                                                                temaEscuro
                                                                    ? BLUE_THEME
                                                                    : GRAY_BG_UX,
                                                            fontFamily:
                                                                "Inter, sans-serif",
                                                            color: temaEscuro
                                                                ? "#FFF"
                                                                : "#4A5568",
                                                            fontWeight: "500",
                                                        },
                                                    },
                                                }}
                                                fullWidth
                                                value={selectedCategoria}
                                                onChange={(event, newValue) =>
                                                    setSelectedCategoria(
                                                        newValue
                                                    )
                                                }
                                                renderOption={(
                                                    props,
                                                    option
                                                ) => (
                                                    <li
                                                        {...props}
                                                        key={
                                                            option?.value ??
                                                            option
                                                        }
                                                    >
                                                        {option?.label ??
                                                            option}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        sx={{
                                                            ...(temaEscuro
                                                                ? textCompleteStyleBlack
                                                                : textCompleteStyle),
                                                            "& label": {
                                                                color: temaEscuro
                                                                    ? COLOR_LABEL_INPUT
                                                                    : BORDER_BUTTON,
                                                            },
                                                        }}
                                                        label={"Categoria *"}
                                                        InputLabelProps={{
                                                            style: {
                                                                color:
                                                                    temaEscuro &&
                                                                    params
                                                                        .inputProps
                                                                        .value ===
                                                                        "" &&
                                                                    BORDER_BUTTON,
                                                            },
                                                        }}
                                                        placeholder={
                                                            "Categoria *"
                                                        }
                                                    />
                                                )}
                                            />
                                        ) : (
                                            <Autocomplete
                                                size={"small"}
                                                data-testid={"contas-transferencia"}
                                                options={contas}
                                                slotProps={{
                                                    paper: {
                                                        style: {
                                                            backgroundColor:
                                                                temaEscuro
                                                                    ? BLUE_THEME
                                                                    : GRAY_BG_UX,
                                                            fontFamily:
                                                                "Inter, sans-serif",
                                                            color: temaEscuro
                                                                ? "#FFF"
                                                                : "#4A5568",
                                                            fontWeight: "500",
                                                        },
                                                    },
                                                }}
                                                fullWidth
                                                value={selectedConta}
                                                onChange={(event, newValue) =>
                                                    setSelectedConta(newValue)
                                                }
                                                renderOption={(
                                                    props,
                                                    option
                                                ) => (
                                                    <li
                                                        {...props}
                                                        key={
                                                            option?.value ??
                                                            option
                                                        }
                                                    >
                                                        {option?.label ??
                                                            option}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        sx={{
                                                            ...(temaEscuro
                                                                ? textCompleteStyleBlack
                                                                : textCompleteStyle),
                                                            "& label": {
                                                                color: temaEscuro
                                                                    ? COLOR_LABEL_INPUT
                                                                    : BORDER_BUTTON,
                                                            },
                                                        }}
                                                        label={"Destino *"}
                                                        InputLabelProps={{
                                                            style: {
                                                                color:
                                                                    temaEscuro &&
                                                                    params
                                                                        .inputProps
                                                                        .value ===
                                                                        "" &&
                                                                    BORDER_BUTTON,
                                                            },
                                                        }}
                                                        placeholder={
                                                            "Destino *"
                                                        }
                                                    />
                                                )}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                    alignItems: "end",
                                }}
                            ></Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                    alignItems: "end",
                                }}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "end",
                                    }}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            border: 0,
                                            borderBottom: temaEscuro
                                                ? `1px solid ${BORDER_TABLE}`
                                                : `1px solid ${WHITE_ESCRITA_THEME}`,
                                            ml: 2,
                                        }}
                                    />
                                    <Grid item>
                                        <DefaultButton
                                            title={"Completar informações"}
                                            size={"small"}
                                            onClick={handleCompletarInfo}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            container
                            sx={{
                                backgroundColor: temaEscuro
                                    ? BLUE_THEME
                                    : GRAY_LABEL_UX_THEME,
                                backgroundImage: temaEscuro && "none",
                                p: 3,
                                width: "100%",
                                height: 270.5,
                            }}
                        >
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <a
                                            style={{
                                                fontSize: "18px",
                                                fontWeight: "bold",
                                                color:
                                                    conciliacao.movimentacao
                                                        .valor_vxmf > 0
                                                        ? GREEN_SUCCESS_UX
                                                        : RED_ERROR_UX,
                                            }}
                                        >
                                            {formatterCurrency(
                                                conciliacao.movimentacao
                                                    .valor_vxmf,
                                                2
                                            )}
                                        </a>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid
                                            container
                                            sx={{
                                                display: "flex",
                                                justifyContent: "end",
                                                gap: "5px",
                                            }}
                                        >
                                            <a
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {
                                                    conciliacao.movimentacao
                                                        .data_pagamento
                                                }
                                            </a>
                                            <a style={{ fontSize: "16px" }}>
                                                Segunda-feira
                                            </a>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            border: 0,
                                            borderBottom: temaEscuro
                                                ? `1px solid ${BORDER_TABLE}`
                                                : `1px solid ${WHITE_ESCRITA_THEME}`,
                                            ml: 2,
                                        }}
                                    />
                                    <Grid item xs={12}>
                                        <Grid container gap={2}>
                                            <Grid item xs={12}>
                                                <a
                                                    style={{
                                                        fontSize: "16px",
                                                    }}
                                                >
                                                    {
                                                        conciliacao.movimentacao
                                                            .descricao
                                                    }
                                                </a>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <a style={{ fontSize: "16px" }}>
                                                    <span
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Fornecedor:
                                                    </span>{" "}
                                                    {conciliacao.movimentacao
                                                        .nome_fornecedor ||
                                                        "Não informado"}
                                                </a>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <a style={{ fontSize: "16px" }}>
                                                    <span
                                                        style={{
                                                        fontWeight: "bold",
                                                        }}
                                                    >
                                                        Categoria:
                                                    </span>{" "}
                                                    {
                                                        conciliacao.movimentacao
                                                            .nome_categoria
                                                    }
                                                </a>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <a style={{ fontSize: "16px" }}>
                                                    <span
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Juros/Multa:
                                                    </span>{" "}
                                                    {formatterCurrency(
                                                        conciliacao.movimentacao
                                                            .juros_multa,
                                                        2
                                                    )}
                                                </a>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <a style={{ fontSize: "16px" }}>
                                                    <span
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Desconto:
                                                    </span>{" "}
                                                    {formatterCurrency(
                                                        conciliacao.movimentacao
                                                            .desconto,
                                                        2
                                                    )}
                                                </a>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                    alignItems: "end",
                                }}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "end",
                                    }}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            border: 0,
                                            borderBottom: temaEscuro
                                                ? `1px solid ${BORDER_TABLE}`
                                                : `1px solid ${WHITE_ESCRITA_THEME}`,
                                            ml: 2,
                                        }}
                                    />
                                    <Grid item>
                                        <DefaultButton
                                            title={"Editar"}
                                            size={"small"}
                                            startIcon={<AddIcon />}
                                            onClick={() =>
                                                handleEditMovimentacao(
                                                    conciliacao.movimentacao
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <DefaultButton
                                            title={"Desvincular"}
                                            size={"small"}
                                            startIcon={<AddIcon />}
                                            onClick={() => handleUnlinkMovimentacao(idx)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default BlocoLancamentoVXMF;
