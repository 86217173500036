import React  from "react";
import {smallPaperStyle, GRAY_LABEL_UX, styleTitleModal, BLUE_THEME, WHITE_ESCRITA_THEME, styleText, BORDER_TABLE, BORDER_PAPER, BORDER_BLACK_THEME_CARD, WHITE_THEME_BLACK, GRAY_BG_UX,} from "../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import DefaultButton from "../Buttons/DefaultButton";
import {useSelector} from "react-redux";
import Divider from "@mui/material/Divider";

const DesfazerConciliacao = ({
    open,
    setOpen,
    itemSelected,
    desfazerConciliacao
}) => {

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const styleCard = {
        border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`,
        display: "flex",
        alignItems: "center",
        padding: 3,
        flexDirection: "column",
        gap: 3,
        width: "270px",
        borderRadius: 1,
        justifyContent: "space-between"
    };

    const styleTitle = {
        color: temaEscuro ? WHITE_THEME_BLACK : GRAY_BG_UX, fontWeight: "600", fontSize: "17px"
    };

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            data-testid="desfazer-modal"
        >
            <Paper sx={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME, maxWidth: "none", width: "850px"}}>
                <Grid container>
                    <Grid item xs={12}>
                        <h3 style={{marginTop: 0, ...styleTitleModal, color: temaEscuro ? GRAY_BG_UX : GRAY_LABEL_UX, fontWeight: "700"}}>O que você quer fazer?</h3>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} /> 
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 3}} >
                        <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Lançamento selecionado: {itemSelected.descricao_vxmf}. Escolhendo qualquer ação abaixo, o lançamento bancário voltará para <strong>Conciliações pendentes.</strong></span>
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 3}}>
                        <Grid container sx={{display: "flex", justifyContent: "space-between", gap: 2}}>
                            <Grid item sx={{...styleCard}}>
                                <span style={{...styleTitle, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>Desfazer conciliação</span>
                                <span style={{...styleText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>A situação do lançamento VXMF permanecerá como &quot;Pago&quot;.</span>
                                <span style={{ ...styleText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, marginBottom: 3}}>O saldo do VXMF não será alterado.</span>
                                <DefaultButton
                                    title="Apenas desfazer"
                                    onClick={() => desfazerConciliacao("apenas_desfazer")}
                                    testId="desfazer-conciliacao"
                                    size="small"
                                />
                            </Grid>
                            <Grid item sx={{...styleCard}}>
                                <span style={{...styleTitle, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>Desfazer conciliação e voltar para &quot;em aberto&quot;</span>
                                <span style={{...styleText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>A situação do lançamento VMXF voltará para &quot;Em aberto&quot;.</span>
                                <span style={{...styleText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, marginBottom: 3}}>O saldo da VMXF será recalculado, desconsiderando este pagamento.</span>
                                <DefaultButton
                                    title="Voltar para em aberto"
                                    onClick={() => desfazerConciliacao("voltar_aberto")}
                                    testId="cancelar-desfazer-conciliacao"
                                    size="small"
                                />
                            </Grid>
                            <Grid item sx={{...styleCard}}>
                                <span style={{...styleTitle, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>Desfazer conciliação e excluir lançamento</span>
                                <span style={{...styleText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>O lançamento VMXF será excluído.</span>
                                <span style={{...styleText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, marginBottom: 3}}>O saldo da VMXF será recalculado.</span>
                                <DefaultButton
                                    title="Excluir lançamento"
                                    onClick={() => desfazerConciliacao("excluir_lancamento")}
                                    testId="excluir-lancamento"
                                    size="small"
                                    variant="yellow"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );
};

export default DesfazerConciliacao;
