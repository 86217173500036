import React from "react";
import Grid from "@mui/material/Grid";
import {
    styleText,
    WHITE_ESCRITA_THEME,
    GRAY_LABEL_UX,
    BORDER_TABLE,
    BLUE_THEME,
    BORDER_BUTTON,
    GRAY_BG_UX,
    LINE_TABLE,
    RED_INFO,
    translateAutocomplete,
    textCompleteStyleBlack,
    textCompleteStyle,
    WHITE_TABLE
} from "../../../../shared/utils";
import { useSelector } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";


const StepOneCartaoCredito = (props) => {

    const {
        contasPagamento,
        bandeiras,
        inputApelido,
        setInputApelido,
        inputUltimosDigitos,
        setInputUltimosDigitos,
        inputCartaoBandeira,
        setInputCartaoBandeira,
        inputCartaoContaPagamento,
        setInputCartaoContaPagamento,
        setInputCartaoEmissor,
        inputCartaoEmissor,
        bancos
    } = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const stylePaper = {
        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
        color: temaEscuro ? "#FFF" : "#4A5568",
    };
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, marginBottom: 2}}>
                <h3 style={{marginTop: 0, color: temaEscuro ? LINE_TABLE : GRAY_LABEL_UX, fontWeight: 700}}>Cadastre as informações do Cartão de Crédito</h3>
            </Grid>
            <Grid item xs={12} sx={{marginTop: 1}}>
                <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 600}}>Cadastre uma descrição, informe a conta corrente para pagamento e a bandeira do seu Cartão de Crédito.</span>
            </Grid>
            <Grid item xs={12} sx={{marginTop: 2, display: "flex", gap: 2, flexWrap: "wrap"}}>
                <TextField
                    label="Apelido do Cartão de Cŕedito"
                    id="outlined-size-small"
                    size="small"
                    value={inputApelido}
                    onChange={(event) => {
                        setInputApelido(event.target.value);
                    }}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                        "& .MuiFormLabel-asterisk": {
                            color: RED_INFO,
                        }
                    }}
                    InputLabelProps={{
                        style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }, 
                    }}
                    required
                />
                <TextField
                    label="últimos 4 números do cartão"
                    id="outlined-size-small"
                    size="small"
                    value={inputUltimosDigitos}
                    onChange={(event) => {
                        setInputUltimosDigitos(event.target.value);
                    }}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                        "& .MuiFormLabel-asterisk": {
                            color: RED_INFO,
                        }
                    }}
                    InputLabelProps={{
                        style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }, 
                    }}
                    required
                    inputProps={{ maxLength: 4 }}
                />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    noOptionsText={translateAutocomplete}
                    size="small"
                    options={bandeiras}
                    slotProps={{
                        paper: {style: stylePaper}
                    }}
                    style={{backgroundColor: temaEscuro && BLUE_THEME, width: "250px",}}
                    value={inputCartaoBandeira}
                    onChange={(e, bandeira) => setInputCartaoBandeira(bandeira)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            sx={{
                                ...(temaEscuro ? textCompleteStyleBlack : textCompleteStyle),
                                "& .MuiFormLabel-asterisk": {
                                    color: RED_INFO,
                                },
                            }}
                            label={"Bandeira"}
                            InputLabelProps={{
                                style: {color: temaEscuro && params.inputProps.value === "" && BORDER_BUTTON},
                            }}
                            required
                        />
                    )}
                    PaperComponent={({ children }) => (
                        <Paper
                            style={{
                                backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
                                color: temaEscuro ? WHITE_TABLE : BLUE_THEME,
                                fontWeight: "500",
                            }}
                        >
                            {children}
                        </Paper>
                    )}
                />
            </Grid>
            <Grid item xs={12} sx={{display: "flex", gap: 2, flexWrap: "wrap"}}>
                <Autocomplete
                    noOptionsText={translateAutocomplete}
                    size="small"
                    options={bancos}
                    slotProps={{
                        paper: {style: stylePaper}
                    }}
                    style={{backgroundColor: temaEscuro && BLUE_THEME, width: "250px",}}
                    value={inputCartaoEmissor}
                    onChange={(e, banco) => setInputCartaoEmissor(banco)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            sx={{
                                ...(temaEscuro ? textCompleteStyleBlack : textCompleteStyle),
                                "& .MuiFormLabel-asterisk": {
                                    color: RED_INFO,
                                },
                            }}
                            label={"Emissor do Cartão"}
                            InputLabelProps={{
                                style: {color: temaEscuro && params.inputProps.value === "" && BORDER_BUTTON},
                            }}
                            required
                        />
                    )}
                    PaperComponent={({ children }) => (
                        <Paper
                            style={{
                                backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
                                color: temaEscuro ? WHITE_TABLE : BLUE_THEME,
                                fontWeight: "500",
                            }}
                        >
                            {children}
                        </Paper>
                    )}
                />
                <Autocomplete
                    noOptionsText={translateAutocomplete}
                    size="small"
                    options={contasPagamento}
                    slotProps={{
                        paper: {style: stylePaper}
                    }}
                    style={{backgroundColor: temaEscuro && BLUE_THEME, width: "250px",}}
                    value={inputCartaoContaPagamento}
                    onChange={(e, banco) => setInputCartaoContaPagamento(banco)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            sx={{
                                ...(temaEscuro ? textCompleteStyleBlack : textCompleteStyle),
                            }}
                            label={"Conta padrão para o pagamento"}
                            InputLabelProps={{
                                style: {color: temaEscuro && params.inputProps.value === "" && BORDER_BUTTON},
                            }}
                        />
                    )}
                    PaperComponent={({ children }) => (
                        <Paper
                            style={{
                                backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
                                color: temaEscuro ? WHITE_TABLE : BLUE_THEME,
                                fontWeight: "500",
                            }}
                        >
                            {children}
                        </Paper>
                    )}
                />
            </Grid>
        </Grid>
    );
};

export default React.memo(StepOneCartaoCredito);