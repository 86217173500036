import React, { memo, useState } from "react";
import { BLUE_THEME, formatterCurrency, GRAY_PDF, TEXT_PDF, WHITE_ESCRITA_THEME, BORDER_TABLE, BORDER_PAPER,} from "../../shared/utils";
import DefaultButton from "../Buttons/DefaultButton";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Divider from "@mui/material/Divider";
import FilterSingleOption from "../Filter/FilterSingleOption";


const ImportFileSecondStep = ({savePlanilha, fecharImportar, processedData, contas, sheet}) => {
    const [contaSelected, setContaSelected] = useState(null);
    const [contaIsNull, setContaIsNull] = useState(false);

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const listItems = ["Linha", "Competência", "Vencimento", "Baixa", "Valor", "Categoria", "Descrição", "Cliente/Fornecedor", "Centro de custo", "Observações", "Rateio"];

    // React.useEffect(() => {
    //     console.log(contaSelected);
    // }, [contaSelected]);

    const checkAndSave = () => {
        if (contaSelected == null) {
            setContaIsNull(true);
        }
        else {
            savePlanilha(contaSelected.value);
        }
    };

    return (
        <React.Fragment>
            <Grid container xs={12} spacing={2}>
                <Grid item xs={12}>
                    <span><p className="title-container-p">{`Confirmar dados da importação - ${sheet.name}`}</p></span>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} /> 
                </Grid>
            </Grid>
            <span style={{fontSize: "14px", margin: "1rem 0"}}>Agora vamos conferir os dados que foram importados da planilha. Você pode inserir a conta que deseja lançar.</span>
            <Grid container spacing={4} sx={{mt: 1}} data-testid="import-second-step-container">
                <Grid item xs={3} sx={{display: "flex", flexDirection: "column", gap: 1}}>
                    <span style={{fontSize: "14px"}}>
                        Importar lançamentos para a conta
                    </span>
                    <FormControl error={contaIsNull}>
                        <FilterSingleOption
                            filterOptions={contas}
                            selectedOption={contaSelected}
                            setSelectedOption={setContaSelected}
                            width={"375px"}
                            testId={"select-conta"}
                        />
                        {
                            contaIsNull && (
                                <FormHelperText>É obrigatório escolher uma conta.</FormHelperText>
                            )
                        }
                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={{mt: 1}}>
                    <TableContainer data-testid="import-second-step-table" >
                        <Table>
                            <TableHead sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_PDF}}>
                                <TableRow>
                                    {listItems.map((item, index) => {
                                        return (
                                            <TableCell key={index} sx={{...styles.tableCellStyle, pr: index+1 === listItems.length && ".5rem", color: temaEscuro ? WHITE_ESCRITA_THEME : TEXT_PDF,}}>{item}</TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    processedData?.map((item, index) => {
                                        return (
                                            <TableRow data-testid="rows" key={index}>
                                                <TableCell sx={{...styles.tableCellDataStyle, color: temaEscuro ? WHITE_ESCRITA_THEME : TEXT_PDF,}}>{index+1}</TableCell>
                                                <TableCell sx={{...styles.tableCellDataStyle, color: temaEscuro ? WHITE_ESCRITA_THEME : TEXT_PDF,}}>{item.data_competencia}</TableCell>
                                                <TableCell sx={{...styles.tableCellDataStyle, color: temaEscuro ? WHITE_ESCRITA_THEME : TEXT_PDF,}}>{item.data_vencimento}</TableCell>
                                                <TableCell sx={{...styles.tableCellDataStyle, color: temaEscuro ? WHITE_ESCRITA_THEME : TEXT_PDF,}}>{item.data_pagamento}</TableCell>
                                                <TableCell sx={{...styles.tableCellDataStyle, color: temaEscuro ? WHITE_ESCRITA_THEME : TEXT_PDF,}}>{formatterCurrency(item?.valor, 2)}</TableCell>
                                                <TableCell sx={{...styles.tableCellDataStyle, color: temaEscuro ? WHITE_ESCRITA_THEME : TEXT_PDF,}}>{item.categoria}</TableCell>
                                                <TableCell sx={{...styles.tableCellDataStyle, color: temaEscuro ? WHITE_ESCRITA_THEME : TEXT_PDF,}}>{item.descricao}</TableCell>
                                                <TableCell sx={{...styles.tableCellDataStyle, color: temaEscuro ? WHITE_ESCRITA_THEME : TEXT_PDF,}}>{item.cliente_fornecedor}</TableCell>
                                                <TableCell sx={{...styles.tableCellDataStyle, color: temaEscuro ? WHITE_ESCRITA_THEME : TEXT_PDF,}}>{item.centro_custo}</TableCell>
                                                <TableCell sx={{...styles.tableCellDataStyle, color: temaEscuro ? WHITE_ESCRITA_THEME : TEXT_PDF,}}>{item.observacoes}</TableCell>
                                                <TableCell sx={{...styles.tableCellDataStyle, color: temaEscuro ? WHITE_ESCRITA_THEME : TEXT_PDF,}}>{item.rateio}</TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Grid container sx={{display: "flex", justifyContent: "space-between", mt: 2}}>
                <DefaultButton
                    title="Fechar"
                    onClick={() => fecharImportar()}
                />
                <DefaultButton
                    testId="concluir-button"
                    title="Concluir"
                    onClick={() => checkAndSave()}
                    variant="yellow"
                />
            </Grid>
        </React.Fragment>
    );
};

const styles = {
    tableCellStyle: {
        margin: 0,
        padding: ".5rem 0 .5rem 1rem",
        fontWeight: "600"
    },
    tableCellDataStyle: {
        margin: 0,
        padding: ".5rem 0 .5rem 1rem",
        fontWeight: "400"
    }
};

export default memo(ImportFileSecondStep);