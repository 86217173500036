import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DefaultButton from "../Buttons/DefaultButton";
const ConfirmDialog = ({
    title,
    description,
    buttonTitle,
    callback,
    open,
    setOpen,
}) => {
    return (
        <Dialog open={open} onClose={() => setOpen(false)} data-testid="dialog-criar-conciliar">
            <DialogTitle sx={{ m: 0, p: 2 }}>{title}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>{description}</DialogContent>
            <div
                style={{
                    display: "flex",
                    padding: "1rem",
                    justifyContent: "space-between",
                    marginTop: "1rem",
                }}
            >
                <DefaultButton title="Cancelar" onClick={() => setOpen(false)} />
                <DefaultButton
                    title={buttonTitle}
                    onClick={callback}
                    testId="callback-function"
                    variant="yellow"
                />
            </div>
        </Dialog>
    );
};

export default ConfirmDialog;
