import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
	DASH_KPI_COLOR,
	DASH_KPI_DATA_COLOR,
	dashLeftTitle,
	paperDash,
	BLUE_THEME,
	WHITE_ESCRITA_THEME,
} from "../../shared/utils";

const DetalhaCartaoCreditoKPI = (props) => {
	const {saldoValor, dataFechamento, testId} = props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

	return(
		<Paper
			className={props.className}
			sx={{
				...paperDash,
				...dashLeftTitle,
				backgroundColor : temaEscuro && BLUE_THEME,
				backgroundImage: temaEscuro && "none",
			}}
        >
			<Grid container rowSpacing={2} data-testid={testId}>
				<Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Grid container>
                        <Grid item xs={6}>
							<span style={{...styles.title, ...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>Saldo anterior:</span>
                        </Grid>
                        <Grid item xs={6} sx={{display: "flex", justifyContent: "end"}}>
							<span style={{...styles.subTitle, ...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>{saldoValor}</span>
                        </Grid>
                    </Grid>
				</Grid>
				<Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                    <Grid container>
                        <Grid item xs={6}>
                            <span style={{...styles.title, ...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>Fechamento:</span>
                        </Grid>
                        <Grid item xs={6} sx={{display: "flex", justifyContent: "end"}}>
                            <span style={{...styles.subTitle, ...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>{dataFechamento}</span>
                        </Grid>
                    </Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

// Create styles
const styles = {
	title: {
		fontSize: "16px",
		fontWeight: 500,
		color: DASH_KPI_COLOR
	},
	subTitle: {
		fontSize: "20px",
		fontWeight: 600,
		color: DASH_KPI_DATA_COLOR
    },
};

export default React.memo(DetalhaCartaoCreditoKPI);