import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import {useSelector, useDispatch} from "react-redux";
import {
    styleText,
    WHITE_ESCRITA_THEME,
    GRAY_LABEL_UX,
    BORDER_TABLE,
    LINE_TABLE,
    YELLOW_BG_UX,
    GRAY_BORDER_UX,
    GRAY_STRONG_UX,
    WHITE_THEME_BLACK
} from "../../../../shared/utils";
import BancoBrasil from "../../../Contas/LogoBancos/BB.png";
import Inter from "../../../Contas/LogoBancos/Inter.png";
import Santander from "../../../Contas/LogoBancos/Santander.png";
import Sicoob from "../../../Contas/LogoBancos/Sicoob.png";
import InputSearch from "../../../../components/Input/InputSearch";
import Box from "@mui/material/Box";
import DefaultButton from "../../../../components/Buttons/DefaultButton";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import {showSnackMessage} from "../../../../actions/SnackActions";


const CreateIntegracao = (props) => {

    const {banco, clientId, setClientId, clientSecret, setClientSecret, selectedFile, setSelectedFile, selectedKeyFile, setSelectedKeyFile} = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const dispatch = useDispatch();
    const hiddenFileInput = React.useRef(null);

    const [isSelected, setIsSelected] = useState(false);

    const openManual = () => {
        window.open("https://voxelgestaocom.sharepoint.com/:w:/r/sites/VoxelGesto/_layouts/15/Doc.aspx?sourcedoc=%7BE70F11EF-20C7-4739-971D-9E70282660C2%7D&file=MANUAL%20-%20MIGRA%C3%87%C3%83O.docx&action=default&mobileredirect=true&wdOrigin=OFFICE-OFFICE-METAOS.FILEBROWSER.FILES-SITES-FOLDER", "_blank");
    };

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const changeHandler = (event) => {                
        let file = event.target.files[0];
        let file2 = event.target.files[1];
        
        if (file != null) {
            if ((file.type === "application/pkix-cert" || file2?.type === "application/pkix-cert") || (file.type === "application/vnd.apple.keynote" || file2?.type === "application/vnd.apple.keynote" || file2?.type === "application/x-x509-ca-cert") || (file.type === "application/vnd.apple.keynote" || file2?.type === "application/x-x509-ca-cert")) {
                setSelectedFile((file.type === "application/pkix-cert" || file.type === "application/x-x509-ca-cert") ? file : file2);
                setSelectedKeyFile(file2.type === "application/vnd.apple.keynote" ? file2 : file);
                setIsSelected(true);
            }
            else {
                let message = "Insira o certificado e a chave";
                dispatch(showSnackMessage({ message: message, severity: "error" }));
            }
        }
    };

    const renderBanco = (bancoNome) => {
        const bancos = {
            "Banco do Brasil": BancoBrasil,
            "Inter": Inter,
            "Santander": Santander,
            "Sicoob": Sicoob,
        };

        if (bancos[bancoNome]) {
            return <img src={bancos[bancoNome]} alt={bancoNome} style={{height: 50, width: 51}} />;
        }

        return (
            <div
                style={{
                height: "40px",
                padding: "0px 10px",
                backgroundColor: YELLOW_BG_UX,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                fontSize: "14px",
                color: "black",
                borderRadius: "5px",
                }}
            >
                {bancoNome.toUpperCase()}
            </div>
        );
    };

    const renderFormulario = (bancoNome) => {
        switch (bancoNome) {
            case "Banco do Brasil":
                return (
                    <React.Fragment>
                        <Box sx={{marginY: 2, display: "flex", flexDirection: "column", gap: 1}}>
                            <span><strong style={{color: YELLOW_BG_UX}}>1º </strong>- Faça login no link do site (link disponível no manual)</span>
                            <span><strong style={{color: YELLOW_BG_UX}}>2º </strong>- Crie uma aplicação do tipo EXTRATO</span>
                            <span><strong style={{color: YELLOW_BG_UX}}>3º </strong>- Peça para o cliente autorizar a aplicação e aguarde ela ficar com o staus PRODUÇÃO</span>
                            <span><strong style={{color: YELLOW_BG_UX}}>4º </strong>-Insira os dados CLIENTID e CLIENTSECRETS no formúlario abaixo</span>
                        </Box>
                        <InputSearch
                            functionProps={() => {}}
                            setSearchValue={setClientId}
                            searchValue={clientId}
                            label={"Cliente ID *"}
                            width={"450px"}
                            testId={"input-client-id"}
                        />
                        <InputSearch
                            functionProps={() => {}}
                            setSearchValue={setClientSecret}
                            searchValue={clientSecret}
                            label={"Cliente Secret *"}
                            width={"450px"}
                            testId={"input-client-secrets"}
                        />
                    </React.Fragment>
                );
            case "Inter":
                return (
                    <React.Fragment>
                        <Box sx={{marginY: 2, display: "flex", flexDirection: "column", gap: 1}}>
                            <span><strong style={{color: YELLOW_BG_UX}}>1º </strong>- Faça login no link do site (link disponível no manual), no menu Conta digital, navegue até a área Aplicações e clique em Nova aplicação. </span>
                            <span><strong style={{color: YELLOW_BG_UX}}>2º </strong>- Após criar a aplicação, clique em Gestão de aplicações. </span>
                            <span><strong style={{color: YELLOW_BG_UX}}>3º </strong>- Clique na aplicação que você criou e no botão Baixar chaves e certificado</span>
                            <span><strong style={{color: YELLOW_BG_UX}}>4º </strong>- Importe os arquivos abaixo</span>
                            <span><strong style={{color: YELLOW_BG_UX}}>5º </strong>- Copie os códigos CLIENTID e CLIENTSECRETS que aparecerão no Internet Banking do Inter e cole nos campos abaixo</span>
                        </Box>
                        <Grid item xs={6}>
                            <DefaultButton
                                title={"Anexar certificados .crt e .key"}
                                onClick={handleClick}
                                startIcon={<AttachFileOutlinedIcon/>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <input type="file"
                                accept={".crt, .key"}
                                ref={hiddenFileInput}
                                onChange={changeHandler}
                                style={{display: "none", ml: 1}}
                                multiple={true}
                            />
                            {
                                isSelected &&
                                    <Grid style={{...styles.styleFieldImport, color: temaEscuro && WHITE_THEME_BLACK}}>
                                        <span>Nome dos arquivos: {selectedFile?.name} e {selectedKeyFile?.name}</span>
                                    </Grid>
                            }
                        </Grid>
                        <InputSearch
                            functionProps={() => {}}
                            setSearchValue={setClientId}
                            searchValue={clientId}
                            label={"Cliente ID *"}
                            width={"450px"}
                        />
                        <InputSearch
                            functionProps={() => {}}
                            setSearchValue={setClientSecret}
                            searchValue={clientSecret}
                            label={"Cliente Secret *"}
                            width={"450px"}
                        />
                    </React.Fragment>
                );
            case "Santander":
                return (
                    <React.Fragment>
                        <Box sx={{marginY: 2, display: "flex", flexDirection: "column", gap: 1}}>
                            <span><strong style={{color: YELLOW_BG_UX}}>1º </strong>- Faça login no link do site (link disponível no manual)</span>
                            <span><strong style={{color: YELLOW_BG_UX}}>2º </strong>- Crie uma aplicação habilitando a opção &ldquo;Saldo e Extrato&ldquo; e anexe o certificado .pem</span>
                            <span><strong style={{color: YELLOW_BG_UX}}>3º </strong>- Acesse a aplicação que foi criada</span>
                            <span><strong style={{color: YELLOW_BG_UX}}>4º </strong>-Insira os dados CLIENTID e CLIENTSECRETS no formúlario abaixo</span>
                        </Box>
                        <InputSearch
                            functionProps={() => {}}
                            setSearchValue={setClientId}
                            searchValue={clientId}
                            label={"Cliente ID *"}
                            width={"450px"}
                        />
                        <InputSearch
                            functionProps={() => {}}
                            setSearchValue={setClientSecret}
                            searchValue={clientSecret}
                            label={"Cliente Secret *"}
                            width={"450px"}
                        />
                    </React.Fragment>
                );
            case "Sicoob":
                return (
                    <React.Fragment>
                        <Box sx={{marginY: 2, display: "flex", flexDirection: "column", gap: 1}}>
                            <span><strong style={{color: YELLOW_BG_UX}}>1º </strong>- Faça login no link do site (link disponível no manual)</span>
                            <span><strong style={{ color: YELLOW_BG_UX }}>2º </strong>- Vá em &ldquo;Meus aplicativos&rdquo; e depois &ldquo;Gerar aplicativo&rdquo;</span>

                            <span><strong style={{color: YELLOW_BG_UX}}>3º </strong>- Crie uma aplicação dentro do portal, anexando o certificado &ldquo;.pem&ldquo;.</span>
                            <span><strong style={{color: YELLOW_BG_UX}}>4º </strong>-Insira o CLIENTID no formúlario abaixo</span>
                        </Box>
                        <InputSearch
                            functionProps={() => {}}
                            setSearchValue={setClientId}
                            searchValue={clientId}
                            label={"Cliente ID *"}
                            width={"450px"}
                        />
                    </React.Fragment>
                );
        }
    };

    return (
        <Grid container spacing={2} data-testid="step-two-conta-corrente-integracao">
            <Grid item xs={12} sx={{borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, marginBottom: 2}}>
                <h3 style={{marginTop: 0, color: temaEscuro ? LINE_TABLE : GRAY_LABEL_UX, fontWeight: 700}}>
                Configurando a integração da conta para o {banco}</h3>
            </Grid>
            <Grid item xs={12} sx={{marginTop: 1}}>
                <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 600}}>
                    Para obter as informações necessárias para a configuração
                    <a onClick={openManual} style={{color: temaEscuro ? YELLOW_BG_UX : GRAY_LABEL_UX, textDecoration: "underline", cursor: "pointer"}}> clique aqui </a> 
                    para acessar o manual e navegue até a seção do banco correspondente</span>
            </Grid>
            <Grid item xs={12} sx={{marginTop: 1}}>
                {renderBanco(banco)}
            </Grid>
            <Grid item xs={12} sx={{display: "flex", gap: 2, flexDirection: "column"}}>
                {renderFormulario(banco)}
            </Grid>
        </Grid>
    );
};

const styles = {
    styleFieldImport: {
        color : GRAY_STRONG_UX
    },

    formControl: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: GRAY_BORDER_UX
            },
        }
    },
};

export default React.memo(CreateIntegracao);