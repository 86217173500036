import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../../components/Titles/Header";
import {
    Checkbox,
    Divider,
    Grid,
    Paper,
    Tab,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TableBody,
    TextField, 
    Accordion,
    AccordionSummary,
    AccordionDetails} from "@mui/material";
import {
    BLACK_LABEL_UX,
    BLACK_TABLE,
    BLUE_LIGHT_UX_THEME,
    BLUE_THEME,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    BORDER_TABLE,
    DASH_KPI_DATA_COLOR,
    GRAY_BG_UX,
    GRAY_LABEL_UX,
    GRAY_LABEL_UX_THEME,
    LINE_TABLE,
    PAPER_PADDING_THEME,
    paperStyle,
    styleText,
    WHITE_ESCRITA_THEME,
    WHITE_THEME_BLACK,
    YELLOW_BG_UX,
    format, 
    checkSituacao,
    formatterCurrency,
    GRAY_PDF,
    paperDash,
    dashLeftTitle,
    DASH_KPI_NEGATIVE_COLOR,
    BLUE_INFO_UX,
    DASH_KPI_COLOR,
    getCurrentDate,
    formaPagamentoOptions,
    GREEN_SUCCESS_UX,
    formatterPercentage} from "../../../shared/utils";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../axios";
import InfoIcon from "@mui/icons-material/Info";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import FilterSingleOption from "../../../components/Filter/FilterSingleOption";
import FilterDayMonthYear from "../../../components/Filter/FilterDayMonthYear";
import InputSearch from "../../../components/Input/InputSearch";
import InputCurrency from "../../../components/Input/InputCurrency";
import Anexos from "../../../components/Tabs/Anexos";
import SaveOptions from "../../../components/Buttons/SaveOptions";
import RateiosReceita from "../../../components/Tabs/RateiosReceita";
import CreateOptionModal from "../../../components/Modal/CreateOptionModal";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { showSnackMessage } from "../../../actions/SnackActions";
import DefaultButton from "../../../components/Buttons/DefaultButton";

const EditarLancamentoRecorrente = () => {
    const dispatch = useDispatch();

    const nav = useNavigate();

    const location = useLocation();

    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const [openWarning, setOpenWarning] = useState(true);
    const [lancamento, setLancamento] = useState(location.state.baixaId);
    const [fornecedores, setFornecedores] = useState([]);
    const [dataCompetencia, setDataCompetencia] = useState("");
    const [descricao, setDescricao] = useState("");
    const [hasRateio, setHasRateio] = useState(false);
    const [categorias, setCategorias] = useState([]);
    const [centroCusto, setCentroCusto] = useState("");
    const [dataVencimento, setDataVencimento] = useState();
    const [contas, setContas] = useState([]);
    const [observacoes, setObservacoes] = useState("");
    const [anexos, setAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);
    const [valor, setValor] = useState(0);
    const [situacao, setSituacao] = useState("");
    
    const [valorEmAberto, setValorEmAberto] = useState(0);
    const [valorPago, setValorPago] = useState(0);

    const [rateios, setRateios] = useState([{
        categoria__nome: null,
        valor: 0,
        percentual: 0,
    }]);

    const [totalPago, setTotalPago] = useState(0);
    const [saldoEmAberto, setSaldoEmAberto] = useState(0);

    const [selectedFornecedor, setSelectedFornecedor] = useState();
    const [selectedCategoria, setSelectedCategoria] = useState("");
    const [selectedCentroCusto, setSelectedCentroCusto] = useState("");
    const [selectedConta, setSelectedConta] = useState();
    const [selectedFormaPagamento, setSelectedFormaPagamento] = useState("");
    const [hasAgendado, setHasAgendado] = useState(false);
    const [hasPago, setHasPago] = useState(false);
    const [inputError, setInputError] = useState(false);
    const [loadingAnexos, setLoadingAnexos] = useState(true);

    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [optionCreateModal, setOptionCreateModal] = useState("");

    const [selectedTab, setSelectedTab] = useState("observacoes");

    const [selectedPrevisaoPagamento, setSelectedPrevisaoPagamento] = useState(moment(getCurrentDate()).toDate());

    const [desconto, setDesconto] = useState(0);
    const [juros, setJuros] = useState(0);
    const [multa, setMulta] = useState(0);
    const [totalPagar, setTotalPagar] = useState();
    
    useEffect(() => {
        if (selectedTab === "anexo") {
            getAnexos();
        }
    }, [selectedTab]);

    const getAnexos = () => {
        setLoadingAnexos(true);

        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            baixa_id: location.state.baixaId,
        };

        api.GetAnexos(dataRequest).then((response) => {
            const data = response.data;
            processAnexosData(data);
            setLoadingAnexos(false);
        });
    };

    useEffect(() => {
        getLancamentoData();
    }, []);

    useEffect(() => {
        if (valorPago < valor) {
            setValorEmAberto(valor - valorPago);
        }
    }, [valorPago]);

    useEffect(() => {
        setTotalPagar((valor + juros + multa) - desconto - valorEmAberto);
    }, [juros, multa, desconto, valor, valorEmAberto, hasPago]);

    const processAnexosData = (anexos_data) => {
        if (anexos_data.length === 0) {
            setAnexos([
                {
                    forma_anexo: "Arquivo",
                    arquivo: null,
                    tipo_anexo: "Contrato",
                    descricao: "",
                }
            ]);
            return;
        }

        let anexos_list = [];

        for (let i = 0; i < anexos_data.length; i++) {
            const byteCharacters = atob(anexos_data[i].arquivo);
            const byteNumbers = new Array(byteCharacters.length);
    
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
    
            const byteArray = new Uint8Array(byteNumbers);
    
            const blob = new Blob([byteArray], { type: anexos_data[i].mime_type });
    
            let file = new File([blob], anexos_data[i].nome_arquivo, { type: anexos_data[i].mime_type });
            
            anexos_list.push({
                forma_anexo: anexos_data[i].forma_anexo,
                arquivo: file,
                tipo_anexo: anexos_data[i].tipo_anexo,
                descricao: anexos_data[i].descricao,
            });
        }

        setAnexos(anexos_list);
    };

    const editLancamento = () => {
        if (dataCompetencia === "" || descricao === "" || valor === 0 || selectedCategoria === "" || selectedConta === "") {
            setInputError(true);
            return;
        }

        let formData = new FormData();

        formData.append("tipo_edicao", location.state.selectedChoice);
        formData.append("lancamento_id", location.state.baixaId);
        formData.append("movimentacao_id", lancamento.id);
        formData.append("situacao", situacao);
        formData.append("estabelecimento_id", estabelecimentoId);
        formData.append("fornecedor", selectedFornecedor);
        formData.append("data_competencia", moment(dataCompetencia).format("DD/MM/YYYY"));
        formData.append("descricao", descricao);
        formData.append("valor", valor);
        formData.append("categoria", selectedCategoria);
        formData.append("centro_custo", selectedCentroCusto);
        formData.append("data_vencimento", moment(dataVencimento).format("DD/MM/YYYY"));
        formData.append("forma_pagamento", selectedFormaPagamento);
        formData.append("conta_pagamento", selectedConta);
        formData.append("observacoes", observacoes);
        formData.append("anexos", JSON.stringify(anexos));
        formData.append("agendado", hasAgendado);
        formData.append("rateio", hasRateio);
        formData.append("valor_pago", valorPago);
        formData.append("pago", hasPago);
        formData.append("valor_em_aberto", valorEmAberto);
        formData.append("codigo_referencia", lancamento.codigo_referencia);
        formData.append("tipo_movimentacao", lancamento.tipo_movimentacao);
        formData.append("total_pago", totalPago);
        formData.append("saldo_em_aberto", saldoEmAberto);

        if (anexos[0].arquivo != null){
            formData.append("quantidade_anexo", anexos.length);
            anexos.forEach((anexo, index) => {
                formData.append(`anexos[${index}][forma_anexo]`, anexo.forma_anexo);
                formData.append(`anexos[${index}][arquivo]`, anexo.arquivo);
                formData.append(`anexos[${index}][tipo_anexo]`, anexo.tipo_anexo);
                formData.append(`anexos[${index}][descricao]`, anexo.descricao);
            });
        }

        formData.append("rateios", JSON.stringify(rateios));

        if (location.state.selectedChoice == "unico") {
            formData.append("data_pagamento", moment(selectedPrevisaoPagamento).format("DD/MM/YYYY"));
            formData.append("desconto", desconto);
            formData.append("juros", juros);
            formData.append("multa", multa);
        }

        api.UpdateLancamentoRecorrente(formData).then(() => {
            nav("/extrato_movimentacoes");
        }).catch(() => {
            dispatch(showSnackMessage({message: "Erro ao editar lançamento", severity: "error"}));
        });
    };

    const getLancamentoData = () => {
        if (estabelecimentoId !== 0) {
            let dataRequest = {
                estabelecimento_id: estabelecimentoId,
                lancamento_id: location.state.baixaId,
            };
            api.GetInfoMovimentacao(dataRequest).then((response) => {
                let responseData = response.data;
                
                setValor(responseData.movimentacao.valor_total);
                setLancamento(responseData.movimentacao);
                setSelectedCategoria(responseData.categoria.nome);
                setSelectedConta(responseData.conta.apelido);
                setSelectedFornecedor(responseData?.fornecedor?.nome);
                setSelectedCentroCusto(responseData.movimentacao.centro_custo);
                setSelectedFormaPagamento(responseData.movimentacao.forma_pagamento);
                setFornecedores(responseData.fornecedores);
                setCategorias(responseData.categorias);
                setCentroCusto(responseData.centros_custos);
                setContas(responseData.contas);
                setDataCompetencia(moment(responseData.movimentacao.data_movimentacao, "DD/MM/YYYY").toDate());
                setSelectedPrevisaoPagamento(moment(responseData.movimentacao.data_pagamento, "DD/MM/YYYY").toDate());
                setDescricao(responseData.movimentacao.descricao);
                setHasRateio(responseData.rateado);
                setRateios(responseData.rateios);
                setDataVencimento(moment(responseData.movimentacao.vencimento, "DD/MM/YYYY").toDate());
                setSelectedFormaPagamento(responseData.movimentacao.forma_pagamento);
                setHasPago(responseData.pago);
                setHasAgendado(responseData.agendado);
                setDesconto(responseData.desconto);
                setJuros(responseData.juros);
                setMulta(responseData.multa);
                setObservacoes(responseData.movimentacao.observacoes);
                setTotalPago(responseData.valor_pago);
                setSaldoEmAberto(responseData.total_pagar);
                if (responseData.movimentacao.valor_total == responseData.valor_pago) {
                    if (responseData.movimentacao.tipo_movimentacao == "Receita") {
                        setSituacao("Recebido");
                    }
                    else {
                        setSituacao("Pago");
                    }
                }
                else if (responseData.total_pagar !== 0 && moment(responseData.movimentacao.vencimento, "DD/MM/YYYY").isBefore(moment(getCurrentDate()))) {
                    setSituacao("Em Atraso");
                }
                else {
                    setSituacao("Em Aberto");
                }
            }).catch(() => {
                dispatch(showSnackMessage({message: "Erro ao buscar informações do lançamento", severity: "error"}));
            });
        }
    };

    const handleNavExtrato = () => {
        nav("/extrato_movimentacoes");
    };

    const getInfoText = () => {
        let infoText = "";
        
        switch (location.state.selectedChoice) {
            case "unico":
                infoText = `Você está editando apenas o lançamento referente a competência ${lancamento.data_movimentacao}`;
                break;
            case "nao-vencidos":
                infoText = `Você está editando o lançamento recorrente referente a competência ${lancamento.data_movimentacao} e todos os não vencidos que não possuam baixas`;
                break;
            case "em-aberto":
                infoText = `Você está editando o lançamento recorrente referente a competência ${lancamento.data_movimentacao} e todos em aberto (vencidos + a vencer) que não possuam baixas`;
                break;
            case "todos":
                infoText = "Você está editando todos os lançamento recorrentes. Algumas ações de edição poderão estar bloqueadas caso tenham valores baixados anteriormente, ou que possuam cobranças em aberto.";
                break;
            default:
                infoText = "";
                break;
        }
        return infoText;
    };

    const handleDayMonthYear = (ano, mes, dia, setState) => {
        setState(`${dia}/${mes}/${ano}`);
    };

    return (
        <div className="main">
            <CreateOptionModal
                openModal={openCreateModal}
                setOpenModal={setOpenCreateModal}
                option={optionCreateModal}
                getInputsOptions={getLancamentoData}
            />
            <Header title="Editar Lançamento" />
            {
                openWarning && (
                    <Paper sx={{...paperStyle, backgroundColor: temaEscuro ? BLUE_THEME : LINE_TABLE, backgroundImage: temaEscuro && "none", border: `1px solid ${BLUE_LIGHT_UX_THEME}`, borderWidth: "1px 1px 1px 6px", mb: 2}}>
                        <div style={{display: "flex", gap: "1rem", alignItems: "start", justifyContent: "space-between"}}>
                            <div style={{display: "flex", gap: "1rem", alignItems: "start"}}>
                                <InfoIcon sx={{color: BLUE_LIGHT_UX_THEME, ml: 1, fontSize: "26px"}} />
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <span style={{fontWeight: "bold", fontSize: "15px", marginBottom: ".5rem"}}>Lançamento Recorrente</span>
                                    <span style={{fontSize: "14px"}}>{getInfoText()}</span>
                                </div>
                            </div>
                            <CloseIcon
                                sx={{"cursor": "pointer"}}
                                onClick={() => setOpenWarning(false)}
                                fontSize="small"
                            />
                        </div>
                    </Paper>
                )
            }
            <Paper sx={{...paperStyle, backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE, backgroundImage: temaEscuro && "none", border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`, mb: 2}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <span><p className="title-container-p">Informações do lançamento</p></span>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} />
                    </Grid>
                    <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "start", gap: 2, flexWrap: "wrap"}}>
                        
                            <Grid item sx={{mr: "2rem"}}>
                                <FilterSingleOption
                                    sx={{borderRadius: 0}}
                                    filterOptions={fornecedores}
                                    selectedOption={selectedFornecedor}
                                    setSelectedOption={(e) => {
                                        setSelectedFornecedor(e);
                                    }}
                                    placeholder={lancamento.tipo_movimentacao === "Receita" ? "Cliente" : "Fornecedor"}
                                    width={"350px"}
                                    disabled={hasRateio}
                                    canCreate
                                    setOpenCreateModal={setOpenCreateModal}
                                    setOptionCreateModal={setOptionCreateModal}
                                />
                            </Grid>
                            <Grid item>
                                <FilterDayMonthYear
                                    disableMinDate
                                    handleSubmit={handleDayMonthYear}
                                    value={dataCompetencia}
                                    setState={setDataCompetencia}
                                    size={"small"}
                                    width={"250px"}
                                    iconPosition={"end"}
                                    label={"Data de competência *"}
                                    inputError={inputError}
                                />
                            </Grid>
                            <Grid item>
                                <InputSearch
                                    functionProps={() => {}}
                                    setSearchValue={setDescricao}
                                    searchValue={descricao}
                                    label={"Descrição *"}
                                    width={"575px"}
                                />
                            </Grid>
                            <Grid item>
                                <InputCurrency
                                    title={"Valor *"}
                                    valor={valor}
                                    setValor={setValor}
                                    disabled={hasPago}
                                />
                            </Grid>
                            <Grid item>
                                <Checkbox
                                    data-testid="rateio-checkbox"
                                    checked={hasRateio}
                                    icon={<CircleOutlinedIcon/>}
                                    checkedIcon={<CircleIcon/>}
                                    onChange={(event) => {
                                        setHasRateio(event.target.checked);
                                    }}
                                />
                                <a style={{
                                    ...styleText,
                                    color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
                                }}>Habilitar rateio</a>
                            </Grid>
                            <Grid item sx={{mr: "2rem"}}>
                                <FilterSingleOption
                                    sx={{borderRadius: 0}}
                                    filterOptions={categorias}
                                    selectedOption={selectedCategoria}
                                    setSelectedOption={(e) => {
                                        setSelectedCategoria(e);
                                    }}
                                    placeholder={"Categoria *"}
                                    width={"350px"}
                                    disabled={hasRateio}
                                    canCreate
                                    setOpenCreateModal={setOpenCreateModal}
                                    setOptionCreateModal={setOptionCreateModal}
                                />
                            </Grid>
                            <Grid item sx={{mr: "2rem"}}>
                                <FilterSingleOption
                                    filterOptions={centroCusto}
                                    selectedOption={selectedCentroCusto}
                                    setSelectedOption={(e) => {
                                        setSelectedCentroCusto(e);
                                    }}
                                    placeholder={"Centro de custo"}
                                    width={"350px"}
                                    canCreate
                                    setOpenCreateModal={setOpenCreateModal}
                                    setOptionCreateModal={setOptionCreateModal}
                                />
                            </Grid>
                            {
                                (situacao !== "Recebido" && situacao !== "Pago") && (
                                    <Grid item xs={12} sx={{display: "flex", gap: "2rem", justifyContent: "end"}}>
                                        <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, textAlign: "end"}}>
                                            <p style={{margin: 0}}>Saldo em aberto</p>
                                            <p style={{margin: 0, fontSize: 27, fontWeight: "bold"}}>R$ {format(saldoEmAberto)}</p>
                                        </span>
                                        {
                                            totalPago != 0 && (
                                                <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, textAlign: "end"}}>
                                                    <p style={{margin: 0}}>{situacao == "Recebido" ? "Total recebido" : "Total pago"}</p>
                                                    <p style={{margin: 0, fontSize: 27, fontWeight: "bold", color: GREEN_SUCCESS_UX}}>R$ {format(totalPago)}</p>
                                                </span>
                                            )
                                        }
                                    </Grid>
                                )
                            }
                    </Grid>
                    {hasRateio && (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <a>Informar categoria e centro de custo</a>
                            </Grid>
                            <Grid item xs={12} sx={{
                                border: 0,
                                borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`,
                                mb: 2.5,
                                ml: 2,
                            }}/>
                            <RateiosReceita
                                rateios={rateios}
                                setRateios={setRateios}
                                categoriaOptions={categorias}
                                centroCustoOptions={centroCusto}
                                valorCompra={valor}
                                setOpenCreateModal={setOpenCreateModal}
                                setOptionCreateModal={setOptionCreateModal}
                            />
                        </React.Fragment>

                    )}
                </Grid>
            </Paper>
            <Paper sx={{...paperStyle, backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE, backgroundImage: temaEscuro && "none", border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{marginTop: 2}} data-testid="condicao-pagamento-title" >
                        <span>
                            <p className="title-container-p">{lancamento.tipo_movimentacao == "Receita" ? "Condição de recebimento" : "Condição de pagamento"}</p>
                        </span>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} />
                    </Grid>
                    {
                        ((location.state.selectedChoice == "unico" && totalPagar == 0) || (valor > totalPago)) ? (
                            <React.Fragment>
                                <Grid item>
                                    <FilterDayMonthYear
                                        disabled={hasPago || saldoEmAberto > 0}
                                        disableMinDate
                                        style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                        handleSubmit={handleDayMonthYear}
                                        value={dataVencimento}
                                        setState={setDataVencimento}
                                        size={"small"}
                                        width={"250px"}
                                        iconPosition={"end"}
                                        label={"Vencimento"}
                                    />
                                </Grid>
                                <Grid item>
                                    <FilterSingleOption
                                        filterOptions={formaPagamentoOptions}
                                        selectedOption={selectedFormaPagamento}
                                        setSelectedOption={(e) => {
                                            setSelectedFormaPagamento(e);
                                        }}
                                        placeholder={"Forma de pagamento"}
                                    />
                                </Grid>
                                <Grid item>
                                    <FilterSingleOption
                                        filterOptions={contas}
                                        selectedOption={selectedConta}
                                        setSelectedOption={(e) => {
                                            setSelectedConta(e);
                                        }}
                                        placeholder={hasPago ? "Conta de pagamento *" : "Conta de pagamento"}
                                    />
                                </Grid>
                                {
                                    location.state.selectedChoice === "unico" && (
                                        <Grid item>
                                            <Checkbox
                                                checked={hasPago}
                                                onChange={(event) => {
                                                    setHasPago(event.target.checked);

                                                    if (event.target.checked) {
                                                        setValorPago(totalPagar);
                                                    }
                                                }}
                                            />
                                            <a style={{
                                                ...styleText,
                                                color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX,
                                                fontSize: "12px",
                                            }}>{lancamento.tipo_movimentacao ? "Recebido" : "Pago"}</a>
                                        </Grid>
                                    )
                                }
                                {
                                    !hasPago && (
                                        <Grid item>
                                            <Checkbox
                                                checked={hasAgendado}
                                                onChange={(event) => {
                                                    setHasAgendado(event.target.checked);
                                                }}
                                            />
                                            <a style={{
                                                ...styleText,
                                                color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX,
                                                fontSize: "12px",
                                            }}>{"Agendado"}</a>
                                        </Grid>
                                    )
                                }
                                {
                                    location.state.selectedChoice === "unico" && (
                                        <React.Fragment>
                                            <Grid item xs={12} sx={{marginTop: 3}}>
                                                {
                                                    lancamento.tipo_movimentacao === "Receita" ? (
                                                        <span>
                                                            <p className="title-container-p">{hasPago ? "Informações do recebimento" : "Previsão de recebimento"}</p>
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <p className="title-container-p">{hasPago ? "Informações do pagamento" : "Previsão de pagamento"}</p>
                                                        </span>
                                                    )
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                                            </Grid>
                                            <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "start", gap: 2, flexWrap: "wrap"}}>
                                                {
                                                    hasPago ? (
                                                        <FilterDayMonthYear
                                                            disableMinDate
                                                            handleSubmit={handleDayMonthYear}
                                                            value={selectedPrevisaoPagamento}
                                                            setState={setSelectedPrevisaoPagamento}
                                                            size={"small"}
                                                            width={"250px"}
                                                            iconPosition={"end"}
                                                            label={"Data do pagamento"}
                                                            inputError={inputError}
                                                        />
                                                    ) : (
                                                        <FilterDayMonthYear
                                                            disableMinDate
                                                            handleSubmit={handleDayMonthYear}
                                                            value={selectedPrevisaoPagamento}
                                                            setState={setSelectedPrevisaoPagamento}
                                                            size={"small"}
                                                            width={"250px"}
                                                            iconPosition={"end"}
                                                            label={"Previsão do pagamento"}
                                                            inputError={inputError}
                                                        />
                                                    )
                                                }
                                                <InputCurrency errorMessage="O valor de desconto não pode ser superior ao valor nominal da parcela" error={desconto > valor} valor={desconto} setValor={setDesconto} title={"Desconto"}/>
                                                <InputCurrency valor={juros} setValor={setJuros} title={"Juros"}/>
                                                <InputCurrency valor={multa} setValor={setMulta} title={"Multa"}/>
                                                <InputCurrency errorMessage="O valor nominal da baixa não pode ser superior ao saldo da parcela" error={valorPago > valor} disabled={!hasPago} valor={hasPago ? valorPago : totalPagar} setValor={hasPago ? setValorPago : setTotalPagar} title={hasPago ? "Valor pago *" : "Valor a pagar *"}/>
                                            </Grid>
                                        </React.Fragment>
                                    )
                                }
                            </React.Fragment>
                        ) : (
                            <Grid item xs={12} sx={{maxHeight: "30vh", mt: 3}}>
                                <TableContainer>
                                    <Table sx={{".MuiTableCell-root": {
                                                    padding: ".8rem 1rem",
                                                    fontWeight: "400",
                                                    textAlign: "center"
                                                }}}>
                                        <TableHead sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_PDF}}>
                                            <TableRow>
                                                <TableCell>Data</TableCell>
                                                <TableCell>Parcela</TableCell>
                                                <TableCell>Conta</TableCell>
                                                <TableCell>Valor</TableCell>
                                                <TableCell>Pago</TableCell>
                                                <TableCell>Em aberto</TableCell>
                                                <TableCell>Situação</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={lancamento.id}>
                                                <TableCell>{lancamento.data_movimentacao}</TableCell>
                                                <TableCell>1/1</TableCell>
                                                <TableCell>{selectedConta}</TableCell>
                                                <TableCell>{formatterCurrency(valor, 2)}</TableCell>
                                                <TableCell>{formatterCurrency(valorPago, 2)}</TableCell>
                                                <TableCell>{formatterCurrency(valorEmAberto, 2)}</TableCell>
                                                <TableCell>{checkSituacao(temaEscuro, situacao)}</TableCell>
                                            </TableRow>
                                            {
                                                valorEmAberto > 0 && (
                                                    <TableRow key={lancamento.id}>
                                                        <TableCell>{moment(dataVencimento).format("DD/MM/YYYY")}</TableCell>
                                                        <TableCell>1/1</TableCell>
                                                        <TableCell>{selectedConta}</TableCell>
                                                        <TableCell>{formatterCurrency(valorEmAberto, 2)}</TableCell>
                                                        <TableCell>{formatterCurrency(0, 2)}</TableCell>
                                                        <TableCell>{formatterCurrency(0, 2)}</TableCell>
                                                        <TableCell>{checkSituacao(temaEscuro, "Em Aberto")}</TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        )
                    }
                    {
                        (valor != totalPago && location.state.selectedChoice === "unico" && hasPago) && (
                            <React.Fragment>
                                <Grid item xs={12} sx={{mt: 2}}>
                                    <Paper sx={{ ...paperDash, ...dashLeftTitle, backgroundColor: temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none" }}>
                                        <Grid container>
                                            <Grid item xs={6} sx={{ display: "flex", alignItems: "start", justifyContent: "start" }}>
                                                <Grid container spacing={1} className="footer_periodo">
                                                    <Grid item xs={12}>
                                                        <span style={{ ...styles.title, ...(temaEscuro && { color: WHITE_ESCRITA_THEME }) }}>Totais</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6} sx={{display: "flex", justifyContent: "end", gap: 2}}>
                                                <Grid container xs={6} spacing={1} className="footer_valor">
                                                    <Grid item xs={6} sx={{ display: "flex", alignItems: "end", flexDirection: "column", gap: 1 }}>
                                                        <span style={{ ...styles.subTitle, ...(temaEscuro && { color: WHITE_THEME_BLACK }) }}>Valor em Aberto (R$)</span>
                                                        <span style={{ ...styles.subTitle, "color": DASH_KPI_NEGATIVE_COLOR }}>{formatterCurrency(valorEmAberto, 2)}</span>
                                                    </Grid>
                                                    <Grid item xs={6} sx={{ display: "flex", alignItems: "end", flexDirection: "column", gap: 1 }}>
                                                        <span style={{ ...styles.subTitle, ...(temaEscuro && { color: WHITE_THEME_BLACK }) }}> {lancamento.tipo_movimentacao == "Receita" ? "Total a receber (R$)" : "Total a pagar (R$)"}</span>
                                                        <span style={{ ...styles.subTitle, "color": BLUE_INFO_UX }}>{formatterCurrency(totalPagar, 2)}</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sx={{mt: 2}}>
                                    <Accordion sx={{backgroundColor: temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Grid container sx={{display: "flex", justifyContent: "space-between"}}>
                                                <span style={{fontSize: "18px", fontWeight: "bold"}}>Resumo da baixa</span>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{display: "flex"}}>
                                        <Grid item xs={12} sx={{maxHeight: "30vh", mt: 3}}>
                                            <TableContainer>
                                                <Table sx={{".MuiTableCell-root": {
                                                                padding: ".8rem 1rem",
                                                                fontWeight: "400",
                                                                textAlign: "center"
                                                            }}}>
                                                    <TableHead sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_PDF}}>
                                                        <TableRow>
                                                            <TableCell>Data</TableCell>
                                                            <TableCell>Forma de pagamento</TableCell>
                                                            <TableCell>Conta</TableCell>
                                                            <TableCell>Valor R$</TableCell>
                                                            <TableCell>Juros/Multa R$</TableCell>
                                                            <TableCell>Desconto/Tarifas R$</TableCell>
                                                            <TableCell>Situação</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow key={lancamento.id}>
                                                            <TableCell>{moment(selectedPrevisaoPagamento).format("DD/MM/YYYY")}</TableCell>
                                                            <TableCell>{selectedFormaPagamento}</TableCell>
                                                            <TableCell>{selectedConta}</TableCell>
                                                            <TableCell>{formatterCurrency(valorPago, 2)}</TableCell>
                                                            <TableCell>{formatterCurrency((multa + juros), 2)}</TableCell>
                                                            <TableCell>{formatterCurrency(desconto, 2)}</TableCell>
                                                            <TableCell>{checkSituacao(temaEscuro, lancamento.tipo_movimentacao === "Receita" ? "Recebido" : "Pago")}</TableCell>
                                                        </TableRow>
                                                        {
                                                            valorEmAberto > 0 && (
                                                                <TableRow key={lancamento.id}>
                                                                    <TableCell>{moment(dataVencimento).format("DD/MM/YYYY")}</TableCell>
                                                                    <TableCell>{selectedFormaPagamento}</TableCell>
                                                                    <TableCell>{selectedConta}</TableCell>
                                                                    <TableCell>{formatterCurrency(valorEmAberto, 2)}</TableCell>
                                                                    <TableCell>{formatterCurrency(0, 2)}</TableCell>
                                                                    <TableCell>{formatterCurrency(0, 2)}</TableCell>
                                                                    <TableCell>{checkSituacao(temaEscuro, "Em Aberto")}</TableCell>
                                                                </TableRow>
                                                            )
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                            <div style={{marginLeft: "46px"}}></div>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </React.Fragment>
                        )
                    }
                    <Grid item xs={8} sx={{backgroundColor: "transparent", marginBottom: "15px", marginTop: 3}}>
                        <Tabs
                            value={selectedTab}
                            onChange={(e, value) => setSelectedTab(value)}
                            TabIndicatorProps={{style: {backgroundColor: "transparent"}}}
                            className="buttonTabs"
                        >
                            <Tab
                                data-testid="observacoes-tab"
                                value={"observacoes"}
                                label="Observações"
                                sx={{
                                    borderRadius: "20px",
                                    color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                    backgroundColor: selectedTab === "observacoes" ? YELLOW_BG_UX : "transparent",
                                    "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                        color: BLACK_LABEL_UX,
                                    },
                                }}
                            />
                            <Tab
                                data-testid="anexos-tab"
                                value={"anexo"}
                                label="Anexo"
                                sx={{
                                    borderRadius: "20px",
                                    color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                    backgroundColor: selectedTab === "anexo" ? YELLOW_BG_UX : "transparent",
                                    "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                        color: BLACK_LABEL_UX,
                                    },
                                }}
                            />
                            {
                                (rateios.length > 1 && rateios[0].categoria !== null) && (
                                    <Tab
                                        data-testid="categoria-tab"
                                        value={"categoria_info"}
                                        label="Informações de categoria"
                                        sx={{
                                            borderRadius: "20px",
                                            color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                            backgroundColor: selectedTab === "categoria_info" ? YELLOW_BG_UX : "transparent",
                                            "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                color: BLACK_LABEL_UX,
                                            },
                                        }}
                                    />
                                )
                            }
                        </Tabs>
                    </Grid>
                    {selectedTab === "observacoes" ? (
                        <Grid item xs={12}>
                            <TextField
                                data-testid="observacoes-textfield"
                                label={"Observações"}
                                id="input-observacoes-textfield"
                                variant="outlined"
                                fullWidth
                                multiline
                                maxRows={6}
                                value={observacoes}
                                onChange={(event) => {
                                    setObservacoes(event.target.value);
                                }}
                                sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
                            />
                        </Grid>
                    ) : selectedTab == "anexo" ? (
                        <Anexos
                            loading={loadingAnexos}
                            anexos={anexos}
                            setAnexos={setAnexos}
                        />
                    ) : (
                        <TableContainer sx={{".MuiTableCell-root": {
                            padding: ".8rem 1rem",
                            fontWeight: "400",
                            textAlign: "center"
                        }}}>
                            <Table>
                                <TableHead sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_PDF}}>
                                    <TableRow>
                                        <TableCell>
                                            Categoria
                                        </TableCell>
                                        <TableCell>
                                            Valor
                                        </TableCell>
                                        <TableCell>
                                            Porcentagem
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rateios.map((rateio, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {rateio.categoria__nome}
                                            </TableCell>
                                            <TableCell>
                                                {formatterCurrency(rateio.valor, 2)}
                                            </TableCell>
                                            <TableCell>
                                                {formatterPercentage(rateio.percentual, 2)}%
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                    <Grid item xs={12} sx={{display: "flex", justifyContent: "end", gap: 2}}>
                        <DefaultButton
                            title={"Voltar"}
                            onClick={handleNavExtrato}
                        />
                        <Grid sx={{display: "flex"}}>
                            <DefaultButton
                                testId={"salvar-button"}
                                title={"Salvar"}
                                onClick={() => editLancamento()}
                                borderRadius={{borderRadius: "5px 0 0 5px"}}
                                variant="yellow"
                            />
                            <SaveOptions
                                action={""}
                                cleanStates={""}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

const styles = {
	title: {
		fontSize: "20px",
		fontWeight: "bold",
		color: DASH_KPI_COLOR
	},
	subTitle: {
		fontSize: "14px",
		fontWeight: "bold",
		color: DASH_KPI_DATA_COLOR
	},
};

export default EditarLancamentoRecorrente;