import React, {useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {Link as LinkRouter} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import api from "../../axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Loading from "../../components/Loading/Loading";
import CopyRight from "../../components/CopyRight/CopyRight";
import { GRAY_LABEL_UX, RED_ERROR_UX, GRAY_HEADER_UX, GRAY_BORDER_TABLE, LINE_TABLE, GRAY_BG_BODY, YELLOW_BG_UX, BLACK_BUTTON } from "../../shared/utils";
import "./Login.css";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: "Inter, cursive",
            color: GRAY_LABEL_UX,
            fontSize: "17px",
            fontWeight: "500",
        },
        button: {
            textTransform: "none",
        }
    },
});


export default function ForgotPwd() {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    
    const handleSubmit = () => {
		const data = {
			email
		};
		setLoading(true);
		api.SendResetPwdEmail(data).then(() => {
			setLoading(false);
            navigate("/check_code", {state: { email }});
		}).catch(() => {
			setLoading(false);
            setEmailError(true);
		});
	};

    const validateEmail = (email) => {
        if (email === "") {
            setEmailError(false);
            return;
        }
        if (email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            setEmailError(false);
        } else {
            setEmailError(true);
        }
    };

    return (
        <Box 
            sx={{
                backgroundColor: GRAY_BG_BODY,
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Grid container sx={{backgroundColor: LINE_TABLE, width: "500px", borderRadius: 2, border: `1px solid ${GRAY_BORDER_TABLE}`, padding: 2}}>
                <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <p style={styles.header}>Esqueci minha senha</p>
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <h1 style={styles.text}>Para recuperar sua senha, digite abaixo o e-mail que você utiliza para acessar o nosso sistema</h1>
                </Grid>
                <Grid item xs={12} sx={{marginBottom: 4}}>
                    <ThemeProvider theme={theme}>
                        <Box component="form" sx={{ marginTop: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <TextField
                                data-testid="email-input"
                                margin="normal"
                                fullWidth
                                label="Email"
                                autoComplete="email"
                                autoFocus
                                onChange={(e) => {setEmail(e.target.value.trim()); validateEmail(e.target.value.trim());}}
                                value={email}
                                variant="outlined"
                                error={emailError}
                                sx={{ 
                                    width: "80%",
                                }}
                            />
                            {
                                emailError && (
                                    <p data-testid="email-error" style={{color: RED_ERROR_UX, fontSize: "12px", marginTop: "-2px"}}>Insira um e-mail válido</p>
                                )
                            }
                            {
                                loading ? (
                                    <Loading />
                                ) : (
                                    <ThemeProvider theme={theme}>
                                        { emailError ? (
                                            <Button
                                                data-testid="unabled-submit-button"
                                                disabled="true"
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                                style={{fontWeight: "600", marginBottom: "30px", width: "80%"}}
                                            >
                                                Enviar código de acesso
                                            </Button>
                                        ) : (
                                            <Button
                                                data-testid="submit-button"
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                                onClick={() => handleSubmit()}
                                                style={{
                                                    backgroundColor: YELLOW_BG_UX,
                                                    color: BLACK_BUTTON,
                                                    border: `1px solid ${YELLOW_BG_UX}`,
                                                    fontWeight: "600",
                                                    boxShadow: "none",
                                                    width: "80%"
                                                }}
                                            >
                                                Enviar código de acesso
                                            </Button>
                                            )
                                        }
                                    </ThemeProvider>
                                )
                            }
                            <Grid container>
                                <Grid item xs={12}>
                                    <LinkRouter data-testid="redirect-back-login" to='/' variant="body2" style={{textDecoration: "none", color: GRAY_LABEL_UX, fontWeight: "600", fontSize: "14px", display: "flex", justifyContent: "center"}}>
                                        Voltar ao login
                                    </LinkRouter>
                                </Grid>
                            </Grid>
                        </Box>
                    </ThemeProvider>
                </Grid>
                <div style={styles.copyright}>
                    <CopyRight color="black"/>
                </div>
            </Grid>
        </Box>
    );
}

const styles = {
    center: {
        width: "60%",
        marginLeft: "auto",
        marginRight: "auto",
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    logo: {
        userDrag: "none",
        userSelect: "none",
        pointerEvents: "none",
        width: "191px",
        height: "36px"
    },
    header: {
        width: "398px",
        height: "15px",
        fontFamily: "Kallisto",
        fontWeight: "bold",
        fontSize: "36px",
        lineHeight: "32px",
        color: GRAY_HEADER_UX,
    },
    text: {
        width: "411px",
        height: "50px",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "20px",
        color: GRAY_LABEL_UX,
        textAlign: "center",
    },
    copyright: {
        textAlign: "center",
        display: "flex",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "end",
        paddingBottom: "40px",
    },
    buttonSubmit: {
        marginTop: 3,
        marginBottom: 2,
        width: "80%",
    }
};

