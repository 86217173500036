import React, {useEffect, useState} from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import {
    BLUE_THEME,
    WHITE_ESCRITA_THEME,
    BORDER_BUTTON,
    GRAY_BG_UX, styleText, GRAY_LABEL_UX
} from "../../shared/utils";
import MenuItem from "@mui/material/MenuItem";
import {useSelector} from "react-redux";
import InputsApelidoTipo from "./InputsApelidoTipo";
import FilterDayMonthYear from "../Filter/FilterDayMonthYear";
import InputCurrency from "../Input/InputCurrency";
import Grid from "@mui/material/Grid";
import moment from "moment";

const FormContaCorrente = (props) => {

    const {
        bancos,
        setInputBanco,
        inputBanco,
        inputModalidade,
        setInputModalidade,
        modalidades,
        isContaPadrao,
        setIsContaPadrao,
        inputTipoConta,
        setInputTipoConta,
        contaDetails,
        tipoConta,
        inputApelido,
        setInputApelido,
        isBancoEdit,
        inputSaldoInicial,
        setInputSaldoInicial,
        selectedDate,
        handleDayMonthYear
    } = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const getLastDate = (date) => {
        const [day, month, year] = date.split("/");
        const lastDate = new Date(`${year}-${month}-${day}`);

        lastDate.setDate(lastDate.getDate() - 1);

        return lastDate.toLocaleDateString("pt-BR");
    };

    const [lastDate, setLastDate] = useState(contaDetails.data_competencia ?? getLastDate(selectedDate));

    useEffect(() => {
        setLastDate(getLastDate(selectedDate));
    }, [selectedDate]);

    return (
        <React.Fragment>
           <InputsApelidoTipo
                inputTipoConta={inputTipoConta}
                isBancoEdit={isBancoEdit}
                contaDetails={contaDetails}
                setInputTipoConta={setInputTipoConta}
                tipoConta={tipoConta}
                inputApelido={inputApelido}
                setInputApelido={setInputApelido}
            />
            <FormControl size="small" sx={{width: "250px"}}>
                <InputLabel id="demo-simple-select-label" sx={{ color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }}>Banco</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={inputBanco}
                    label="Banco"
                    defaultValue={contaDetails.banco}
                    disabled={isBancoEdit}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                    }}
                    onChange={(e) => setInputBanco(e.target.value)}
                >
                    {bancos.map((banco) => {
                        return (
                            <MenuItem data-testid="menu-item" key={banco} value={banco}>
                                {banco}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <FormControl size="small" sx={{width: "250px"}}>
                <InputLabel id="demo-simple-select-label" sx={{ color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }}>Modalidade</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={inputModalidade}
                    label="Banco"
                    defaultValue={contaDetails.modalidade}
                    disabled={isBancoEdit}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                    }}
                    onChange={(e) => setInputModalidade(e.target.value)}
                >
                        {modalidades.map((mod) => {
                        return (
                            <MenuItem data-testid="menu-item" key={mod} value={mod}>
                                {mod}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <FormControlLabel 
                control={
                    <Checkbox
                        checked={isContaPadrao}
                        onChange={(e) => setIsContaPadrao(e.target.checked)}
                        inputProps={{ "aria-label": "controlled" }}
                    />
                } 
                label="Conta Padrão"
                sx={{
                    "& .MuiTypography-root": {
                        color: temaEscuro && WHITE_ESCRITA_THEME,
                        fontSize: "15px",
                    }
                }}
            />
            <Grid container sx={{display: "flex", mt: 1.5}} spacing={2}>
                <Grid item xs={12} sx={{display: "flex", alignItems: "start", flexDirection: "column"}}>
                    <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Saldo Inicial</span>
                </Grid>
                <Grid item xs={12} sx={{display: "flex", alignItems: "start", justfiyContent: "center", flexDirection: "row"}}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <FilterDayMonthYear
                                handleSubmit={handleDayMonthYear}
                                value={moment(contaDetails.data_competencia, "YYYY-MM-DD").toDate()}
                                label="Data do saldo"
                                width="250px"
                                isEditConta
                            />
                        </Grid>
                        <Grid item>
                            <InputCurrency valor={inputSaldoInicial} setValor={setInputSaldoInicial} title={`Saldo final da conta em ${lastDate}`} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default React.memo(FormContaCorrente);