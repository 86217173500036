import React from "react";
import Grid from "@mui/material/Grid";
import importIlustration from "../../assets/images/import_planilha.png";
import DefaultButton from "../Buttons/DefaultButton";


const ImportFileLastStep = ({fecharImportar}) => {
    return (
        <React.Fragment>
            <Grid container xs={12} sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 5}}>
                <Grid item xs={6} sx={{display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center"}}>
                    <img src={importIlustration}/>
                    <h3>Importamos os lançamentos no VXMF com sucesso!</h3>
                    <span style={{fontSize: "14px", lineHeight: "20px"}}>
                        Os dados foram importados com sucesso e já estão disponíveis para você acessar!
                    </span>
                </Grid>
                <Grid item>
                    <DefaultButton
                        title="Fechar"
                        onClick={fecharImportar}
                        variant="yellow"
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default ImportFileLastStep;