import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    paperStyle,
    PAPER_PADDING_THEME,
    LINE_TABLE,
    BORDER_PAPER,
    BORDER_BLACK_THEME_CARD,
    BLUE_THEME,
    BORDER_BUTTON,
    RED_ERROR_UX,
    BORDER_TABLE,
    WHITE_ESCRITA_THEME,
    RED_INFO,
    GRAY_BG_UX,
    GRAY_STRONG_UX,
    TITLE_SIZE,
} from "../../../shared/utils";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import DefaultButton from "../../../components/Buttons/DefaultButton";
import FilterDayMonthYear from "../../../components/Filter/FilterDayMonthYear";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import api from "../../../axios";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Anexos from "../../../components/Tabs/Anexos";
import InputCurrency from "../../../components/Input/InputCurrency";
import moment from "moment";
import { useHotkeys } from "react-hotkeys-hook";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CompletarTransferencia = ({
    open,
    setOpen,
    lancamento,
    conta,
    contaPara,
    indexConciliacao,
    handleAddMovimentacao,
}) => {
    const dateParts = lancamento.data_lancamento.split("/");
    const dataLancamento = new Date(
        `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`
    );

    const isLargeScreen = useMediaQuery("(min-width:1640px)");

    const [allContas, setAllContas] = useState([]);
    const [contaOrigem, setContaOrigem] = useState(conta.label);
    const [contaDestino, setContaDestino] = useState(contaPara.label);
    const [valor, setValor] = useState(Math.abs(lancamento.valor_lancamento));
    const [dataTransferencia, setDataTransferencia] = useState(dataLancamento);
    const [descricao, setDescricao] = useState(lancamento.descricao);
    const [anexos, setAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);

    const [prevContaOrigem, setPrevContaOrigem] = useState("");
    const [prevContaDestino, setPrevContaDestino] = useState("");
    const [prevValor, setPrevValor] = useState(0);
    const [prevAnexos, setPrevAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);
    const [changesList, setChangesList] = useState([]);

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const temaEscuro = useSelector((state) => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(
        (state) => state.StoreReducer.estabelecimentoId
    );

    useHotkeys("ctrl+z", () => {
        const change = changesList.pop();

        switch (change) {
            case "contaOrigem":
                setContaOrigem(prevContaOrigem);
                break;
            case "contaDestino":
                setContaDestino(prevContaDestino);
                break;
            case "valor":
                setValor(prevValor);
                break;
            case "anexos":
                setAnexos(prevAnexos);
                break;
            default:
                break;
        }
    });

    useEffect(() => {
        if (estabelecimentoId !== 0) {
            getContas();
        }
    }, [estabelecimentoId]);

    const handleDayMonthYear = (ano, mes, dia, setState) => {
        setState(`${dia}/${mes}/${ano}`);
    };

    const checkData = () => {
        if (contaOrigem === "" || contaDestino === "") {
            setError(true);
            setErrorMessage("As contas de origem e destino são obrigatórias");
        } else if (contaOrigem === contaDestino) {
            setError(true);
            setErrorMessage(
                "As contas de origem e destino não podem ser iguais"
            );
        } else if (valor <= 0) {
            setError(true);
            setErrorMessage("O valor da transferência deve ser maior que zero");
        } else if (dataTransferencia === "") {
            setError(true);
            setErrorMessage("A data de transferência é obrigatória");
        } else {
            setError(false);
            setErrorMessage("");
            postTranferencia();
        }
    };

    const getContas = () => {
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
        };

        api.GetContasTransferencia(dataRequest).then((response) => {
            setAllContas(response.data);
        });
    };

    const postTranferencia = () => {
        const formData = new FormData();
        formData.append("estabelecimento_id", estabelecimentoId);
        formData.append("conta_origem", contaOrigem);
        formData.append("conta_destino", contaDestino);
        formData.append("valor", valor);
        formData.append(
            "data_transferencia",
            moment(dataTransferencia).format("DD/MM/YYYY")
        );
        formData.append("descricao", descricao);

        if (anexos[0].arquivo != null) {
            formData.append("quantidade_anexo", anexos.length);
            anexos.forEach((anexo, index) => {
                formData.append(
                    `anexos[${index}][forma_anexo]`,
                    anexo.forma_anexo
                );
                formData.append(`anexos[${index}][arquivo]`, anexo.arquivo);
                formData.append(
                    `anexos[${index}][tipo_anexo]`,
                    anexo.tipo_anexo
                );
                formData.append(`anexos[${index}][descricao]`, anexo.descricao);
            });
        }

        api.PostTransferencia(formData).then((response) => {
            handleAddMovimentacao(indexConciliacao, lancamento.id, {
                id: response.data.id,
                apelido_conta: conta.label,
                codigo_referencia: "Sem NFE",
                data_movimentacao:
                    moment(dataTransferencia).format("DD/MM/YYYY"),
                desconto: 0,
                descricao: descricao,
                nome_categoria: null,
                nome_fornecedor: null,
                tipo_movimentacao: "Transferência",
                valor_total: valor,
            });
            setOpen(false);
        });
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={() => setOpen(false)}
            TransitionComponent={Transition}
            PaperProps={{
                style: {
                    backgroundColor: BLUE_THEME,
                },
            }}
        >
            <AppBar
                sx={{
                    backgroundColor: temaEscuro
                        ? PAPER_PADDING_THEME
                        : LINE_TABLE,
                    backgroundImage: temaEscuro && "none",
                    borderBottom: temaEscuro
                        ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                        : `1px solid ${BORDER_PAPER}`,
                    padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                    position: "relative",
                }}
            >
                <Toolbar>
                    <Typography sx={{ color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX, fontSize: TITLE_SIZE, fontWeight: "bold", lineHeight: 1, flex: 1 }}>
                        Nova transferência
                    </Typography>
                    <IconButton
                        autoFocus
                        color="inherit"
                        onClick={() => setOpen(false)}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div style={{ overflow: "auto", maxHeight: "100vh" }}>
                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro
                            ? PAPER_PADDING_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        border: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        margin: `20px ${
                            isLargeScreen ? "120px" : "20px"
                        } 20px ${isLargeScreen ? "120px" : "20px"}`,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <span>
                                <p className="title-container-p">
                                    Informações da gerais
                                </p>
                            </span>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider
                                sx={{
                                    backgroundColor: temaEscuro
                                        ? BORDER_TABLE
                                        : WHITE_ESCRITA_THEME,
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: "flex",
                                alignItems: "start",
                                gap: 2,
                                flexWrap: "wrap",
                                justifyContent: "start",
                            }}
                        >
                            <FormControl
                                size="small"
                                sx={{ width: "250px" }}
                                required
                            >
                                <InputLabel
                                    id="demo-simple-select-label"
                                    sx={{
                                        color: temaEscuro && BORDER_BUTTON,
                                        fontSize: "15px",
                                        "& .MuiFormLabel-asterisk": {
                                            color: RED_INFO,
                                        },
                                    }}
                                >
                                    Conta de origem
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Conta de origem"
                                    value={contaOrigem}
                                    sx={{
                                        backgroundColor: temaEscuro
                                            ? BLUE_THEME
                                            : GRAY_BG_UX,
                                        width: "250px",
                                        "& .MuiInputBase-input": {
                                            color:
                                                temaEscuro &&
                                                WHITE_ESCRITA_THEME,
                                        },
                                    }}
                                    onChange={(e) => {
                                        setChangesList([
                                            ...changesList,
                                            "contaOrigem",
                                        ]);
                                        setPrevContaOrigem(contaOrigem);
                                        setContaOrigem(e.target.value);
                                    }}
                                >
                                    {allContas.map((cv) => {
                                        return (
                                            <MenuItem
                                                data-testid="menu-item"
                                                key={cv}
                                                value={cv}
                                            >
                                                {cv}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl
                                size="small"
                                sx={{ width: "250px" }}
                                required
                            >
                                <InputLabel
                                    id="demo-simple-select-label"
                                    sx={{
                                        color: temaEscuro && BORDER_BUTTON,
                                        fontSize: "15px",
                                        "& .MuiFormLabel-asterisk": {
                                            color: RED_INFO,
                                        },
                                    }}
                                >
                                    Conta de destino
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Conta de destino"
                                    value={contaDestino}
                                    sx={{
                                        backgroundColor: temaEscuro
                                            ? BLUE_THEME
                                            : GRAY_BG_UX,
                                        width: "250px",
                                        "& .MuiInputBase-input": {
                                            color:
                                                temaEscuro &&
                                                WHITE_ESCRITA_THEME,
                                        },
                                    }}
                                    onChange={(e) => {
                                        setChangesList([
                                            ...changesList,
                                            "contaDestino",
                                        ]);
                                        setPrevContaDestino(contaDestino);
                                        setContaDestino(e.target.value);
                                    }}
                                >
                                    {allContas.map((cv) => {
                                        return (
                                            <MenuItem
                                                data-testid="menu-item"
                                                key={cv}
                                                value={cv}
                                            >
                                                {cv}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <InputCurrency
                                valor={valor}
                                setValor={setValor}
                                setPrevValor={setPrevValor}
                                changesList={changesList}
                                setChangesList={setChangesList}
                                title="Saldo *"
                                dataTestId="input-faturamento-bruto"
                            />
                            <FilterDayMonthYear
                                style={{
                                    backgroundColor: temaEscuro && BLUE_THEME,
                                    ...styles.textStyle,
                                }}
                                handleSubmit={handleDayMonthYear}
                                setState={setDataTransferencia}
                                value={dataTransferencia}
                                size={"small"}
                                width={"266px"}
                                iconPosition={"end"}
                                label={"Data de transferência"}
                                isContaPoupanca
                            />
                            <TextField
                                required
                                label="Descrição"
                                value={descricao}
                                placeholder="Descrição da transferência"
                                sx={{
                                    ...styles.textStyle,
                                    backgroundColor: temaEscuro && BLUE_THEME,
                                    width: "266px",
                                    "& .MuiFormLabel-asterisk": {
                                        color: RED_INFO,
                                    },
                                }}
                                onChange={(event) => {
                                    setDescricao(event.target.value);
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: temaEscuro && BORDER_BUTTON,
                                    },
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: 2 }}>
                            <span>
                                <p className="title-container-p">Anexos</p>
                            </span>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider
                                sx={{
                                    backgroundColor: temaEscuro
                                        ? BORDER_TABLE
                                        : WHITE_ESCRITA_THEME,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Anexos
                                anexos={anexos}
                                setAnexos={setAnexos}
                                changesList={changesList}
                                setChangesList={setChangesList}
                                setPrevAnexos={setPrevAnexos}
                                isTransferencia
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: "flex",
                                gap: 2,
                                alignItems: "center",
                                justifyContent: "end",
                                marginTop: 3,
                            }}
                        >
                            <DefaultButton
                                title="Voltar"
                                onClick={() => window.history.back()}
                            />
                            <DefaultButton
                                disabled={
                                    contaOrigem === "" ||
                                    contaDestino === "" ||
                                    valor <= 0 ||
                                    dataTransferencia === ""
                                }
                                title="Salvar"
                                onClick={checkData}
                                
                                variant="yellow"
                            />
                        </Grid>
                        {error && (
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    fontSize: "14px",
                                    color: RED_ERROR_UX,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "end",
                                    mt: 1,
                                }}
                            >
                                {errorMessage}
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </div>
        </Dialog>
    );
};

const styles = {
    textStyle: {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "28px",
    },
    numberInputStyle: {
        margin: 0,
        padding: "0 0 0 .7rem",
        width: "266px",
        height: "38px",
        borderRadius: "4px",
    },
};

export default CompletarTransferencia;
