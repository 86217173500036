import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import BlocoLancamentoBanco from "./BlocoLancamentoBanco";
import BlocoLancamentoVXMF from "./BlocoLancamentoVXMF";
import ConfirmDialog from "../Modal/ConfirmDialog";
import CompletarMovimentacao from "../../containers/Contas/CompletarInformacoes/CompletarInformacoes";
import CompletarTransferencia from "../../containers/Contas/CompletarInformacoes/CompletarTransferencia";
import DefaultButton from "../Buttons/DefaultButton";

const BlocoConciliacao = ({
    conciliacao,
    index,
    handleRemoveRow,
    handleCriarConciliar,
    handleConciliar,
    handleAddMovimentacao,
    conta,
    categoriasOptions,
    contasOptions,
    setSelectedLancamento,
    setOpenModalBuscarLanc,
    handleUnlinkMovimentacao,
}) => {
    const [selectedCategoria, setSelectedCategoria] = useState(null);
    const [selectedContaDestino, setSelectedContaDestino] = useState(null);
    const [selectedMovType, setSelectedMovType] = useState("movimentacao");
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openCompletarInfoDialog, setOpenCompletarInfoDialog] =
        useState(false);
    const [openCompletarTransfDialog, setOpenCompletarTransfDialog] =
        useState(false);

    const handleCompletarDialog = () => {
        if (selectedMovType === "movimentacao") {
            setOpenCompletarInfoDialog(true);
        } else {
            setOpenCompletarTransfDialog(true);
        }
    };

    return (
        <React.Fragment key={index}>
            <ConfirmDialog
                title="Criar novo lançamento e conciliar"
                description="Um novo lançamento será criado no VXMF e será conciliado. "
                buttonTitle="Criar e conciliar"
                callback={() => {
                    handleCriarConciliar(
                        conciliacao.extrato.id,
                        selectedMovType,
                        selectedContaDestino?.label,
                        selectedCategoria?.value
                    );
                    setOpenConfirmDialog(false);
                }}
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
            />
            {openCompletarInfoDialog && (
                <CompletarMovimentacao
                    open={openCompletarInfoDialog}
                    setOpen={setOpenCompletarInfoDialog}
                    lancamento={conciliacao.extrato}
                    conta={conta}
                    categoria={selectedCategoria}
                    indexConciliacao={index}
                    handleAddMovimentacao={handleAddMovimentacao}
                />
            )}
            {openCompletarTransfDialog && (
                <CompletarTransferencia
                    open={openCompletarTransfDialog}
                    setOpen={setOpenCompletarTransfDialog}
                    lancamento={conciliacao.extrato}
                    conta={conta}
                    contaPara={selectedContaDestino}
                    indexConciliacao={index}
                    handleAddMovimentacao={handleAddMovimentacao}
                />
            )}
            <Grid
                item
                xs={5}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <BlocoLancamentoBanco
                    conciliacao={conciliacao}
                    idx={index}
                    handleIgnore={handleRemoveRow}
                    apelidoConta={conta.label}
                />
            </Grid>
            <Grid
                item
                xs={1.5}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <DefaultButton
                    title={"Conciliar"}
                    size="small"
                    onClick={() =>
                        conciliacao.movimentacao === null
                            ? setOpenConfirmDialog(true)
                            : handleConciliar(conciliacao.extrato.id, [
                                  conciliacao.movimentacao.id,
                              ])
                    }
                    disabled={
                        (selectedMovType === "movimentacao"
                            ? !selectedCategoria
                            : !selectedContaDestino) &&
                        conciliacao.movimentacao === null
                    }
                    testId="btn-conciliar"
                    
                    variant="yellow"
                />
            </Grid>
            <Grid item xs={5.5}>
                <BlocoLancamentoVXMF
                    conciliacao={conciliacao}
                    idx={index}
                    categorias={categoriasOptions}
                    contas={contasOptions}
                    setSelectedLancamento={setSelectedLancamento}
                    setOpenModalBuscarLanc={setOpenModalBuscarLanc}
                    selectedCategoria={selectedCategoria}
                    setSelectedCategoria={setSelectedCategoria}
                    selectedConta={selectedContaDestino}
                    setSelectedConta={setSelectedContaDestino}
                    selectedMovType={selectedMovType}
                    setSelectedMovType={setSelectedMovType}
                    handleCompletarInfo={handleCompletarDialog}
                    handleUnlinkMovimentacao={handleUnlinkMovimentacao}
                />
            </Grid>
        </React.Fragment>
    );
};

export default BlocoConciliacao;
