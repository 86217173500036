import React, {useState, useEffect} from "react";
import Header from "../../components/Titles/Header";
import Paper from "@mui/material/Paper";
import {
    PAPER_PADDING_THEME,
    LINE_TABLE,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    paperStyle,
    PAPER_THEME,
    YELLOW_INFO_UX,
    BORDER_BUTTON,
    VERY_WEAK_YELLOW_COMPARATIVO,
    BLACK_SUBITEM_NAVBAR,
    YELLOW_BG_UX,
    getCurrentDateFormatted,
} from "../../shared/utils";
import { useSelector, useDispatch } from "react-redux";
import SelectEstabelecimento from "../../components/SelectEstabelecimento/SelectEstabelecimento";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import SettingsIcon from "@mui/icons-material/Settings";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DefaultButton from "../../components/Buttons/DefaultButton";
import SelectedTipoConta from "./StepsContas/SelectedTipoConta";
import StepOneContaCorrente from "./StepsContas/StepsContaCorrente.js/StepOneContaCorrente";
import StepTwoContaCorrente from "./StepsContas/StepsContaCorrente.js/StepTwoContaCorrente";
import StepThreeContaCorrente from "./StepsContas/StepsContaCorrente.js/StepThreeContaCorrente";
import api from "../../axios";
import StepOneCaixinha from "./StepsContas/StepsCaixinha/StepOneCaixinha";
import StepTwoCaixinha from "./StepsContas/StepsCaixinha/StepTwoCaixinha";
import StepOneCartaoCredito from "./StepsContas/StepsCartaoCredito/StepOneCartaoCredito";
import {showSnackMessage} from "../../actions/SnackActions";
import StepTwoCartaoCredito from "./StepsContas/StepsCartaoCredito/StepTwoCartaoCredito";
import StepOnePoupanca from "./StepsContas/StepsPoupanca/StepOnePoupanca";
import StepTwoPoupanca from "./StepsContas/StepsPoupanca/StepTwoPoupanca";
import StepOneInvestimento from "./StepsContas/StepsInvestimento/StepOneInvestimento";
import StepTwoInvestimento from "./StepsContas/StepsInvestimento/StepTwoInvestimento";
import StepTwoAplicacao from "./StepsContas/StepsAplicacaoAutomatica/StepTwoAplicacao";
import StepOneAplicacao from "./StepsContas/StepsAplicacaoAutomatica/StepOneAplicacao";
import StepOneOutraConta from "./StepsContas/StepsOutraConta/StepOneOutraConta";
import StepTwoOutraConta from "./StepsContas/StepsOutraConta/StepTwoOutraConta";
import moment from "moment";
import StepThreeComum from "./StepsContas/StepThreeComum";

const steps = ["Conta", "Cadastro", "Fatura", "Conclusão"];

const NewConta = () => {

    const [activeStep, setActiveStep] = useState(0);
    const [selectedCard, setSelectedCard] = useState(null);
    const [inputBanco, setInputBanco] = useState("");
    const [inputApelido, setInputApelido] = useState("");
    const [isContaPadrao, setIsContaPadrao] = useState(false);
    const [inputSaldoInicial, setInputSaldoInicial] = useState(0);
    const [selectedDate, setSelectedDate] = useState(null);
    const [checked, setChecked] = useState("PJ");
    const [showSaldoManual, setShowSaldoManual] = useState(false);
    const [showIntegracao, setShowIntegracao] = useState(false);
    const [progressComplete, setProgressComplete] = useState(false);
    const [renderSuccess, setRenderSuccess] = useState(false);
    const [bancos, setBancos] = useState([]);
    const [contasCorrentes, setContasCorrentes] = useState([]);
    const [modalidades, setModalidades] = useState([]);
    const [contasPagamento, setContasPagamento] = useState([]);
    const [bandeiras, setBandeiras] = useState([]);
    const [inputUltimosDigitos, setInputUltimosDigitos] = useState("");
    const [inputCartaoBandeira, setInputCartaoBandeira] = useState("");
    const [inputCartaoContaPagamento, setInputCartaoContaPagamento] = useState("");
    const [inputCartaoEmissor, setInputCartaoEmissor] = useState("");
    const [inputDiaVencimento, setInputDiaVencimento] = useState("");
    const [inputDiaFechamento, setInputDiaFechamento] = useState("");
    const [inputContaCorrenteVinculada, setInputContaCorrenteVinculada] = useState("");
    const [inputModalidade, setInputModalidade] = useState("");
    const [inputSaldoFinal, setInputSaldoFinal] = useState(0);
    const [clientId, setClientId] = useState("");
    const [clientSecret, setClientSecret] = useState("");
    const [selectedFile, setSelectedFile] = useState();
    const [selectedKeyFile, setSelectedKeyFile] = useState();


    const navigate = useNavigate();
    const dispatch = useDispatch();

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);

    useEffect(() => {
        if (estabelecimentoId !== 0) {
            getContas();
        }
    }, [estabelecimentoId]);

    const getContas = () => {
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
        };
        api.GetContas(dataRequest).then(response => {
            let dataResponse = response.data;
            setBancos(dataResponse.bancos);
            setContasPagamento(dataResponse.cartao_contas_pagamento);
            setBandeiras(dataResponse.bandeiras);
            setContasCorrentes(dataResponse.contas_corrente);
            setModalidades(dataResponse.modalidades);
        }).catch(() => {
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
        if (showSaldoManual) {
            setShowSaldoManual(false);
        }
        if (showIntegracao) {
            setShowIntegracao(false);
        }
        setInputSaldoInicial(0);
        setSelectedDate(null);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleBackContas = () => {
        navigate("/contas");
    };

    const handleDayMonthYear = (ano, mes, dia) => {
        setSelectedDate(`${ano}-${mes}-${dia}`);
    };

    const handleChangeChecked = (event) => {
        setChecked(event.target.value);
    };

    const disabledConfig = () => {
        const commonValidations = {
            inputApelido: inputApelido === "",
        };
    
        const config = {
            "Conta Corrente": {
                1: commonValidations.inputApelido || inputBanco === "",
                2: selectedDate == null,
            },
            "Caixinha": {
                1: commonValidations.inputApelido,
                2: selectedDate == null,
            },
            "Cartão de Crédito": {
                1: commonValidations.inputApelido || inputCartaoBandeira === "" || inputCartaoEmissor === "" || inputUltimosDigitos === "",
                2: inputDiaVencimento === "" || inputDiaFechamento === "",
            },
            "Poupança": {
                1: commonValidations.inputApelido || inputBanco === "" || inputModalidade === "",
                2: selectedDate == null,
            },
            "Investimento": {
                1: commonValidations.inputApelido || inputBanco === "",
                2: selectedDate == null,
            },
            "Aplicação Automática": {
                1: commonValidations.inputApelido || inputBanco === "",
                2: selectedDate == null,
            },
            "Outro tipo de conta": {
                1: commonValidations.inputApelido,
                2: selectedDate == null,
            },
        };
        
        if (showIntegracao) {
            if (inputBanco.nome === "Inter") {
                return clientId === "" || clientSecret === "" || selectedFile === undefined || selectedKeyFile === undefined;
            } else if (inputBanco.nome === "Sicoob") {
                return clientId === "";
            } else {
                return clientId === "" || clientSecret === "";
            }
        } else {
            return config[selectedCard]?.[activeStep] || false;
        }
    };

    const getStepComponent = (step, selectedCard) => {
        const stepComponents = {
            "Conta Corrente": {
                1: StepOneContaCorrente,
                2: StepTwoContaCorrente,
                3: StepThreeContaCorrente,
            },
            "Caixinha": {
                1: StepOneCaixinha,
                2: StepTwoCaixinha,
                3: StepThreeComum,
            },
            "Cartão de Crédito": {
                1: StepOneCartaoCredito,
                2: StepTwoCartaoCredito,
                3: StepThreeComum,
            },
            "Poupança": {
                1: StepOnePoupanca,
                2: StepTwoPoupanca,
                3: StepThreeComum,
            },
            "Investimento": {
                1: StepOneInvestimento,
                2: StepTwoInvestimento,
                3: StepThreeComum,
            },
            "Aplicação Automática": {
                1: StepOneAplicacao,
                2: StepTwoAplicacao,
                3: StepThreeComum,
            },
            "Outro tipo de conta": {
                1: StepOneOutraConta,
                2: StepTwoOutraConta,
                3: StepThreeComum,
            },
        };
    
        return stepComponents[selectedCard]?.[step] || null;
    };
    
    const getStepContent = (step) => {
        if (step === 0) {
            return <SelectedTipoConta setSelectedCard={setSelectedCard} handleNext={handleNext} />;
        }
    
        const StepComponent = getStepComponent(step, selectedCard);
        
        return (
            <StepComponent
                inputBanco={inputBanco}
                setInputBanco={setInputBanco}
                inputApelido={inputApelido}
                setInputApelido={setInputApelido}
                isContaPadrao={isContaPadrao}
                setIsContaPadrao={setIsContaPadrao}
                checked={checked}
                handleChangeChecked={handleChangeChecked}
                bancos={bancos}
                contasPagamento={contasPagamento}
                bandeiras={bandeiras}
                inputUltimosDigitos={inputUltimosDigitos}
                setInputUltimosDigitos={setInputUltimosDigitos}
                inputCartaoBandeira={inputCartaoBandeira}
                setInputCartaoBandeira={setInputCartaoBandeira}
                inputCartaoContaPagamento={inputCartaoContaPagamento}
                setInputCartaoContaPagamento={setInputCartaoContaPagamento}
                setInputCartaoEmissor={setInputCartaoEmissor}
                inputCartaoEmissor={inputCartaoEmissor}
                contasCorrentes={contasCorrentes}
                setInputContaCorrenteVinculada={setInputContaCorrenteVinculada}
                inputContaCorrenteVinculada={inputContaCorrenteVinculada}
                modalidades={modalidades}
                setInputModalidade={setInputModalidade}
                inputModalidade={inputModalidade}
                setInputSaldoFinal={setInputSaldoFinal}
                inputSaldoFinal={inputSaldoFinal}
                handleDayMonthYear={handleDayMonthYear}
                selectedDate={selectedDate}
                setShowSaldoManual={setShowSaldoManual}
                showSaldoManual={showSaldoManual}
                showIntegracao={showIntegracao}
                setShowIntegracao={setShowIntegracao}
                inputDiaVencimento={inputDiaVencimento}
                setInputDiaVencimento={setInputDiaVencimento}
                inputDiaFechamento={inputDiaFechamento}
                setInputDiaFechamento={setInputDiaFechamento}
                setInputSaldoInicial={setInputSaldoInicial}
                inputSaldoInicial={inputSaldoInicial}
                progressComplete={progressComplete}
                setProgressComplete={setProgressComplete}
                createConta={createConta}
                setRenderSuccess={setRenderSuccess}
                renderSuccess={renderSuccess}
                tipoConta={selectedCard}
                clientId={clientId}
                setClientId={setClientId}
                clientSecret={clientSecret}
                setClientSecret={setClientSecret}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                selectedKeyFile={selectedKeyFile}
                setSelectedKeyFile={setSelectedKeyFile}
            />
        );
        
    };

    const ColorlibConnector = styled(StepConnector)(() => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor: temaEscuro ? VERY_WEAK_YELLOW_COMPARATIVO : YELLOW_BG_UX
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor: temaEscuro ? VERY_WEAK_YELLOW_COMPARATIVO : YELLOW_BG_UX
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 10,
            border: 0,
            backgroundColor: BORDER_BUTTON,
            borderRadius: 10,
        },
    }));

    const ColorlibStepIconRoot = styled("div")(({  ownerState }) => ({
        backgroundColor: BORDER_PAPER,
        zIndex: 1,
        color: PAPER_THEME,
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        ...(ownerState.active && {
            backgroundColor: YELLOW_INFO_UX,
            boxShadow: temaEscuro ? `0 4px 10px 0 ${BLACK_SUBITEM_NAVBAR}` : `0 3px 3px 0 ${BLACK_SUBITEM_NAVBAR}`,
        }),
        ...(ownerState.completed && {
            color: PAPER_THEME,
            backgroundColor: YELLOW_INFO_UX
        }),
    }));
      
    const ColorlibStepIcon = (props) => {
        const { active, completed, className } = props;
      
        const icons = {
            1: <AddToPhotosIcon />,
            2: <SettingsIcon />,
            3: <RequestQuoteIcon />,
            4: <CheckCircleIcon />,
        };
      
        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className} >
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    };

    const createConta = (tipo) => {
        const formattedDate = selectedDate instanceof Date ? moment(selectedDate).format("DD-MM-YYYY") : selectedDate;
    
        const baseDataRequest = {
            estabelecimento_id: estabelecimentoId,
            apelido: inputApelido,
            conta_tipo: tipo,
            data_competencia: formattedDate || getCurrentDateFormatted()
        };
    
        const tipoContaHandlers = {
            "Conta Corrente": () => ({
                ...baseDataRequest,
                banco_nome: inputBanco.nome,
                modalidade: checked,
                saldo_inicial: inputSaldoFinal || 0,
                conta_padrao: isContaPadrao,
                ...(showIntegracao && {
                    client_id: clientId,
                    client_secret: clientSecret,
                }),
                ...(inputBanco.nome === "Inter" && {
                    file_name: selectedFile?.name,
                    key_file_name: selectedKeyFile?.name,
                    selected_file: selectedFile,
                    selected_key_file: selectedKeyFile
                }),
            }),
            "Caixinha": () => ({
                ...baseDataRequest,
                saldo_inicial: inputSaldoFinal,
            }),
            "Cartão de Crédito": () => ({
                ...baseDataRequest,
                cartao_emissor: inputCartaoEmissor,
                ultimos_digitos: inputUltimosDigitos,
                bandeira: inputCartaoBandeira,
                conta_pagamento: inputCartaoContaPagamento,
                dia_vencimento: inputDiaVencimento,
                dia_fechamento: inputDiaFechamento,
                banco_nome: inputCartaoEmissor,
            }),
            "Poupança": () => ({
                ...baseDataRequest,
                banco_nome: inputBanco.nome,
                conta_corrente_vinculada: inputContaCorrenteVinculada,
                modalidade: checked,
                saldo_inicial: inputSaldoInicial,
            }),
            "Investimento": () => ({
                ...baseDataRequest,
                banco_nome: inputBanco.nome,
                conta_corrente_vinculada: inputContaCorrenteVinculada,
                saldo_inicial: inputSaldoInicial,
            }),
            "Aplicação Automática": () => ({
                ...baseDataRequest,
                banco_nome: inputBanco.nome,
                conta_corrente_vinculada: inputContaCorrenteVinculada,
                saldo_inicial: inputSaldoInicial,
            }),
            "Outro tipo de conta": () => ({
                ...baseDataRequest,
                saldo_inicial: inputSaldoFinal,
            }),
        };
    
        const dataRequest = tipoContaHandlers[tipo]();

        const formData = new FormData();
        Object.entries(dataRequest).forEach(([key, value]) => {
            formData.append(key, value);
        });
        
        const response = api.CreateConta(formData);
        return response;
    };
    

    return (
        <div className="main" data-testid="new-conta">
            <Header title="Nova Conta" subTitle="Contas" previousPage="/contas" />
            <Paper
                sx={{
                    ...paperStyle,
                    backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE,
                    backgroundImage: temaEscuro && "none", 
                    border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`}}
                data-testid="perfil-screen"
            >
                {
                    estabelecimentoId === 0 ? (
                        <SelectEstabelecimento/> 
                    ) : (
                        <React.Fragment>
                            <Box sx={{ width: "60%", margin: "auto", paddingTop: 2 }}>
                                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                                    {steps.map((label) => {
                                        return (
                                            <Step key={label}>
                                                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            
                                {activeStep === steps.length ? (
                                    <React.Fragment>
                                        <Typography sx={{ mt: 2, mb: 1 }}>
                                            Todos os passos concluídos - você terminou
                                        </Typography>
                                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                                            <Box sx={{ flex: "1 1 auto" }} />
                                            <Button onClick={handleReset}>Resetar</Button>
                                        </Box>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Box sx={{ mt: 5, mb: 5 }}>
                                            {getStepContent(activeStep)}
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                                            {/* Renderiza o botão "Cancelar" na primeira etapa */}
                                            {activeStep === 0 && (
                                                <DefaultButton
                                                    title="Cancelar"
                                                    onClick={handleBackContas}
                                                    testId="btn-cancelar"
                                                />
                                            )}

                                            {/* Renderiza o botão "Voltar" nas etapas 1 e 2 */}
                                            {[1, 2].includes(activeStep) && (
                                                <DefaultButton
                                                    title="Voltar"
                                                    onClick={handleBack}
                                                />
                                            )}

                                            {/* Renderiza o botão "Voltar para as contas cadastradas" na etapa 3 */}
                                            {activeStep === 3 && (progressComplete || ["Caixinha", "Cartão de Crédito", "Poupança", "Investimento", "Aplicação Automática", "Outro tipo de conta"].includes(selectedCard)) && (
                                                <DefaultButton
                                                    title="Voltar para as contas cadastradas"
                                                    onClick={handleBackContas}
                                                />
                                            )}

                                            <Box sx={{ flex: "1 1 auto" }} />

                                            {/* Renderiza o botão "Próximo" nas etapas 1 e 2, se os campos estiverem preenchidos */}
                                            {(showSaldoManual || showIntegracao || activeStep === 1 || ["Caixinha", "Cartão de Crédito", "Poupança", "Investimento", "Aplicação Automática", "Outro tipo de conta"].includes(selectedCard)) && activeStep !== 3 && (
                                                <DefaultButton 
                                                    title="Próximo"
                                                    onClick={handleNext}
                                                    disabled={disabledConfig()}
                                                    testId="button-next"
                                                    
                                                    variant="yellow"
                                                />
                                            )}

                                            {activeStep === 3 && progressComplete && renderSuccess && (
                                                <DefaultButton
                                                    title="Concluir"
                                                    onClick={() => navigate("/contas")}
                                                    testId="concluir-button"
                                                    
                                                    variant="yellow"
                                                />
                                            )}
                                        </Box>
                                    </React.Fragment>
                                )}
                            </Box>
                        </React.Fragment>
                    )
                }
            </Paper>
        </div>
    );

};

export default NewConta;