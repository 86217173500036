import React, {useState, useEffect} from "react";
import Header from "../../../components/Titles/Header";
import Paper from "@mui/material/Paper";
import {
    PAPER_PADDING_THEME,
    paperStyle,
    LINE_TABLE,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
} from "../../../shared/utils";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import api from "../../../axios";
import {showSnackMessage} from "../../../actions/SnackActions";
import InputSearch from "../../../components/Input/InputSearch";
import FilterDayMonthYear from "../../../components/Filter/FilterDayMonthYear";
import FilterSingleOption from "../../../components/Filter/FilterSingleOption";
import Anexos from "../../../components/Tabs/Anexos";
import {useLocation, useNavigate} from "react-router-dom";
import moment from "moment";
import DefaultButton from "../../../components/Buttons/DefaultButton";
import { Skeleton } from "@mui/material";
import InputCurrency from "../../../components/Input/InputCurrency";


const EditarTransferencia = () => {

    const nav = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const id_movimentacao = (location?.state.id_movimentacao ?? "");

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);

    const [loadingAnexos, setLoadingAnexos] = useState(true);

    useEffect(() => {
        if (typeof(id_movimentacao) !== "undefined") {
            getInfoTransferencia();
            getAnexos();
        }
    }, [id_movimentacao]);

    const getAnexos = () => {
        setLoadingAnexos(true);

        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            baixa_id: id_movimentacao,
        };

        api.GetAnexos(dataRequest).then((response) => {
            const data = response.data;
            processAnexosData(data);
            setLoadingAnexos(false);
        });
    };

    const [loading, setLoading] = useState(false);
    const [contaOrigem, setContaOrigem] = useState("");
    const [contaDestino, setContaDestino] = useState("");
    const [descricao, setDescricao] = useState("");
    const [selectedDataTransferencia, setSelectedDataTransferencia] = useState("");
    const [valor, setValor] = useState(0);
    const [inputError, setInputError] = useState(false);
    const [anexos, setAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);

    const [contaOptions, setContaOptions] = useState([]);

    useEffect(() => {
        if (contaOrigem !== null && contaDestino !== null) {
            setDescricao(`Origem: ${contaOrigem} / Destino: ${contaDestino}`);
        }
    }, [contaOrigem, contaDestino]);

    const handleDayMonthYear = (ano, mes, dia, setState) => {
        setState(`${dia}/${mes}/${ano}`);
    };

    const formatDateIfNeeded = (date) => {
        return typeof(date) !== "object" ? date : moment(date).format("DD/MM/YYYY");
    };

    const processAnexosData = (anexos_data) => {
        if (anexos_data.length === 0) {
            setAnexos([
                {
                    forma_anexo: "Arquivo",
                    arquivo: null,
                    tipo_anexo: "Contrato",
                    descricao: "",
                }
            ]);
            return;
        }

        let anexos_list = [];

        for (let i = 0; i < anexos_data.length; i++) {
            const byteCharacters = atob(anexos_data[i].arquivo);
            const byteNumbers = new Array(byteCharacters.length);
    
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
    
            const byteArray = new Uint8Array(byteNumbers);
    
            const blob = new Blob([byteArray], { type: anexos_data[i].mime_type });
    
            let file = new File([blob], anexos_data[i].nome_arquivo, { type: anexos_data[i].mime_type });
            
            anexos_list.push({
                forma_anexo: anexos_data[i].forma_anexo,
                arquivo: file,
                tipo_anexo: anexos_data[i].tipo_anexo,
                descricao: anexos_data[i].descricao,
            });
        }

        setAnexos(anexos_list);
    };

    const getInfoTransferencia = () => {
        setLoading(true);
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            lancamento_id: id_movimentacao,
        };
        api.GetInfoMovimentacao(dataRequest).then((response) => {
            const data = response.data;
            setContaDestino(data.conta_destino);
            setContaOrigem(data.conta_origem);
            setContaOptions(data.contas);
            setDescricao(data.movimentacao.observacoes);
            setSelectedDataTransferencia(moment(data.movimentacao.data_competencia, "YYYY-MM-DD").toDate());
            setValor(data.movimentacao.valor_total);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const handleEditTransferencia = () => {
        if (!contaOrigem || !contaDestino || !selectedDataTransferencia || !valor) {
            setInputError(true);
            return;
        }
        setLoading(true);
        setLoadingAnexos(true);
        let formData = new FormData();
        formData.append("estabelecimento_id", estabelecimentoId);
        formData.append("lancamento_id", id_movimentacao);
        formData.append("conta_origem", contaOrigem);
        formData.append("conta_destino", contaDestino);
        formData.append("descricao_movimentacao", descricao);
        formData.append("data_compra", formatDateIfNeeded(selectedDataTransferencia));
        formData.append("valor_movimentacao", valor);
        formData.append("anexos", JSON.stringify(anexos));

        if (anexos[0].arquivo != null){
            formData.append("quantidade_anexo", anexos.length);
            anexos.forEach((anexo, index) => {
                formData.append(`anexos[${index}][forma_anexo]`, anexo.forma_anexo);
                formData.append(`anexos[${index}][arquivo]`, anexo.arquivo);
                formData.append(`anexos[${index}][tipo_anexo]`, anexo.tipo_anexo);
                formData.append(`anexos[${index}][descricao]`, anexo.descricao);
            });
        }

        api.EditarMovimentacao(formData).then(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Transferência editada com sucesso!", severity: "success"}));
            nav("/extrato_movimentacoes");
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const returnExtrato = () => {
        nav("/extrato_movimentacoes");
    };

    return (
        <div className="main" data-testid="exm-container">
            <Header title="Editar transferência" subTitle="EXM - Extrato de Movimentações" previousPage="/extrato_movimentacoes" dataTestId="header-exm" />
            <Paper
                sx={{
                    ...paperStyle,
                    backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE,
                    backgroundImage: temaEscuro && "none", 
                    border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`,
                    marginBottom: 2
                }} 
                data-testid="perfil-screen"
            >
                {loading ? (
                    [...Array(5).keys()].map((i,d) =>{
                        return(
                            <Skeleton key={d} height={50}/>
                        );
                    })
                ) : (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <span><p className="title-container-p">Informações da transferência</p></span>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                        </Grid>
                        <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "start", gap: 2, flexWrap: "wrap"}}>
                            <FilterSingleOption
                                filterOptions={contaOptions}
                                selectedOption={contaOrigem}
                                setSelectedOption={setContaOrigem}
                                placeholder="Conta de origem"
                                width={"250px"}
                                inputError={inputError}
                            />
                            <FilterSingleOption
                                filterOptions={contaOptions}
                                selectedOption={contaDestino}
                                setSelectedOption={setContaDestino}
                                placeholder="Conta de destino"
                                width={"250px"}
                                inputError={inputError}
                            />
                            <InputSearch
                                functionProps={() => {}}
                                setSearchValue={setDescricao}
                                searchValue={descricao}
                                label={"Descrição"}
                                width={"250px"}
                            />
                            <FilterDayMonthYear
                                handleSubmit={handleDayMonthYear}
                                value={selectedDataTransferencia}
                                setState={setSelectedDataTransferencia}
                                size={"small"}
                                width={"250px"}
                                iconPosition={"end"}
                                label={"Data da transferência"}
                                isEditConta
                                inputError={inputError}
                            />
                            <InputCurrency
                                valor={valor}
                                setValor={setValor}
                                title={"Valor"}
                                data-id={"input-faturamento-bruto"}
                            />
                            <Grid item xs={12} sx={{marginTop: 2}}>
                                <Anexos
                                    loading={loadingAnexos}
                                    anexos={anexos}
                                    setAnexos={setAnexos}
                                    isTransferencia
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Paper>
            <Grid container>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "end", gap: 2}}>
                    <DefaultButton
                        onClick={() => returnExtrato()}
                        title="Cancelar"
                    />
                    <DefaultButton
                        title={"Salvar"}
                        onClick={() => handleEditTransferencia()}
                        
                        variant="yellow"
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default React.memo(EditarTransferencia);