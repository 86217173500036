import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DefaultButton from "./DefaultButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
    BLACK_TABLE_THEME,
} from "../../shared/utils";
import EdicaoLoteModal from "../Modal/EdicaoLoteModal";


export default function OptionsNotasSelected(props) {

    const {notas, deleteNotas} = props;
    
    const [anchorEl, setAnchorEl] = useState(null);
    const [openEdicaoModal, setOpenEdicaoModal] = useState(false);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const moreVertIcon = useMemo(() => (
		<MoreVertIcon/>
	), []);


    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    
    return (
        notas?.length > 0 && (
            <React.Fragment>
                <DefaultButton
                    title="Ações em lote"
                    startIcon={moreVertIcon}
                    onClick={handleClick}
                    testId="options-menu"
                    className={props.className}
                />
                <EdicaoLoteModal
                    openModal={openEdicaoModal}
                    setOpenModal={setOpenEdicaoModal}
                    exmItems={notas}
                    setAnchorEl={setAnchorEl}
                />
                <Menu
                    sx={{
                        "& .css-kc02vp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper" : {
                            background: temaEscuro && BLACK_TABLE_THEME
                        }
                    }}
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <MenuItem
                        onClick={() => deleteNotas()}
                    >
                        Excluir
                    </MenuItem>
                    <MenuItem
                        onClick={() => setOpenEdicaoModal(true)}
                    >
                        Editar
                    </MenuItem>
                </Menu>
            </React.Fragment>
        )
    );
}