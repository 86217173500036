import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {
    styleText,
    WHITE_ESCRITA_THEME,
    GRAY_LABEL_UX,
    BORDER_TABLE,
    BLUE_THEME,
    BORDER_BUTTON,
    GRAY_BG_UX,
    LINE_TABLE,
    RED_INFO,
    WHITE_TABLE,
    translateAutocomplete,
    textCompleteStyleBlack,
    textCompleteStyle,
    BORDER_PAPER
} from "../../../../shared/utils";
import { useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import { Autocomplete, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";


const StepOneContaCorrente = (props) => {

    const {inputBanco, setInputBanco, inputApelido, setInputApelido, setIsContaPadrao, checked, handleChangeChecked, bancos} = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const [bancosFormatted, setBancosFormatted] = useState([]);

    // verifica se cada banco faz parte da lista de integração automática e ordena
    useEffect(() => {
        const formatBancos = bancos
            .map((banco) => ({
                nome: banco,
                temIntegracaoAutomatica: bancosComIntegracao.includes(banco),
            }))
            .sort((a, b) => b.temIntegracaoAutomatica - a.temIntegracaoAutomatica);

        setBancosFormatted(formatBancos);
    }, []);

    const stylePaper = {
        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
        color: temaEscuro ? "#FFF" : "#4A5568",
    };

    const bancosComIntegracao = ["Banco do Brasil", "Santander", "Sicoob", "Inter"];
    
    return (
        <Grid container spacing={2} data-testid="step-one-conta-corrente">
            <Grid item xs={12} sx={{borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, marginBottom: 2}}>
                <h3 style={{marginTop: 0, color: temaEscuro ? LINE_TABLE : GRAY_LABEL_UX, fontWeight: 700}}>Cadastre a conta corrente do seu banco</h3>
            </Grid>
            <Grid item xs={12} sx={{marginTop: 1}}>
                <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 600}}>Informe seu banco e crie um apelido para esta conta</span>
            </Grid>
            <Grid item xs={12} sx={{marginTop: 2, display: "flex", gap: 2, flexWrap: "wrap"}}>
                <Autocomplete
                    noOptionsText={translateAutocomplete}
                    size="small"
                    options={bancosFormatted}
                    data-testid="autocomplete-banco"
                    groupBy={(banco) =>
                        banco.temIntegracaoAutomatica ? "Possuem Integração Automática" : "Importação Manual do Extrato"
                    }
                    getOptionLabel={(banco) => banco.nome}
                    slotProps={{
                        paper: {style: stylePaper}
                    }}
                    style={{backgroundColor: temaEscuro && BLUE_THEME, width: "250px",}}
                    value={inputBanco || null} 
                    onChange={(e, banco) => setInputBanco(banco)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            sx={{
                                ...(temaEscuro ? textCompleteStyleBlack : textCompleteStyle),
                                "& .MuiFormLabel-asterisk": {
                                    color: RED_INFO,
                                },
                            }}
                            label={"Banco"}
                            InputLabelProps={{
                                style: {color: temaEscuro && params.inputProps.value === "" && BORDER_BUTTON},
                            }}
                            required
                        />
                    )}
                    renderGroup={(params) => (
                        <Grid container alignItems="center">
                            <Grid item xs={12} sx={{padding: 2}}>
                                <span style={{...styleText, color: temaEscuro ? LINE_TABLE : GRAY_LABEL_UX, fontWeight: 600}}>{params.group}</span>
                                <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER, marginTop: 1}} /> 
                            </Grid>
                            <Grid item xs={12}>
                                <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 500}}>{params.children}</span>
                            </Grid>
                        </Grid>
                    )}
                    PaperComponent={({ children }) => (
                        <Paper
                            style={{
                                backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
                                color: temaEscuro ? WHITE_TABLE : BLUE_THEME,
                                fontWeight: "500",
                            }}
                        >
                            {children}
                        </Paper>
                    )}
                />
                <TextField
                    label="Apelido"
                    id="outlined-size-small"
                    size="small"
                    data-testid="input-apelido"
                    value={inputApelido}
                    onChange={(event) => {
                        setInputApelido(event.target.value);
                    }}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                        "& .MuiFormLabel-asterisk": {
                            color: RED_INFO,
                        }
                    }}
                    InputLabelProps={{
                        style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }, 
                    }}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel 
                    control={
                        <Checkbox
                            onChange={(e) => setIsContaPadrao(e.target.checked)}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    } 
                    label="Quero que esta seja minha conta padrão"
                    sx={{
                        "& .MuiTypography-root": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                            fontSize: "15px",
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12} sx={{marginTop: 2, display: "flex", gap: 2}}>
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label" sx={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 600, marginBottom: 2}}>Tipo de conta</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        value={checked}
                        onChange={handleChangeChecked}
                    >
                        <FormControlLabel value="PJ" control={<Radio />} label="Conta Pessoa Jurídica (PJ)"  sx={{"& .MuiTypography-root": {fontSize: "14px", color: temaEscuro && WHITE_ESCRITA_THEME}}} />
                        <FormControlLabel value="PF" control={<Radio />} label="Conta Pessoa Física (PF)" sx={{"& .MuiTypography-root": {fontSize: "14px", color: temaEscuro && WHITE_ESCRITA_THEME}}} />
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default React.memo(StepOneContaCorrente);