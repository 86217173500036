import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import {
    DASH_KPI_NEGATIVE_COLOR,
    DASH_KPI_POSITIVE_COLOR,
    GRAY_LABEL_UX,
    styleText,
    WHITE_ESCRITA_THEME,
    YELLOW_BG_UX,
} from "../../shared/utils";
import { useSelector, useDispatch } from "react-redux";
import InputSearch from "../Input/InputSearch";
import BuscarLancamentos from "../../containers/Contas/BuscarLancamentos/BuscarLancamentos";
import BlocoConciliacao from "./BlocoConciliacao";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ConciliacaoContaKPI from "../Dash/ConciliacaoContaKPI";
import DefaultButton from "../Buttons/DefaultButton";
import api from "../../axios";
import {showSnackMessage} from "../../actions/SnackActions";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";


const ConciliacoesConta = (props) => {

    const dispatch = useDispatch();
    const {
        conta,
        contasOptions,
        periodo,
        arrowCounter,
        customPeriodo,
        getValorPendente,
        getSaldoAtual,
        getDetalhesConta,
        throwDispatchErrorMessage
    } = props;

    const navigate = useNavigate();

    const [searchValue, setSearchValue] = useState("");
    const [todosKPI, setTodosKPI] = useState(0);
    const [recebidosKPI, setRecebidosKPI] = useState(0);
    const [pagamentosKPI, setPagamentosKPI] = useState(0);
    const [selectedKPI, setSelectedKPI] = useState("todos");
    const [conciliacoesConta, setConciliacoesConta] = useState([]);
    const [conciliacoesContaFiltered, setConciliacoesContaFiltered] = useState([]);
    const [loading, setLoading] = useState(false);
    const [categoriasOptions, setCategoriasOptions] = useState([]);
    const [openModalBuscarLanc, setOpenModalBuscarLanc] = useState(false);

    const [selectedLancamento, setSelectedLancamento] = useState(null);

    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    useEffect(() => {
        getConciliacoesConta();
    }, [periodo, arrowCounter, customPeriodo]);

    useEffect(() => {
        api.GetCategorias({estabelecimento_id: estabelecimentoId}).then(response => {
            setCategoriasOptions(response.data);
        });
    }, []);

    const getConciliacoesConta = (clear = false) => {
        if (!conta) {
            return;
        }
        setLoading(true);
        let dataRequest = {
            estabelecimento_id: estabelecimentoId,
            conta_id: conta.value,
            periodo: periodo,
            plus_minus: arrowCounter,
            kpi: selectedKPI,
        };
        if (periodo === "custom_periodo") {
            let start_date = customPeriodo[0]._d;
            let end_date = customPeriodo[1]._d;
            dataRequest.start_date = `${start_date.getDate()}/${start_date.getMonth() + 1}/${start_date.getFullYear()}`;
            dataRequest.end_date = `${end_date.getDate()}/${end_date.getMonth() + 1}/${end_date.getFullYear()}`;
        }
        if (searchValue !== "" & !clear) {
            dataRequest.search_value = searchValue;
        }
        api.GetConciliacaoConta(dataRequest).then(response => {
            let data = response.data;
            if (!data) {
                dispatch(showSnackMessage({ message: "Nenhuma conciliação a ser feita manualmente para esta data!", severity: "warning" }));
            } else {
                setConciliacoesConta(data.conciliacao);
                setConciliacoesContaFiltered(data.conciliacao);
                setTodosKPI(data.total_kpi);
                setRecebidosKPI(data.recebidos_kpi);
                setPagamentosKPI(data.pagamentos_kpi);
            }
            setLoading(false);
        });
    };

    const handleConciliar = (extratoId, movimentacoesIds) => {
        setLoading(true);
        let dataRequest = {
            extrato_id: extratoId,
            movimentacoes_ids: movimentacoesIds,
        };

        api.conciliarLancamentos(dataRequest).then(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: "Conciliação realizada com sucesso!", severity: "success" }));
            getConciliacoesConta();
            getValorPendente();
            getDetalhesConta(true);
        }).catch((error) => {
            if (error.response.status === 403) {
                dispatch(showSnackMessage({message: "Atenção! Você não possui permissão para esta ação.", severity: "info"}));
            } else {
                dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
            }
        });

    };

    const handleCriarConciliar = (extratoId, tipoMovimentacao, contaDestino, categoria) => {
        setLoading(true);
        let dataRequest = {
            extrato_id: extratoId,
            tipo_movimentacao: tipoMovimentacao,
            conta_destino: contaDestino,
            categoria: categoria,
        };
        api.criarConciliarLancamento(dataRequest).then(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: "Conciliação criada com sucesso!", severity: "success" }));
            getConciliacoesConta();
            getValorPendente();
            getSaldoAtual();
            getDetalhesConta(true);
        }).catch((error) => {
            if (error.response.status === 403) {
                dispatch(showSnackMessage({message: "Atenção! Você não possui permissão para esta ação.", severity: "info"}));
            } else {
                dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
            }
        });
    };

    const handleRemoveRow = (conciliacao, idx) => {
        setLoading(true);
        api.ignoreLancamento({extrato_id: conciliacao.extrato.id}).then(() => {
            conciliacoesConta.splice(conciliacao.id, 1);
            setConciliacoesConta([...conciliacoesConta]);
            conciliacoesContaFiltered.splice(idx, 1);
            setTodosKPI(todosKPI - 1);
            if (conciliacao.extrato.valor_lancamento > 0) {
                setRecebidosKPI(recebidosKPI - 1);
            }
            if (conciliacao.extrato.valor_lancamento < 0) {
                setPagamentosKPI(pagamentosKPI - 1);
            }
            setLoading(false);
        });
    };

    const handleAddMovimentacao = (index, extratoId, movimentacao) => {
        setConciliacoesConta(
            conciliacoesConta.map((conciliacao, idx) => {
                if (idx === index) {
                    conciliacao.movimentacao = movimentacao;
                }
                return conciliacao;
            }
        ));
        setConciliacoesContaFiltered(
            conciliacoesContaFiltered.map((conciliacao) => {
                if (conciliacao.extrato.id === extratoId) {
                    conciliacao.movimentacao = movimentacao;
                }
                return conciliacao;
            })
        );
        getSaldoAtual();
    };

    const handleUnlinkMovimentacao = (idx) => {
        setConciliacoesConta(
            conciliacoesConta.map((conciliacao, index) => {
                if (index === idx) {
                    conciliacao.movimentacao = null;
                }
                return conciliacao;
            })
        );        
    };

    const handleClearFilters = () => {
        setSearchValue("");
        getConciliacoesConta(true);
    };

    const redirctToIgnorados = () => {
        navigate("/ignorados", {state: { conta_id: conta.value }});
    };

    const handleChangeKPI = (kpi) => {
        setLoading(true);
        setSelectedKPI(kpi);
        switch (kpi) {
            case "todos":
                setConciliacoesContaFiltered(conciliacoesConta);
                break;
            case "recebidos":
                setConciliacoesContaFiltered(conciliacoesConta.filter(conciliacao => conciliacao.extrato.valor_lancamento > 0));
                break;
            case "pagamentos":
                setConciliacoesContaFiltered(conciliacoesConta.filter(conciliacao => conciliacao.extrato.valor_lancamento < 0));
                break;
        }
        setLoading(false);
    };

    return (
        <React.Fragment>
            {openModalBuscarLanc && selectedLancamento && (
            <BuscarLancamentos
                lancamento={selectedLancamento}
                contaId={conta?.value}
                contas={contasOptions}
                openModalBuscarLanc={openModalBuscarLanc}
                setOpenModalBuscarLanc={setOpenModalBuscarLanc}
                getConciliacoesConta={getConciliacoesConta}
            />)}
            <Grid container spacing={3} data-testid="conciliacoes-container">
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <InputSearch
                                callFunction
                                EXM
                                functionProps={getConciliacoesConta}
                                setSearchValue={setSearchValue}
                                searchValue={searchValue}
                                label={"Pesquisar"}
                            />
                        </Grid>
                        <Grid item sx={{display: "flex", alignItems: "center", cursor: "pointer"}}>
                            <DeleteForeverIcon sx={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}/>
                            <a style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}} onClick={() => handleClearFilters()}>Limpar filtros</a>
                        </Grid>
                        <Grid item sx={{marginLeft: "auto"}}>
                            <DefaultButton
                                onClick={conta ? () => redirctToIgnorados() : throwDispatchErrorMessage}
                                title="Ver lançamentos ignorados"
                                testId="btn-ignorados"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{marginBottom: 10}}>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <ConciliacaoContaKPI
                                title={"Todos"}
                                data={todosKPI}
                                handleClick={() => handleChangeKPI("todos")}
                                selectedKPI={selectedKPI === "todos"}
                                color={YELLOW_BG_UX}
                                testId="todos-kpi"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ConciliacaoContaKPI
                                title={"Recebidos"}
                                data={recebidosKPI}
                                handleClick={() => handleChangeKPI("recebidos")}
                                selectedKPI={selectedKPI === "recebidos"}
                                color={DASH_KPI_POSITIVE_COLOR}
                                testId="todos-recebidos"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ConciliacaoContaKPI
                                title={"Pagamentos"}
                                data={pagamentosKPI}
                                handleClick={() => handleChangeKPI("pagamentos")}
                                selectedKPI={selectedKPI === "pagamentos"}
                                color={DASH_KPI_NEGATIVE_COLOR}
                                testId="todos-pagamentos"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {
                    loading ? (
                        <Grid item xs={12}>
                            <Skeleton variant="rectangular" height={500} width="100%" />
                        </Grid>
                    ) : (
                        (
                            conciliacoesContaFiltered.map((conciliacao, index) => {
                                return (
                                    <BlocoConciliacao
                                        key={index}
                                        conciliacao={conciliacao}
                                        index={index}
                                        handleRemoveRow={handleRemoveRow}
                                        conta={conta}
                                        categoriasOptions={categoriasOptions}
                                        contasOptions={contasOptions}
                                        setSelectedLancamento={setSelectedLancamento}
                                        setOpenModalBuscarLanc={setOpenModalBuscarLanc}
                                        handleCriarConciliar={handleCriarConciliar}
                                        handleConciliar={handleConciliar}
                                        handleAddMovimentacao={handleAddMovimentacao}
                                        handleUnlinkMovimentacao={handleUnlinkMovimentacao}
                                    />
                                );
                            })
                        )
                    )
                }
            </Grid>
        </React.Fragment>
    );
};

export default ConciliacoesConta;